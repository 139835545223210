/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import { get, isNil, map, omit, toArray } from 'lodash'
import React from 'react'

import { formatTotal } from '../../../pages-builder/calculations/calculation-components'
import {
  ChangeBadge,
  diffRepeaters
} from '../../../pages-runner/view-form/components/compare-changes'
import { useFormbot, useFormbotData } from '../../engine/formbot-react/hooks'
import ViewModal from '../table/view-modal'
import { getRepeatStructure, useFooterGadgets } from './utils'

export default function RepeaterView (props) {
  return props.condensed ? (
    <RepeaterViewCondensed {...props} />
  ) : (
    <RepeaterViewInner {...props} />
  )
}

function RepeaterViewCondensed (props) {
  const [showModal, setShowModal] = React.useState(false)
  const length = props.value?.data?.length
  const label = length === 1 ? 'item' : 'items'
  return (
    <>
      {length && (
        <button
          className='kp-button-transparent'
          onClick={e => {
            e.stopPropagation()
            e.preventDefault() // Needed because stopPropagation still bubbles up events for links
            setShowModal(true)
          }}
          aria-label={`Repeater with ${length} ${label}. Click to view.`}
        >
          {length} {label}
        </button>
      )}
      <ViewModal setShowModal={setShowModal} showModal={showModal}>
        <RepeaterViewInner
          {...props}
          formKey={props.formKey.replace('data.', '')}
          context={{ gadgetInstances: props.schema }}
        />
      </ViewModal>
    </>
  )
}

function RepeaterViewInner (props) {
  const formbot = useFormbot()
  const formbotData = useFormbotData()
  const footerEnabled = props.details?.calculationFooter?.enabled
  const footerGadgets = useFooterGadgets(
    props.childrenTemplate,
    props.details?.calculationFooter?.fields ?? []
  )
  const changes = diffRepeaters(props)
  return (
    <div className='flex flex-col gap-4'>
      <ul className='flex flex-col gap-4'>
        {map(props.value?.data, (repeat, i) => (
          <li
            key={repeat.id}
            className={cx({
              'bg-white dark:bg-transparent': props.condensed,
              '!bg-green-100 px-2.5': changes[repeat.id]?.type === 'added',
              '!bg-red-100 px-2.5': changes[repeat.id]?.type === 'removed'
            })}
          >
            {changes[repeat.id] && <div className='h-2.5' />}
            <ChangeBadge type={changes[repeat.id]?.type} className='pt-2.5' />
            {map(props.childrenTemplate, gadget => {
              const context = omit(props.context, ['prev', 'next'])
              if (!changes[repeat.id]) {
                context.compare = { changes: toArray(changes) }
              }
              const template = getRepeatStructure(gadget, repeat.id, i, props)
              return formbot.render('View', template, formbotData, { context })
            })}
          </li>
        ))}
      </ul>
      {footerEnabled && footerGadgets.length > 0 && (
        <CalculationFooter
          gadgets={footerGadgets}
          value={props.value?.footer ?? {}}
        />
      )}
    </div>
  )
}

function CalculationFooter ({ gadgets, value }) {
  return (
    <dl className='flex divide-x divide-light-gray-300 border border-light-gray-300 bg-light-gray-200 dark:divide-light-gray-400 dark:border-light-gray-400 dark:bg-light-gray-300'>
      {gadgets.map(gadget => (
        <CalculationFooterField
          key={gadget.id}
          calcFunction={gadget.calcFunction}
          gadget={gadget}
          value={get(value, [gadget.formKey, gadget.calcFunction])}
        />
      ))}
    </dl>
  )
}

function CalculationFooterField ({ calcFunction, gadget, value }) {
  const formatted = formatTotal(value, calcFunction, gadget.type)

  return (
    <div className='flex flex-1 flex-col justify-between px-6 py-3.5'>
      <dt className='text-base dark:text-black'>{gadget.label}</dt>
      <dd className='flex justify-end gap-1 font-medium'>
        <span className='text-medium-gray-500'>{calcFunction}</span>
        <span
          className={cx('dark:text-black', {
            'text-medium-gray-500 dark:text-medium-gray-500': isNil(formatted)
          })}
        >
          {formatted}
        </span>
      </dd>
    </div>
  )
}
