/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import _ from 'lodash'
import shortid from 'shortid'

export function gatherAllSubGadgets (
  gadgets,
  formbot,
  options = {},
  count = 3
) {
  if (count <= 0) return gadgets
  const allGadgets = _.flatMap(gadgets, gadget => {
    const definition = formbot.getGadget(gadget.type)
    if (!definition.subFields || options.ignoreSubFields?.(definition)) {
      return [gadget]
    }
    const subFields = definition
      .subFields({
        id: gadget.id,
        formKey: gadget.formKey,
        label: gadget?.customName?.enabled
          ? gadget?.customName?.value
          : gadget.label,
        details: gadget.details
      })
      .map(subField => {
        const subGadget = {
          ...subField,
          parent: gadget.id,
          subField: true
        }
        return subGadget
      })
    return [
      gadget,
      ...gatherAllSubGadgets(subFields, formbot, options, count - 1)
    ]
  })
  return _.uniqBy(allGadgets, 'formKey')
}

function prependDataDot (gadgets) {
  return _.map(gadgets, gadget => {
    if (!gadget.formKey) return gadget
    return { ...gadget, formKey: `data.${gadget.formKey}` }
  })
}

function removeLayout (gadgets) {
  return _.filter(gadgets, gadget => gadget.formKey)
}

export function structureToSchema (structure, formbot) {
  const gadgets = collectGadgets(structure.template)
  const allGadgets = removeLayout([
    ...prependDataDot(gadgets),
    ...structure.metaFields,
    ...structure.integrationFields,
    ...prependDataDot(structure.trashed || [])
  ])
  return gatherAllSubGadgets(allGadgets, formbot)
}

export function expandTableColumns (
  schema,
  formbot,
  mapper = (gadget, parent) => gadget
) {
  return _.flatMap(schema, gadget => {
    switch (gadget.type) {
      case 'Repeater': {
        const children = []
        _.forEach(gadget.childrenTemplate, g => {
          traverseTemplate(g, child => {
            if (child.formKey) {
              const formKey = `${gadget.formKey}.data.*.data.${child.formKey}`
              children.push(mapper({ ...child, formKey }, gadget))
            }
          })
        })
        return [mapper(gadget), ...gatherAllSubGadgets(children, formbot)]
      }
      case 'Table': {
        const columns = _.map(gadget.childrenTemplate, g =>
          mapper({ ...g, formKey: `${gadget.formKey}.*.${g.formKey}` }, gadget)
        )
        return [mapper(gadget), ...gatherAllSubGadgets(columns, formbot)]
      }

      default:
        return [mapper(gadget)]
    }
  })
}

export function traverseTemplate (_template, cb, parent, i, root) {
  const template = { id: 'ROOT', type: 'ROOT', children: [] }
  if (_template) template.children.push(_template)
  traverseTemplateInner(template, cb)
  return template.children[0]
}

export function traverseTemplateInner (template, cb, parent, i, root) {
  if (root === undefined) root = true
  if (!template) return
  if (template.children) {
    _.forEachRight(template.children, (child, i) => {
      traverseTemplateInner(child, cb, template, i, false)
    })
  }
  cb(template, root, parent, i)
}

export function getRepeatableChildren (gadget) {
  if (gadget.type === 'Table') {
    return gadget.childrenTemplate
  }

  if (gadget.type === 'Repeater') {
    const children = []
    traverseTemplate({ children: gadget.childrenTemplate }, child => {
      if (child.formKey || child.details?.selectedOutputField) {
        children.unshift(child)
      }
    })
    return children
  }

  if (gadget.type === 'Rules') {
    const children = []
    traverseTemplateInner(gadget.details.tree, treeNode => {
      children.push(...treeNode.gadgets)
    })
    return children
  }
}

export function findValueInTemplate (template, cb) {
  let value = cb(template) || null
  if (value) return value
  if (template.children) {
    _.some(template.children, child => {
      value = findValueInTemplate(child, cb)
      return value
    })
  }
  return value
}

export function collectGadgets (template) {
  const gadgets = []
  traverseTemplate(template, gadget => gadgets.push(gadget))
  return gadgets
}

function getPd (gadget) {
  const enabled = gadget.conditionalVisibility?.enabled
  return enabled ? gadget.conditionalVisibility.value : null
}

function getHiddenGadgets (Formbot, document, gadgets, allGadgets) {
  return _.reject(gadgets, gadget => {
    if (gadget.hidden) return false
    const pd = getPd(gadget)
    if (!pd) return true
    const combineFn = pd.type === 'any' ? _.some : _.every
    const parts = _.filter(pd.parts, 'formKey')
    return combineFn(parts, part => {
      const datum = _.get(document, part.formKey)
      const gadgetInstance = _.find(allGadgets, { formKey: part.formKey })
      if (!gadgetInstance) return false
      const gadgetDefinition = Formbot.getGadget(gadgetInstance.type)
      const actual = datum ?? gadgetDefinition.defaultValue
      return gadgetDefinition.progressiveDisclosure.check(
        actual,
        part.data,
        document
      )
    })
  })
}

const ID_KEY = 'VISIBLE-' + shortid.generate()

export function filterVisibleGadgets (Formbot, document, structure) {
  let template = _.cloneDeep(structure.template)
  traverseTemplate(template, gadget => {
    gadget[ID_KEY] = shortid.generate()
    if (gadget.formKey) gadget.formKey = `data.${gadget.formKey}`
  })
  let toRemove
  const allGadgets = gatherAllSubGadgets(
    [
      ...collectGadgets(template),
      ...structure.metaFields,
      ...structure.integrationFields
    ],
    Formbot
  )
  do {
    const gadgets = collectGadgets(template)
    toRemove = getHiddenGadgets(
      Formbot,
      document,
      [...gadgets, ...structure.metaFields, ...structure.integrationFields],
      allGadgets
    )
    const toRemoveIds = _.map(toRemove, ID_KEY)
    const resultTemplate = traverseTemplate(
      template,
      (gadget, root, parent, i) => {
        if (!_.includes(toRemoveIds, gadget[ID_KEY])) return
        parent.children.splice(i, 1)
        _.unset(document, gadget.formKey)
      }
    )
    if (!resultTemplate) template = null
  } while (toRemove.length)
  traverseTemplate(template, gadget => {
    delete gadget[ID_KEY]
    if (gadget.formKey) gadget.formKey = gadget.formKey.replace('data.', '')
  })
  return template
}

// Determines if a child of a repeatable gadget is configured to auto-update.
// It's a little tricky because it may need to search for the actual FormTypeahead
// gadget it's associated with.
export function childAutoUpdates (child, children, allGadgets) {
  if (child.autoUpdate) return true
  let parent = _.find(children, c => child.details?.parentId?.includes(c.id))
  parent =
    parent || _.find(allGadgets, g => child.details?.parentId?.includes(g.id))
  return !!parent?.details?.autoUpdate?.enabled
}
