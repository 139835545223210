/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { produce } from 'immer'
import {
  compact,
  every,
  filter,
  find,
  get,
  includes,
  isArray,
  isNil,
  keyBy,
  map,
  noop,
  pick,
  reduce,
  some,
  unset
} from 'lodash'

const MAX_PD_DEPENDENCY_DEPTH = 100

function addSectionDepth (template, depth = 1) {
  let nextDepth = depth
  if (template.type === 'Section') {
    template.sectionDepth = depth
    nextDepth = template.hideLabel ? depth : depth + 1
  }
  if (isArray(template.children)) {
    template.children.forEach(child => {
      addSectionDepth(child, nextDepth)
    })
  }
}

export default function fbRender (
  Formbot,
  mode,
  template,
  document,
  gadgetInstances,
  props = {}
) {
  if (!template?.id) {
    return Formbot.renderEmptyForm(Formbot, mode, props)
  }
  // Section depth is used to determine which heading tags (H1, H2...) to use
  addSectionDepth(template)
  return renderGadget(
    Formbot,
    gadgetInstances,
    mode,
    document,
    template,
    props
  )(template)
}

function renderGadget (
  Formbot,
  gadgetInstances,
  mode,
  document,
  fullTemplate,
  props
) {
  function renderGadgetInternal (template) {
    const middlewares = [
      progressiveDisclosure,
      officeUseOnly,
      renderChildren,
      bindDataHandlers,
      highlightFields,
      showWarnings
    ]
    const args = {
      Formbot,
      fullTemplate,
      template,
      document,
      props,
      gadgetInstances,
      renderGadgetInternal
    }
    const options = reduce(
      middlewares,
      (memo, mw) => ({ ...memo, ...mw(args) }),
      {
        context: props.context,
        noGrid: props.noGrid,
        readOnly: template.readOnly,
        hidden: template.hidden,
        progDisc: (gadget, extraGadgets = []) =>
          shouldShowProgressiveDisclosure({
            Formbot,
            document,
            template: gadget,
            fullTemplate,
            gadgetInstances: keyBy(
              [...gadgetInstances, ...extraGadgets],
              'formKey'
            )
          })
      }
    )

    return Formbot.renderGadget(Formbot, mode, template, options)
  }
  return renderGadgetInternal
}

function getPd (gadget) {
  const enabled = gadget.conditionalVisibility?.enabled
  return enabled ? gadget.conditionalVisibility.value : null
}

function progressiveDisclosure ({
  Formbot,
  template,
  document,
  fullTemplate,
  gadgetInstances
}) {
  const shouldShow = shouldShowProgressiveDisclosure({
    Formbot,
    document,
    template,
    fullTemplate,
    gadgetInstances: keyBy(gadgetInstances, 'formKey')
  })
  return { shouldShow }
}

// function findAncestry (template, gadget) {
//   if (template.id === gadget.id) return []
//   const children = [].concat(
//     template.children ?? [],
//     template.childrenTemplate ?? []
//   )
//   for (const child of children) {
//     const ancestry = findAncestry(child, gadget)
//     if (ancestry) return [template, ...ancestry]
//   }
//   return null
// }

function shouldShowProgressiveDisclosure (
  { Formbot, template, fullTemplate, document, gadgetInstances },
  callStack = []
) {
  callStack = callStack.slice()
  callStack.push(pick(template, ['formKey', 'label', 'id']))
  if (callStack.length > MAX_PD_DEPENDENCY_DEPTH) {
    const err = new Error(i18n._('unable.to.render.form.cyclical'))
    err.code = 'pd_dependency_cycle'
    err.title = "OK, here's what's going on:"
    err.details = { callStack }
    throw err
  }
  if (!template) return false
  // const ancestry = findAncestry(fullTemplate, template) ?? []
  // const parentIsShowing = ancestry.every(parent =>
  //   shouldShowProgressiveDisclosure(
  //     { Formbot, template: parent, fullTemplate, document, gadgetInstances },
  //     callStack
  //   )
  // )
  // if (!parentIsShowing) return false
  const pd = getPd(template)
  if (!pd) {
    if (template.type === 'Validation' && !Formbot.options.config) return false
    return true
  }
  const parts = filter(pd.parts, p => p.formKey && gadgetInstances[p.formKey])
  document = reduce(
    parts,
    (document, part) => {
      const template = gadgetInstances[part.formKey]
      const shouldShow = shouldShowProgressiveDisclosure(
        { Formbot, template, fullTemplate, document, gadgetInstances },
        callStack
      )
      if (shouldShow) return document
      return produce(document, draft => {
        unset(draft, part.formKey)
      })
    },
    document
  )

  const any = pd.type === 'any'
  const combineFn = any ? some : every
  const shouldShow = combineFn(parts, part => {
    const { formKey } = part
    const gadgetInstance = gadgetInstances[formKey]
    const gadgetDefinition = Formbot.getGadget(gadgetInstance.type)
    const actual =
      get(document, formKey) === undefined
        ? gadgetDefinition.defaultValue
        : get(document, formKey)
    const expected = part.data
    return gadgetDefinition.progressiveDisclosure.check(
      actual,
      expected,
      document
    )
  })

  return shouldShow
}

const defaultRender = ({ children }, render) => compact(map(children, render))
function renderChildren ({ Formbot, template, props, renderGadgetInternal }) {
  const { renderChildren } = Formbot.getGadget(template.type)
  if (!template.children && !renderChildren) return {}
  const render = renderChildren || defaultRender
  const isConfig = props.context.configMode
  return { children: render(template, renderGadgetInternal, isConfig) }
}

function bindDataHandlers ({ Formbot, template, document, props }) {
  const obj = {}
  const gadgetDefinition = Formbot.getGadget(template.type)
  const value = get(document.data, template.formKey)
  obj.value = !isNil(value) ? value : gadgetDefinition.defaultValue
  obj.onChange = noop
  if (props.onChange) {
    obj.StaticFormbot = props.StaticFormbot
    obj.onChange = (newVal, path) => {
      const newPath =
        path || path === 0 ? `${template.formKey}.${path}` : template.formKey
      return props.onChange(newPath, newVal)
    }
  }
  return obj
}

function officeUseOnly ({ template, props }) {
  if (template?.details?.officeUseOnly && props.hideOfficeUse) {
    return { shouldShow: false }
  }
  return {}
}

function highlightFields ({ template, props }) {
  const fields = props?.highlight?.fields || []
  if (includes(fields, `data.${template?.formKey}`)) {
    return { highlight: props?.highlight?.color }
  }
  return {}
}

function showWarnings ({ template, document, props }) {
  const warnings = props?.context?.warnings || []
  const warning = find(warnings, w =>
    w.shouldShow(template, get(document, `data.${template.formKey}`))
  )
  if (warning) {
    return { warning: warning.message }
  }
  return {}
}
