/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { find, includes, map, reject } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import * as Lookups from '../../../components/lookup-controls'
import Spinner from '../../../components/spinner'
import { useAppIds } from '../../../components/use-app-ids-context'
import useDebouncedValue from '../../../components/use-debounced-value'
import * as Icons from '../../../icons'
import Button from '../../../ui/button'
import { Space } from '../../../ui/layout'
import * as Lookup from '../../../ui/lookup'
import { Option, Select } from '../../../ui/select'
import { useInputs } from '../../integration-utils'

export default function IntegrationMultiselect (props) {
  const hasSearch = props.details?.searchParameter
  return hasSearch ? <Typeahead {...props} /> : <Dropdown {...props} />
}

function Dropdown ({ value, onChange, details, ...props }) {
  const id = details?.id
  const inputs = useInputs(details)
  const { appId, actionId } = useAppIds()
  const { data, error, loading } = useQuery(
    ...getInvokeIntegrationQuery({ appId, actionId, id, data: inputs })
  )
  if (error || !id || inputs === null) return null
  if (loading) {
    return (
      <LoadingWrapper gridded={props.gridded}>
        <Spinner size={16} />
      </LoadingWrapper>
    )
  }
  const loaded = id && !error && !loading
  const app = actionId ? data?.action?.document?.app : data?.app
  const rawOptions = loaded
    ? (app?.sharedWithMe?.integration?.invoke ?? [])
    : null
  const ids = map(value, 'id')
  const options = map(rawOptions, item => ({
    ...item,
    id: String(item.id)
  })).filter(option => !includes(ids, option.id))
  return (
    <>
      <Select
        aria-labelledby={props['aria-labelledby']}
        {...(props['aria-describedby'] && {
          'aria-describedby': props['aria-describedby']
        })}
        aria-required={props.required}
        className={props.className}
        id={props.id}
        onChange={id =>
          onChange([...reject(value, { id }), find(options, { id })])
        }
        value=''
      >
        <Option value='' />
        {map(options, ({ id, label }) => (
          <Option key={id} value={id}>
            {label}
          </Option>
        ))}
      </Select>
      {!!value?.length && <Space h='8px' />}
      <Wrapper gridded={props.gridded}>
        {map(value, option => (
          <SpacedChip key={option.id}>
            <Lookup.ChipLabel>{option.label}</Lookup.ChipLabel>
            <Button
              transparent
              onClick={() => onChange(reject(value, { id: option.id }))}
            >
              <Icons.Close width='8px' height='8px' />
            </Button>
          </SpacedChip>
        ))}
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  padding: ${p => (p.gridded ? '0 16px 16px 16px' : 0)};
`

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${p => (p.gridded ? '8px 16px 24px 16px' : 0)};
`

const SpacedChip = styled(Lookup.Chip)`
  margin-right: 4px;
  margin-bottom: 4px;
`

function Typeahead ({ value, onChange, details, ...props }) {
  const [query, setQuery] = React.useState('')
  const debouncedQuery = useDebouncedValue(query, 300)
  const { appId, actionId } = useAppIds()
  const { id, searchParameter } = details
  const placeholder =
    details?.placeholder?.enabled && (details?.placeholder?.value ?? '')
  const searchParam = searchParameter.id.replace(/^(url|query):/, '')
  let inputs = useInputs(details)
  if (searchParameter.required && !debouncedQuery) inputs = null
  if (inputs) inputs = { ...inputs, [searchParam]: debouncedQuery }
  const { data, loading } = useQuery(
    ...getInvokeIntegrationQuery({ appId, actionId, id, data: inputs })
  )
  const app = actionId ? data?.action?.document?.app : data?.app
  const rawOptions = app?.sharedWithMe?.integration?.invoke ?? []
  const options = map(rawOptions, item => ({ ...item, id: String(item.id) }))
  return (
    <Lookups.Multiselect
      id={props.id}
      placeholder={placeholder}
      options={options}
      onChange={onChange}
      value={value}
      query={query}
      setQuery={setQuery}
      aria={{
        labelledby: props['aria-labelledby'],
        describedby: props['aria-describedby']
      }}
      gridded={props.gridded}
      loading={loading}
    />
  )
}

const invokeIntegtationQuery = gql`
  query InvokeIntegrationQuery($appId: ID!, $id: ID!, $data: JSON) {
    app(id: $appId) {
      id
      sharedWithMe {
        integration(id: $id) {
          id
          invoke(data: $data)
        }
      }
    }
  }
`

const invokeIntegrationWithActionQuery = gql`
  query InvokeIntegrationWithActionQuery(
    $actionId: String!
    $id: ID!
    $data: JSON
  ) {
    action(actionId: $actionId) {
      id
      document {
        id
        app {
          id
          sharedWithMe {
            integration(id: $id) {
              id
              invoke(data: $data)
            }
          }
        }
      }
    }
  }
`

const getInvokeIntegrationQuery = ({ appId, actionId, id, data }) => [
  actionId ? invokeIntegrationWithActionQuery : invokeIntegtationQuery,
  {
    variables: { appId, actionId, id, data },
    skip: (!appId && !actionId) || !id || data === null
  }
]
