/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import {
  cloneDeep,
  find,
  forEachRight,
  includes,
  keyBy,
  map,
  some,
  split
} from 'lodash'
import shortid from 'shortid'

import { filterFooter } from '../formbot/gadgets/repeater/utils'

export function getDropZoneProps (dropContext, isInvalid) {
  if (!dropContext) return null
  const { dir, dimensions, data, id } = dropContext
  const gid = dir === 'inside' ? id.substr(6) : id
  if (isInvalid(gid, dir)) return null
  let { left, top, width, height } = dimensions
  if (data === 'Row' || data === 'Column') {
    if (dir === 'right') left += width - 50
    if (dir === 'bottom') top += height - 50
    if (dir === 'left' || dir === 'right') width = 50
    if (dir === 'top' || dir === 'bottom') height = 50
  } else if (data === 'Section' || data === 'Table') {
    if (dir === 'right') left += width - 10
    if (dir === 'bottom') top += height - 10
    if (dir === 'left' || dir === 'right') width = 10
    if (dir === 'top' || dir === 'bottom') height = 10
  } else {
    if (dir === 'left' || dir === 'right') width /= 2
    if (dir === 'top' || dir === 'bottom') height /= 2
    if (dir === 'right') left += width
    if (dir === 'bottom') top += height
  }
  return { left, top, width, height }
}

export function getAriaLabel (gadget) {
  if (gadget.type === 'Column' && gadget.root) {
    return 'form'
  }

  if (gadget.type === 'Row' && Array.isArray(gadget.children)) {
    return `${i18n._('row.with.gadgets.length', {
      length: gadget.children.length
    })}`
  }

  if (gadget.type === 'TableColumn') {
    return gadget.details?.gadget?.label
  }

  return gadget.label
}

export function traverseFormSafe (_form, cb) {
  const form = { id: 'ROOT', type: 'ROOT', children: [] }
  if (_form) form.children.push(cloneDeep(_form))
  traverseForm(form, cb)
  return form.children[0]
}

export function traverseForm (gadget, cb, parent = null, i = 0) {
  if (!gadget) return
  if (gadget.children) {
    forEachRight(gadget.children, (g, i) => {
      traverseForm(g, cb, gadget, i)
    })
  } else if (gadget.childrenTemplate) {
    forEachRight(gadget.childrenTemplate, (g, i, children) => {
      traverseForm(g, cb, { ...gadget, children }, i)
    })
  }
  cb(gadget, parent, i)
}

export const t = {
  findValue: (form, cb) => {
    let result
    traverseFormSafe(form, (gadget, parent, i) => {
      if (!result) result = cb(gadget, parent, i)
    })
    return result
  },
  flatMap: (form, cb) => {
    const result = []
    traverseFormSafe(form, (gadget, parent, i) => {
      result.push(...cb(gadget, parent, i))
    })
    return result
  }
}

// Create an object where the keys are ids of repeaters and tables in the form,
// and the values are the definitions of those gadgets, with the added property
// `children` that is a flat array of all the direct children (i.e. not nested
// inside further repeatables).
export function getRepeatableChildrenMap (form) {
  let children = []
  const repeatables = t.flatMap(form, gadget => {
    if (!includes(['Repeater', 'Table'], gadget.type)) return []
    children = t.flatMap(gadget, g =>
      // Since child repeaters will be traversed before their parents, we can
      // filter out children that were also children of the previous repeater,
      // ensuring that the list of children is only direct children.
      g.id === 'ROOT' || g.id === gadget.id || some(children, { id: g.id })
        ? []
        : [g]
    )
    return [
      {
        ...gadget,
        children
      }
    ]
  })
  return keyBy(repeatables, 'id')
}

// Find the nearest parent that has a formKey. I.e., if the gadget is
// inside a table or repeater, find that containing table or repeater.
// The parent is also returned with a `children` property that is a flat
// array of all its children.
export function findParent (repeatableChildrenMap, id) {
  return find(repeatableChildrenMap, g => some(g.children, { id }))
}

// Create a function that checks if the target id is inside a table or repeater
const isIn = (type, repeatableChildrenMap) => (id, dir) =>
  (repeatableChildrenMap[id]?.type === type && dir === 'inside') ||
  some(repeatableChildrenMap, g => g.type === type && some(g?.children, { id }))

const DISALLOWED_IN_TABLE = ['Repeater', 'Section', 'Spacer', 'Table']
const DISALLOWED_IN_REPEATER = ['Repeater', 'Table']
export function buildIsInvalidFunction (form, gadget, gadgetMapById) {
  const { id, type, canonicalGadget } = gadget
  if (canonicalGadget) {
    return (id, dir) => {
      if (id === 'ROOT') return false
      const dzGadget = gadgetMapById[id]
      if (canonicalGadget.formKey.includes('.*.')) {
        const [parentKey] = canonicalGadget.formKey.split('.*.')
        const inEmptyTable = dzGadget.formKey === parentKey && dir === 'inside'
        const nextToTableColumns = dzGadget.parent?.formKey === parentKey
        return !inEmptyTable && !nextToTableColumns
      } else {
        return (
          dzGadget.parent?.type === 'Table' ||
          (dzGadget.type === 'Table' && dir === 'inside')
        )
      }
    }
  }
  const childIds = t.flatMap(gadget, g => (g.id === 'ROOT' ? [] : [g.id]))
  const isNested = id => childIds.includes(id)
  const repeatableChildrenMap = getRepeatableChildrenMap(form)
  const parent = id && findParent(repeatableChildrenMap, id)
  if (includes(['Repeater', 'Table'], parent?.type)) {
    const goodIds = map(parent.children, 'id')
    return id =>
      isNested(id) ||
      !!repeatableChildrenMap[id] || // Don't allow inside nested repeaters or tables
      !goodIds.includes(id)
  }
  const isInTable = isIn('Table', repeatableChildrenMap)
  const isInRepeater = isIn('Repeater', repeatableChildrenMap)
  if (id) {
    return (id, dir) =>
      isNested(id) || isInTable(id, dir) || isInRepeater(id, dir)
  }
  // since DISALLOWED_IN_TABLE is a superset of DISALLOWED_IN_REPEATER,
  // we can check just the larger one
  if (DISALLOWED_IN_TABLE.includes(type)) {
    return (id, dir) =>
      (DISALLOWED_IN_REPEATER.includes(type) && isInRepeater(id, dir)) ||
      isInTable(id, dir)
  }
  return id => isNested(id)
}

export function findGadgetById (form, id) {
  let found
  traverseForm(form, gadget => {
    if (found) return
    if (gadget.id === id) found = gadget
  })
  return found
}

export function removeGadgetFromForm (form, id) {
  let removed
  return traverseFormSafe(form, (gadget, parent, i) => {
    if (removed) return
    if (gadget.id === id) {
      removed = true
      parent.children.splice(i, 1)
    }
  })
}

export function clearUselessLayout (form) {
  return traverseFormSafe(form, (gadget, parent, i) => {
    if (includes(['Column', 'Row'], gadget.type)) {
      if (gadget.children.length === 0) {
        parent.children.splice(i, 1)
      } else if (gadget.children.length === 1) {
        parent.children.splice(i, 1, gadget.children[0])
      } else {
        forEachRight(gadget.children, (child, j) => {
          if (child.type === gadget.type) {
            gadget.children.splice(j, 1, ...gadget.children[j].children)
          }
        })
      }
    } else if (gadget.type === 'Repeater') {
      parent.children.splice(i, 1, filterFooter(gadget))
    }
  })
}

const newColumn = c => ({ type: 'Column', id: shortid.generate(), children: c })
const newRow = c => ({ type: 'Row', id: shortid.generate(), children: c })

export function addGadgetToForm (form, gid, dir, newGadget) {
  // The Repeater gadget sets its child ids in the form of
  // [parent].[i].[child] so we need to split it first to find a match

  const parts = split(gid, '.')
  const targetGid = parts.length === 3 ? parts[2] : gid
  let added = false
  return traverseFormSafe(form, (gadget, parent, i) => {
    if (added || gadget.id !== targetGid) return
    added = true
    if (dir === 'inside') {
      if ('childrenTemplate' in gadget) gadget.childrenTemplate = [newGadget]
      else gadget.children = [newGadget]
    } else if (dir === 'top') {
      if (parent.type === 'Column') parent.children.splice(i, 0, newGadget)
      else parent.children[i] = newColumn([newGadget, parent.children[i]])
    } else if (dir === 'bottom') {
      if (parent.type === 'Column') parent.children.splice(i + 1, 0, newGadget)
      else parent.children[i] = newColumn([parent.children[i], newGadget])
    } else if (dir === 'left') {
      if (includes(['Row', 'Table'], parent.type)) {
        parent.children.splice(i, 0, newGadget)
      } else parent.children[i] = newRow([newGadget, parent.children[i]])
    } else if (dir === 'right') {
      if (includes(['Row', 'Table'], parent.type)) {
        parent.children.splice(i + 1, 0, newGadget)
      } else parent.children[i] = newRow([parent.children[i], newGadget])
    } else {
      throw new Error(i18n._('voronoi.formbot.utils.direction') + `${dir}`)
    }
  })
}
