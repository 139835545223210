/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'

import Input from '../../ui/input'
import { Wrapper } from '../../ui/layout'
import { Body1 } from '../../ui/typography'

export const Config = ({ value, updateValue, defaultName, label }) => (
  <Wrapper px={3}>
    <Body1 py={2} as='label' htmlFor='custom-step-label'>
      {label}
    </Body1>
    <Input
      id='custom-step-label'
      className='w-full'
      placeholder={defaultName}
      value={value.stepName || ''}
      onChange={val => {
        updateValue(draft => {
          draft.stepName = val
        })
      }}
    />
  </Wrapper>
)
