/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { NetworkStatus, gql, useQuery } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import Scrollbox from '../../../../components/scrollbox'
import Spinner from '../../../../components/spinner'
import * as Icons from '../../../../icons'
import { VisuallyHidden } from '../../../../ui/a11y'
import Button from '../../../../ui/button'

export default function HierarchyView () {
  const layoutRef = React.useRef()
  const [columns, updateColumns] = useImmer([
    { parentId: null, selected: null }
  ])
  const slice = columns.slice(-3)
  const handleSelectGroup = React.useCallback(group => {
    updateColumns(columns => {
      const selectedColumnIndex = columns.findIndex(
        column => column.parentId === group.parentId
      )
      if (selectedColumnIndex === -1) return
      columns.splice(selectedColumnIndex)
      columns.push(
        { parentId: group.parentId, selected: group.id },
        { parentId: group.id, selected: null }
      )
    })

    // This is for focusing the first button in the list
    // when the list is already loaded
    const firstItem = layoutRef.current?.querySelector(
      `[data-parentid="${group.id}"] button`
    )
    if (firstItem) firstItem.focus()
  }, [])
  return (
    <HierarchyWrap className='border border-light-gray-300'>
      <StyledHierarchyHeader className='bg-light-gray-200 dark:bg-light-gray-300'>
        {slice.length > 1 && (
          <Button
            outline
            icon
            m={2}
            ml={3}
            disabled={columns.length === 1}
            onClick={() => {
              updateColumns(columns => {
                if (columns.length > 1) columns.pop()
                columns.at(-1).selected = null
              })
            }}
          >
            <Icons.Back className='fill-blue-500' />
            <VisuallyHidden>
              <Trans id='go.back' message='Go back' />
            </VisuallyHidden>
          </Button>
        )}
      </StyledHierarchyHeader>
      <Layout
        ref={layoutRef}
        className='border-t border-light-gray-300 bg-white'
      >
        {slice.map(column => (
          <StyledScrollBox key={column.parentId}>
            <GroupListColumn
              parentId={column.parentId}
              selected={column.selected}
              onSelect={handleSelectGroup}
            />
          </StyledScrollBox>
        ))}
      </Layout>
    </HierarchyWrap>
  )
}

const groupsByParentQuery = gql`
  query GroupsByParent($parentId: ID, $skip: Int, $limit: Int) {
    groupsConnection(
      args: {
        hasCategory: true
        parentId: $parentId
        skip: $skip
        limit: $limit
        sort: "name"
      }
    ) {
      edges {
        node {
          id
          name
          parentId
          category {
            id
            name
          }
          hasChildren
        }
      }
      pageInfo {
        hasNextPage
        skip
        limit
      }
    }
  }
`

/**
 * @param {import('@apollo/client').ApolloCache} cache
 * @param {*} group
 */
export function updateParentQueryCache (cache, group) {
  if (group.categoryId) {
    cache.evict({ id: `Category:${group.categoryId}` })
  }
  cache.evict({
    fieldName: 'groupsConnection',
    args: {
      args: { parentId: group.parentId, hasCategory: true, sort: 'name' }
    }
  })
}

function GroupListColumn ({ parentId, selected, onSelect }) {
  const { data, loading, fetchMore, networkStatus } = useQuery(
    groupsByParentQuery,
    {
      variables: { parentId },
      notifyOnNetworkStatusChange: true
    }
  )
  const listRef = React.useRef()
  React.useEffect(() => {
    // This is for focusing the first button in the list
    // if the data is being loaded for the first time
    if (parentId && data) {
      listRef.current?.querySelector('a')?.focus()
    }
  }, [data, parentId])
  const loadMore = React.useCallback(() => {
    const pageInfo = data?.groupsConnection?.pageInfo
    fetchMore({
      variables: {
        parentId,
        limit: pageInfo.limit,
        skip: pageInfo.skip + pageInfo.limit
      }
    })
  }, [data?.groupsConnection?.pageInfo, fetchMore, parentId])
  const groups =
    data?.groupsConnection?.edges
      .map(({ node: group }) => group)
      .filter(group => group.category?.id) ?? []
  return (
    <List ref={listRef} data-parentid={parentId}>
      {groups.map(group => (
        <GroupListItem
          key={group.id}
          hasKids={group.hasChildren}
          active={selected === group.id}
          group={group}
          select={() => group.hasChildren && onSelect(group)}
        />
      ))}
      {loading && networkStatus === NetworkStatus.loading ? (
        <LoadingListItem>
          <Spinner size={32} />
        </LoadingListItem>
      ) : (
        data?.groupsConnection?.pageInfo?.hasNextPage && (
          <LoadMoreListItem>
            <LoadMoreButton onClick={loadMore}>
              {loading && networkStatus === NetworkStatus.fetchMore ? (
                <Spinner size={16} />
              ) : (
                <Trans id='load.more' message='Load more' />
              )}
            </LoadMoreButton>
          </LoadMoreListItem>
        )
      )}
    </List>
  )
}

const StyledHierarchyHeader = styled.div`
  height: 50px;
`

const LoadingListItem = styled.li`
  box-sizing: border-box;
  padding: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LoadMoreListItem = styled.li`
  box-sizing: border-box;
  padding: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LoadMoreButton = styled(Button)`
  cursor: pointer;
  justify-self: center;
  width: 100%;
  padding: 8px;
  align-items: center;
`

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const Layout = styled.div`
  display: flex;
  max-height: calc(100% - 50px);
  height: calc(100% - 50px);

  > * {
    width: 33%;
  }

  > :first-child,
  > :nth-child(2) {
    border-right: 1px solid var(--light-gray-300);
  }
`

const StyledScrollBox = styled(Scrollbox)`
  overflow-y: auto;
  overflow-x: hidden;
`

const HierarchyWrap = styled.div`
  grid-column: 2;
  width: 1200px;
  height: 100%;

  @media (max-width: 1232px) {
    width: 100%;
  }
`

function GroupListItem ({ group, select, active, hasKids }) {
  return (
    <StyledListItem active={active}>
      <Link
        to={`${group.id}/view`}
        className='flex w-full max-w-full flex-col overflow-hidden text-ellipsis px-4 py-2 text-sm'
      >
        <span
          className={cx(
            'inline-block max-w-full overflow-hidden text-ellipsis text-left max-[500px]:text-[0.8125rem]',
            active && 'font-bold'
          )}
        >
          {group.name}
        </span>
        <span className='kp-chip mt-1 w-max px-2 py-0.5'>
          {group.category?.name}
        </span>
      </Link>
      {hasKids && (
        <StyledButton
          icon
          transparent
          onClick={e => {
            e.stopPropagation()
            select()
          }}
          active={active}
          aria-label={i18n._({
            id: 'select.group',
            message: 'Select {group}',
            values: { group: group.name }
          })}
        >
          <CaretIcon />
        </StyledButton>
      )}
    </StyledListItem>
  )
}

const CaretIcon = styled(Icons.SelectDownArrow)`
  height: 10px;
  width: 10px;
  transform: rotate(-90deg);
  transition-duration: 150ms;
`

const StyledListItem = styled.li`
  display: flex;
  max-width: 100%;
  width: 100%;
  align-items: center;
  min-height: 56px;
  background-color: ${p => p.active && '#e5f2f9'};
  html.dark & {
    // Outlier: dark:bg-light-gray-300
    background-color: ${p => p.active && '#333'};
  }
  * {
    cursor: pointer;
    &:hover {
      background-color: #eee;
      html.dark & {
        // Outlier: dark:hover:bg-light-gray-300
        background-color: #333;
      }
    }
  }
`

const StyledButton = styled(Button)`
  background-color: ${p => p.active && '#e5f2f9'};
  html.dark & {
    // Outlier: dark:active:bg-light-gray-300
    background-color: ${p => p.active && '#333'};
  }
  border-radius: ${p => (p.active ? 0 : '')};
  padding: 12px;
  cursor: e-resize;
  * {
    cursor: e-resize;
  }

  ${CaretIcon} {
    transform: ${p => p.active && 'rotate(-90deg) scale(1.2)'};
    fill: ${p => p.active && 'var(--text-link)'};
  }

  &:active,
  &:hover {
    background-color: ${p => (p.active ? '#e5f2f9' : '#eee')};
    ${CaretIcon} {
      transform: rotate(-90deg) scale(1.15);
    }
  }
  margin-right: 2px;
  min-height: 56px;
`
