/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import React from 'react'

import * as Icons from '../../icons'
import * as DocumentHistory from '../../pages-runner/document-history'
import { Popover2 } from '../../ui/popover'

export default function MoreOptionsMenu ({
  additionalButtons,
  onClick,
  shouldShowHistory = true,
  width
}) {
  return (
    <Popover2
      role='dialog'
      trigger={
        <button
          className='kp-button-transparent kp-button-icon'
          aria-label={i18n._('more.options')}
          data-testid='more-options'
        >
          <Icons.MenuVertical className='fill-medium-gray-500 text-medium-gray-500 dark:fill-medium-gray-300 dark:text-medium-gray-300' />
        </button>
      }
      bottom={width < 1024 && 54}
    >
      {hide => (
        <div className='py-1 dark:bg-light-gray-200'>
          {shouldShowHistory && (
            <DocumentHistory.Button
              onClick={() => {
                onClick()
                hide()
              }}
            />
          )}
          {additionalButtons && additionalButtons(hide)}
        </div>
      )}
    </Popover2>
  )
}
