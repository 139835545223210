/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import { get, isEqual, uniqWith } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import Spinner from '../../../components/spinner'
import * as Icons from '../../../icons'
import * as Chooser from '../../data-chooser'
import {
  collectGadgets,
  getRepeatableChildren
} from '../../engine/formbot/utils'
import { ReactComponent as Illustration } from '../data-lookup/illustration-integration.svg.jsx'
import AutoUpdateConfig from '../form-typeahead/auto-update-config'

export function RequiredConfig ({ value, ...props }) {
  const hasChosenDataset = Object.keys(value).length !== 0

  return hasChosenDataset ? (
    <RequiredConfigWithDataset {...props} value={value} />
  ) : (
    <RequiredConfigWithoutDataset {...props} />
  )
}

export function RequiredConfigWithoutDataset ({
  Gadgets,
  updateDataLookupSource
}) {
  return (
    <Gadgets.Padded>
      <Gadgets.Label>
        <Trans id='data.source.colon' />
      </Gadgets.Label>
      <Chooser.AssociationsTypeahead onSelect={updateDataLookupSource} />
      <div className='mt-9 flex items-center justify-center'>
        <div className='flex h-24 w-36 items-center justify-center rounded-xl bg-light-gray-100 px-5 text-center text-base text-dark-gray-200 dark:bg-light-gray-300'>
          <Trans id='choose.source.to.begin' />
        </div>
        <Illustration />
      </div>
    </Gadgets.Padded>
  )
}

export function RequiredConfigWithDataset ({
  Gadgets,
  onChange,
  value,
  updateDataLookupSource,
  formKey
}) {
  const { data, loading } = useQuery(formQuery, {
    variables: {
      id: value.id,
      pageId: value.isProduct ? value.pageId : value.id
    }
  })
  const [showGadgetChooser, setShowGadgetChooser] = React.useState(false)
  const template = get(data, 'app.dataset.formContainer.template')
  const dataGadgets = getDataGadgets(template)

  React.useEffect(() => {
    if (!value) return
    if (dataGadgets && !value.selectedGadget) setShowGadgetChooser(true)
  })
  const combinedLabel = value.selectedGadget
    ? `${value.label} - ${value.selectedGadget.label}`
    : value.label
  return (
    <Gadgets.Padded>
      <Gadgets.Label>
        <Trans id='data.source.colon' />
      </Gadgets.Label>
      <Chooser.AssociationsTypeahead
        onSelect={updateDataLookupSource}
        id={value.id}
        label={combinedLabel}
        tileOptions={value.tileOptions}
      />
      {value.selectedGadget && (
        <button
          title='Edit Selected Field'
          className={cx('kp-button-transparent flex w-min gap-3', {
            'bg-light-gray-200': showGadgetChooser
          })}
          onClick={() => setShowGadgetChooser(true)}
        >
          <span>{value.selectedGadget.label}</span>
          <span className='text-xs text-medium-gray-500'>
            {value.selectedGadget.type}
          </span>
        </button>
      )}
      <AutoUpdateConfig id={formKey} Gadgets={Gadgets} className='mt-4' />
      {showGadgetChooser && (
        <GadgetChooser
          dataGadgets={dataGadgets}
          value={value}
          onChange={onChange}
          loading={loading}
          onHide={() => setShowGadgetChooser(false)}
        />
      )}
    </Gadgets.Padded>
  )
}

function GadgetChooser ({ value, onChange, loading, onHide, dataGadgets }) {
  const [selectedGadget, setSelectedGadget] = React.useState(
    value.selectedGadget
  )
  return (
    <div className='fixed z-10 animate-fade-in'>
      <div className='fixed left-[calc(50%-12rem)] top-20 flex min-h-max w-96 flex-col rounded bg-white shadow-lg shadow-medium-gray-100 dark:shadow-light-gray-200'>
        <div className='flex items-center justify-between border-b border-b-light-gray-300 bg-light-gray-100 px-4 py-2 uppercase text-medium-gray-500 dark:bg-light-gray-300 dark:text-dark-gray-500'>
          <p>Select Gadget</p>
          <button
            className='kp-button-transparent kp-button-icon kp-button-sm'
            onClick={() => onHide()}
          >
            <Icons.Close width='12px' height='12px' />
          </button>
        </div>
        <div className='flex max-h-80 min-h-[20rem] flex-1 flex-col overflow-scroll'>
          <ul>
            {loading ? (
              <Choice className='flex min-h-12 items-center justify-between px-2 py-4 text-dark-gray-100'>
                Loading
                <Spinner size={16} />
              </Choice>
            ) : (
              dataGadgets.map((field, i) => (
                <li key={field.id}>
                  <Choice
                    as='button'
                    onClick={() => setSelectedGadget(field)}
                    key={field.id}
                    className={cx(
                      'flex min-h-12 items-center px-2 py-4 text-dark-gray-500 transition-all active:bg-blue-100',
                      {
                        'bg-blue-100': selectedGadget?.id === field.id,
                        'hover:bg-light-gray-100':
                          selectedGadget?.id !== field.id
                      }
                    )}
                  >
                    <span className='flex-grow text-sm'>{field.label}</span>
                    {selectedGadget?.id === field.id && (
                      <button
                        disabled={!selectedGadget}
                        className='kp-button-solid animate-fade-in'
                        onClick={() => {
                          onChange({ ...value, selectedGadget })
                          onHide()
                        }}
                      >
                        <Trans id='continue' />
                      </button>
                    )}
                  </Choice>
                </li>
              ))
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

const getDataGadgets = template => {
  return uniqWith(
    collectGadgets(template).reduce((acc, gadget) => {
      if (REPEATABLE_TYPES.includes(gadget.type)) {
        return getRepeatableChildren(gadget).reduce((acc, child) => {
          if (INTEGRATION_TYPES.includes(child.type)) {
            acc.push(gadget)
          }
          return acc
        }, acc)
      }
      if (INTEGRATION_TYPES.includes(gadget.type)) {
        acc.push(gadget)
      }
      return acc
    }, []),
    isEqual
  )
}

const REPEATABLE_TYPES = ['Repeater', 'Table', 'Rules']
const INTEGRATION_TYPES = ['DataLookup', 'FormTypeahead', 'FormMultiselect']

const formQuery = gql`
  query FormQuery($id: ID!, $pageId: ID!) {
    app(id: $id) {
      id
      dataset(id: $pageId) {
        id
        formContainer {
          id
          template
        }
      }
    }
  }
`

const Choice = styled.div`
  cursor: pointer;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;

  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 2px #7da4d9;
  }
`
