/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import cx from 'clsx'
import { find, map, reject } from 'lodash'
import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useLocation } from 'react-router-dom'
import shortid from 'shortid'

import * as Icons from '../icons'
import { productBuilder } from './feature-flags'
import { useIds } from './use-ids'

const Item = React.forwardRef(
  (
    {
      value,
      remove,
      onChange,
      create,
      indexType,
      values,
      buildingProduct,
      ...rest
    },
    ref
  ) => (
    <div className='flex py-2' ref={ref} {...rest}>
      <Icons.Drag className='mr-4 mt-2 h-4 w-4 fill-light-gray-500 dark:fill-medium-gray-300' />
      <div className='flex-1'>
        <input
          className='kp-input w-full'
          value={value.lbl}
          onChange={e => onChange({ ...value, lbl: e.target.value })}
          onKeyDown={e => e.keyCode === 13 && create(e)}
          autoFocus
          data-testid='reorderable-item'
        />
        {buildingProduct &&
          (indexType ? (
            <div className='mt-1 text-xs text-medium-gray-500'>
              Kuali ID: {value.key}
            </div>
          ) : (
            <KualiIdInput
              id={value.key}
              value={value.customKey}
              onChange={customKey => onChange({ ...value, customKey })}
              values={values}
            />
          ))}
      </div>
      <button className='kp-button-transparent kp-button-icon' onClick={remove}>
        <div className='sr-only'>
          <Trans id='remove.item' />
        </div>
        {buildingProduct && indexType ? <Icons.Archive /> : <Icons.Delete />}
      </button>
    </div>
  )
)

function KualiIdInput ({ id, onChange, value, values }) {
  const location = useLocation()
  const isSettings = location.pathname.endsWith('/setting')
  let error = ''
  const allValues = map(reject(values, { key: id }), 'customKey')
  if (allValues.includes(value)) error = 'Identifier must be unique'
  if (!value) error = 'Identifier is required'
  return (
    <div className='pt-1'>
      <div className='flex items-center gap-1'>
        <label className='block whitespace-nowrap text-sm text-dark-gray-500'>
          {isSettings ? 'Option ID:' : 'Kuali ID:'}
        </label>
        <div className='relative'>
          {!isSettings && (
            <span className='absolute left-2 flex h-full items-center text-medium-gray-400'>
              kuali_
            </span>
          )}
          <input
            className={cx('kp-input w-full', { 'pl-[42px]': !isSettings })}
            value={value ?? ''}
            onChange={e =>
              onChange(
                e.target.value.replace(/ /g, '_').replace(/[^a-zA-Z0-9_]/g, '')
              )
            }
          />
        </div>
      </div>
      <div
        className={cx('pl-14', {
          'text-red-500': error,
          'text-green-400': !error
        })}
      >
        {error || 'Looks good!'}
      </div>
    </div>
  )
}

export default ({ value, onChange, children, indexType }) => {
  const { pageId } = useIds()
  const buildingProduct = productBuilder && !!pageId
  const options = indexType?.details?.options ?? []
  const values = [
    ...value,
    ...map(options, option => {
      return { ...option, customKey: option.key.replace('kuali_', '') }
    })
  ]
  const existingKeys = map(value, 'key')
  const archivedOptions = reject(options, o => existingKeys.includes(o.key))
  const create = () => {
    onChange([
      ...value,
      { key: shortid.generate(), lbl: '', defaultRadio: false }
    ])
  }
  return (
    <DragDropContext
      onDragEnd={result => {
        if (!result.destination) return
        const newValue = [...value]
        const [column] = newValue.splice(result.source.index, 1)
        newValue.splice(result.destination.index, 0, column)
        onChange(newValue)
      }}
    >
      <Droppable droppableId='droppable-items'>
        {(provided, snapshot) => (
          <div
            className='py-2'
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {value.map((item, i) => (
              <Draggable key={item.key} draggableId={item.key} index={i}>
                {(provided, snapshot) => (
                  <Item
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    value={item}
                    onChange={val => {
                      const newValue = [...value]
                      newValue[i] = val
                      onChange(newValue)
                    }}
                    remove={() => {
                      const newValue = [...value]
                      newValue.splice(i, 1)
                      onChange(newValue)
                    }}
                    create={create}
                    indexType={find(options, { key: item.key })}
                    values={values}
                    buildingProduct={buildingProduct}
                  />
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <div className='flex gap-4'>
        <button
          onClick={create}
          className={cx('flex-1', {
            'kp-button-outline': buildingProduct,
            'kp-button-solid': !buildingProduct
          })}
        >
          <Trans id='add' />
        </button>
        {!buildingProduct && children}
      </div>
      {!!archivedOptions.length && buildingProduct && (
        <div className='pt-8'>
          <label className='font-medium text-dark-gray-300'>
            <Trans id='archived.options' />
          </label>
          {archivedOptions.map(option => (
            <div key={option.key} className='pt-4'>
              <div className='flex gap-2'>
                <div className='flex-1 rounded bg-light-gray-100 px-2 py-1.5 text-sm text-medium-gray-500'>
                  {option.lbl}
                </div>
                <button
                  className='kp-button-outline'
                  onClick={() => onChange([...value, { ...option }])}
                >
                  <Trans id='restore' />
                </button>
              </div>
              <div className='mt-1 text-xs text-medium-gray-500'>
                Kuali ID: {option.key}
              </div>
            </div>
          ))}
        </div>
      )}
    </DragDropContext>
  )
}
