/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { getHours, getMinutes, isValid, parse } from 'date-fns'
import React from 'react'
import styled from 'styled-components'

import * as Icons from '../../../icons'
import Input from '../../../ui/input'

const timeFormat24 = 'HH:mm' // 24-hour format, ie 14:30
const timeFormat12 = 'h:mm a' // 12-hour format, ie 2:30 PM

export default function ({
  details,
  gridded,
  value,
  errors,
  onChange,
  ...rest
}) {
  const [data, setData] = React.useState(() => secondsToLocaleTimeString(value))
  const [hasError, setHasError] = React.useState(false)
  const Component = gridded ? MyInput : Input
  const placeholder = getPlaceholder(details)

  const handleChange = inputTime => {
    setData(inputTime)

    if (inputTime === '') {
      onChange('')
      setHasError(false)
      return
    }

    const parsedTime = parseTime(inputTime)

    if (parsedTime) {
      // Convert the valid input time to seconds to store in the database
      onChange(timeStringToSeconds(parsedTime))
      setHasError(false)
    } else {
      setHasError(true)
    }
  }

  return (
    <Wrapper>
      <Component
        aria-labelledby={rest['aria-labelledby']}
        {...(rest['aria-describedby'] && {
          'aria-describedby': rest['aria-describedby']
        })}
        aria-required={rest.required}
        aria-invalid={hasError}
        id={rest.id}
        value={data}
        onChange={handleChange}
        placeholder={placeholder}
      />
      <TimeIcon />
      {hasError && errorMessage()}
      {gridded && errors}
    </Wrapper>
  )
}

// Sample outputs: 14:30, 2:30 PM, 2:30 p.m.
const secondsToLocaleTimeString = seconds => {
  if (seconds === null || seconds === '' || seconds === undefined) return ''
  const baseDate = new Date(0) // January 1, 1970, 00:00:00 UTC
  const dateWithTime = new Date(baseDate.getTime() + seconds * 1000)
  // Format the time according to the current locale
  const timeFormatter = new Intl.DateTimeFormat(i18n.locale || 'en-US', {
    timeStyle: 'short',
    timeZone: 'UTC',
    hourCycle: getHourCycle()
  })

  return timeFormatter.format(dateWithTime)
}

// Return the user's preferred hour cycle,
// which can be independently set in some OSes
// Outputs: h11, h12, h23, h24
const getHourCycle = () => {
  return new Intl.DateTimeFormat().resolvedOptions().hourCycle || 'h12'
}

// returns a default placeholder if the details.placeholder is not set
const getPlaceholder = details => {
  return (
    (details?.placeholder?.enabled && details?.placeholder?.value) ??
    i18n._({
      id: 'time.placeholder',
      message: 'Enter a time like {time}',
      values: { time: secondsToLocaleTimeString(52200) }
    })
  )
}

// attempt to parse the timeString into a date using both 24-hour and 12-hour formats
const parseTime = timeString => {
  const parsedTime24 = parse(timeString, timeFormat24, new Date())
  const parsedTime12 = parse(timeString, timeFormat12, new Date())

  return isValid(parsedTime24)
    ? parsedTime24
    : isValid(parsedTime12)
      ? parsedTime12
      : null
}

// assumes timeString is a valid date or null
const timeStringToSeconds = timeString => {
  if (!timeString) return ''
  const { hours, minutes } = extractHoursAndMinutes(timeString)
  return hours * 3600 + minutes * 60
}

// assumes time is a valid date
const extractHoursAndMinutes = time => ({
  hours: getHours(time),
  minutes: getMinutes(time)
})

const errorMessage = () => {
  return (
    <Error className='text-red-400'>
      {i18n._({
        id: 'time.validation.message',
        message: "Won't save. Enter a valid time, such as {time}.",
        values: { time: secondsToLocaleTimeString(52200) }
      })}
    </Error>
  )
}

const Wrapper = styled.div`
  position: relative;
`
const TimeIcon = styled(Icons.Time)`
  position: absolute;
  top: 0px;
  left: 16px;
`

const MyInput = styled(Input)`
  border: none;
  outline: none;
  padding: 0 16px 16px 40px;
  background: none;
  width: 100%;
  html.dark & {
    // Outlier: dark:bg-white
    background: #444;
  }
`

const Error = styled.div`
  padding-left: 16px;
  padding-bottom: 8px;
`
