/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import React from 'react'

import { TextView } from '../../gadget-helpers'

export default function ({ value, gridded, condensed }) {
  return <TextView {...{ condensed, gridded }}>{format(value)}</TextView>
}

// Sample outputs: 14:30, 2:30 PM, 2:30 p. m.
function format (seconds) {
  if (seconds === null) return ''
  const baseDate = new Date(0) // January 1, 1970, 00:00:00 UTC
  const dateWithTime = new Date(baseDate.getTime() + seconds * 1000)

  const timeFormatter = new Intl.DateTimeFormat(i18n.locale || 'en-US', {
    timeStyle: 'short',
    timeZone: 'UTC'
  })
  return timeFormatter.format(dateWithTime)
}
