/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { filter, find } from 'lodash'
import React from 'react'

import Loading from '../../components/loading'
import * as Affiliations from '../../components/lookups/affiliations'
import * as Groups from '../../components/lookups/groups'
import * as Roles from '../../components/lookups/roles'
import * as Users from '../../components/lookups/users'
import { ModalPage } from '../../components/modal-page'
import { GraphQLError as Error } from '../../components/system-error'
import { useQuery } from '../../components/use-query'
import Checkbox from '../../ui/checkbox'
import { useAddIdentityMutation } from './components/mutation.add-identity-to-role'
import { useRemoveIdentityMutation } from './components/mutation.remove-identity-from-role'
import { QueryContextProvider } from './components/use-query-context'

export default function SystemPermissions () {
  return (
    <ModalPage title={i18n._('pages.permissions.system.permissions')}>
      <SystemPermissionsInner />
    </ModalPage>
  )
}

const BUILD_ADMIN_POLICY_ID = '5cf7d98b5c18be79112cd4f2'
const BUILD_USER_POLICY_ID = '5d38badad5151fba67aa78b2'

export function SystemPermissionsInner () {
  const query = getAdminSettingsPageQuery()
  const { data, error, loading } = useQuery(query)
  if (loading) return <Loading />
  if (error) return <Error error={error} />
  const adminPolicy = find(data.listPolicyGroups, { id: BUILD_ADMIN_POLICY_ID })
  const userPolicy = find(data.listPolicyGroups, { id: BUILD_USER_POLICY_ID })
  return (
    <QueryContextProvider query={query}>
      <div className='p-[30px] text-sm'>
        <BuildUsers
          policy={userPolicy}
          userAffiliations={data.userAffiliations}
        />
        <BuildAdministrators
          policy={adminPolicy}
          userAffiliations={data.userAffiliations}
        />
      </div>
    </QueryContextProvider>
  )
}

function BuildUsers ({ policy }) {
  const removeIdentity = useRemoveIdentityMutation(policy.id)
  const addIdentity = useAddIdentityMutation(policy.id)
  return (
    <div className='mb-5 bg-white px-[30px] py-5 shadow-custom'>
      <h3 className='text-sm font-bold text-staticblack'>
        <Trans id='pages.permissions.who' />
      </h3>
      <Checkbox
        id='permission-all-authenticated-users'
        name='permission-all-authenticated-users'
        label={i18n._('pages.permissions.all.authenticated')}
        checked={!!find(policy.identities, { id: '$$AUTHENTICATED$$' })}
        onChange={checked => {
          if (checked) {
            addIdentity({
              id: '$$AUTHENTICATED$$',
              type: 'VIRTUAL',
              label: 'Authenticated'
            })
          } else {
            removeIdentity('$$AUTHENTICATED$$')
          }
        }}
      />
      <label className='mb-[10px] mt-5 block'>
        <Trans id='pages.permissions.these.users' />
      </label>
      <Users.Multiselect
        standalone
        id={`${policy.id}-users`}
        placeholder={i18n._('pages.permissions.add.user')}
        onAdd={obj => addIdentity({ ...obj, type: 'USER' })}
        onRemove={removeIdentity}
        value={filter(policy.identities, { type: 'USER' })}
      />
      <label className='mb-[10px] mt-5 block'>
        <Trans id='pages.permissions.these.groups' />
      </label>
      <Groups.Multiselect
        standalone
        id={`${policy.id}-groups`}
        placeholder={i18n._('pages.permissions.add.group')}
        onAdd={obj => addIdentity({ ...obj, type: 'GROUP' })}
        onRemove={removeIdentity}
        value={filter(policy.identities, { type: 'GROUP' })}
      />
      <label className='mb-[10px] mt-5 block'>
        <Trans id='pages.permissions.these.roles' />
      </label>
      <Roles.Multiselect
        standalone
        id={`${policy.id}-roles`}
        placeholder={i18n._('pages.permissions.add.role')}
        onAdd={obj => addIdentity({ ...obj, type: 'ROLE' })}
        onRemove={removeIdentity}
        value={filter(policy.identities, { type: 'ROLE' })}
      />
      <AffiliationsSection
        id={`${policy.id}-affiliations`}
        policy={policy}
        removeIdentity={removeIdentity}
        addIdentity={addIdentity}
      />
    </div>
  )
}

function BuildAdministrators ({ policy }) {
  const removeIdentity = useRemoveIdentityMutation(policy.id)
  const addIdentity = useAddIdentityMutation(policy.id)
  return (
    <div className='mb-5 bg-white px-[30px] py-5 shadow-custom'>
      <h3 className='text-sm font-bold text-staticblack'>
        <Trans id='pages.permissions.who.kuali' />
      </h3>
      <label className='mb-[10px] mt-5 block'>
        <Trans id='pages.permissions.these.users' />
      </label>
      <Users.Multiselect
        standalone
        id={`${policy.id}-users`}
        placeholder={i18n._('pages.permissions.add.user')}
        onAdd={obj => addIdentity({ ...obj, type: 'USER' })}
        onRemove={removeIdentity}
        value={filter(policy.identities, { type: 'USER' })}
      />
      <label className='mb-[10px] mt-5 block'>
        <Trans id='pages.permissions.these.groups' />
      </label>
      <Groups.Multiselect
        standalone
        id={`${policy.id}-groups`}
        placeholder={i18n._('pages.permissions.add.group')}
        onAdd={obj => addIdentity({ ...obj, type: 'GROUP' })}
        onRemove={removeIdentity}
        value={filter(policy.identities, { type: 'GROUP' })}
      />
      <AffiliationsSection
        id={`${policy.id}-affiliations`}
        policy={policy}
        removeIdentity={removeIdentity}
        addIdentity={addIdentity}
      />
    </div>
  )
}

const userAffiliationsQuery = gql`
  query UserAffiliations {
    userAffiliations {
      orgDN: orgDn
      affiliation
    }
  }
`

function AffiliationsSection ({ id, policy, removeIdentity, addIdentity }) {
  const { data, loading } = useQuery({
    query: userAffiliationsQuery,
    fetchPolicy: 'network-only'
  })
  const selected = filter(policy.identities, { type: 'AFFILIATION' })
  const affiliations = data?.userAffiliations ?? []
  const hasAffiliations = !loading && affiliations.length > 0
  if (!hasAffiliations && selected.length === 0) return null
  return (
    <>
      <label className='mb-[10px] mt-5 block'>
        <Trans id='pages.permissions.these.affiliations' />
      </label>
      {hasAffiliations && (
        <Affiliations.Multiselect
          standalone
          id={id}
          affiliations={data?.userAffiliations}
          placeholder={i18n._('pages.permissions.add.affiliation')}
          onAdd={obj => addIdentity({ ...obj, type: 'AFFILIATION' })}
          onRemove={removeIdentity}
          value={selected}
        />
      )}
    </>
  )
}

export const getAdminSettingsPageQuery = () => ({
  variables: {},
  query: gql`
    query AdminSettingsPageQuery {
      listPolicyGroups: systemPolicyGroups {
        id
        removable
        name
        policies {
          id
          version
          statements {
            action
            resource
            effect
          }
        }
        identities {
          type
          id
          label
        }
      }
    }
  `
})
