/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import cx from 'clsx'
import _ from 'lodash'
import React from 'react'

import * as Icons from '../../icons'
import { ChangeBadge } from '../../pages-runner/view-form/components/compare-changes'
import { FormbotDataContext } from '../engine/formbot-react/hooks'

export default function compareChanges ({ template, context, value, details }) {
  if (!template.formKey) return {}
  const { changes, selected, setSelected, labelOnly } = context.compare || {}
  const key = template.formKey.replace(`.${details?.rowIndex ?? 0}.`, '.*.')
  const change = changes?.find(c => [template.formKey, key].includes(c.formKey))
  if (!change) return {}
  const { prev, next, type } = change
  const focused = template.formKey === selected
  context = _.omit(context, 'compare')
  return {
    label: label => (
      <div className='flex flex-col items-start gap-1'>
        <ChangeBadge type={type} />
        {label}
      </div>
    ),
    component: (component, rerender) => {
      if (labelOnly) return component
      if (!setSelected) {
        if (type === 'changed') {
          const compare = { changes, labelOnly: true }
          context = { ...context, prev, next, compare }
          component = rerender(value, template, context, true)
        }
        return (
          <div
            className={cx('h-full [&>*>*]:h-full', {
              'bg-red-100': type === 'removed',
              'bg-green-100': type === 'added',
              'bg-orange-100': type === 'changed'
            })}
          >
            {component}
          </div>
        )
      }
      return (
        <div
          className={cx('relative h-full', {
            'border-4 border-blue-500 bg-orange-100 dark:bg-orange-950': focused
          })}
          onClick={() => setSelected(template.formKey)}
        >
          {focused && (
            <div
              data-testid='current-change'
              className='absolute left-0 top-[calc(100%+2px)] z-10 flex w-full flex-col items-center gap-2 rounded-lg bg-orange-100 p-4 shadow-md dark:bg-orange-950 md:-left-20 md:w-[calc(100%+160px)] md:flex-row'
            >
              {type === 'added' ? (
                <>
                  <NewField />
                  <Icons.Back className='-rotate-90 md:rotate-180' />
                  {rerender(next.value, next.template, context)}
                </>
              ) : type === 'removed' ? (
                <>
                  <FormbotDataContext.Provider value={prev.doc}>
                    {rerender(prev.value, prev.template, context)}
                  </FormbotDataContext.Provider>
                  <Icons.Back className='-rotate-90 md:rotate-180' />
                  <RemovedField />
                </>
              ) : (
                <>
                  <FormbotDataContext.Provider value={prev.doc}>
                    {rerender(prev.value, prev.template, { ...context, next })}
                  </FormbotDataContext.Provider>
                  <Icons.Back className='-rotate-90 md:rotate-180' />
                  {rerender(next.value, next.template, { ...context, prev })}
                </>
              )}
            </div>
          )}
          {component}
        </div>
      )
    }
  }
}

function NewField () {
  return (
    <div className='flex flex-1 items-center justify-center gap-2 py-4'>
      <Icons.Add className='fill-green-400' />
      <Trans id='compare_changes.new_field' message='New Field' />
    </div>
  )
}

function RemovedField () {
  return (
    <div className='flex flex-1 items-center justify-center gap-2 py-4'>
      <Icons.Remove className='fill-red-500' />
      <Trans id='compare_changes.removed_field' message='Removed Field' />
    </div>
  )
}
