/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { clamp, toPlainObject, toSafeInteger } from 'lodash'

import browserCheckRegEx from './regex'

const STORAGE_KEY = 'browserWarningLastClosed'
const MAX_WARNING_SNOOZE_IN_DAYS = 16
const SUPPORTED_BROWSER_DOCUMENTATION_URL =
  'https://kuali.zendesk.com/hc/articles/21037047325211'

function getlastClosedInfo () {
  const rawLastClosedInfo = localStorage.getItem(STORAGE_KEY)
  if (!rawLastClosedInfo) return { count: 0, closedAt: 0 }
  try {
    const parsedValue = toPlainObject(JSON.parse(rawLastClosedInfo))
    return {
      count: Math.max(0, toSafeInteger(parsedValue.count)),
      closedAt: clamp(toSafeInteger(parsedValue.closedAt), 0, Date.now())
    }
  } catch (error) {
    return { count: 0, closedAt: 0 }
  }
}

function setLastClosedInfo (count) {
  localStorage.setItem(
    STORAGE_KEY,
    JSON.stringify({ count, closedAt: Date.now() })
  )
}

function differenceInCalendarDays (date1, date2) {
  const diff = Math.abs(date1 - date2)
  return Math.round(diff / (1000 * 60 * 60 * 24))
}

const isSupported = browserCheckRegEx.test(navigator.userAgent)

if (!isSupported) {
  const lastClosedInfo = getlastClosedInfo()
  const allowedSnoozeDurationInDays = clamp(
    toSafeInteger(Math.pow(2, lastClosedInfo.count - 1)),
    MAX_WARNING_SNOOZE_IN_DAYS
  )
  const daysSinceLastClosed = differenceInCalendarDays(
    Date.now(),
    lastClosedInfo.closedAt
  )
  const isShown = daysSinceLastClosed >= allowedSnoozeDurationInDays
  if (isShown) {
    const warningBar = document.createElement('div')
    warningBar.style.display = 'flex'
    warningBar.style.backgroundColor = '#ffcb80'
    warningBar.style.borderBottom = '1px solid #ffa628'
    warningBar.style.lineHeight = '1'
    warningBar.style.padding = '0.5em 1em'
    warningBar.style.alignItems = 'center'
    warningBar.role = 'alert'
    warningBar.innerHTML = `
      <svg style="margin-right:0.357em" fill="#c54700" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.98812 -0.000899923C5.85052 0.0340949 3.81256 0.908909 2.31479 2.43443C1.56537 3.18055 0.974081 4.07011 0.576263 5.04993C0.178446 6.02976 -0.0177335 7.07974 -0.000547985 8.1371C-0.00177763 9.17029 0.200895 10.1936 0.595853 11.1483C0.990812 12.103 1.57029 12.9704 2.30109 13.7008C3.03188 14.4311 3.89962 15.0101 4.85457 15.4045C5.80953 15.7989 6.83293 16.0009 7.86612 15.9991H8.00878C10.147 15.9771 12.1892 15.1078 13.6873 13.5818C15.1853 12.0559 16.0169 9.99806 15.9995 7.85977C16.0019 6.81404 15.7952 5.77838 15.3916 4.81369C14.988 3.849 14.3955 2.97476 13.6491 2.24237C12.9027 1.50998 12.0174 0.934238 11.0452 0.548995C10.073 0.163752 9.03361 -0.0232109 7.98812 -0.000899923ZM6.99945 11.0271C6.99465 10.8964 7.01595 10.766 7.06212 10.6436C7.10829 10.5212 7.17839 10.4093 7.26834 10.3143C7.35828 10.2194 7.46626 10.1433 7.58596 10.0905C7.70565 10.0378 7.83467 10.0094 7.96545 10.0071H7.98345C8.24652 10.0076 8.49919 10.1099 8.68847 10.2926C8.87776 10.4753 8.98897 10.7242 8.99878 10.9871C9.00368 11.1178 8.98244 11.2482 8.93631 11.3707C8.89017 11.4931 8.82007 11.6051 8.7301 11.7001C8.64014 11.7951 8.53213 11.8711 8.41239 11.9239C8.29265 11.9766 8.1636 12.0049 8.03278 12.0071H8.01478C7.75181 12.0063 7.49934 11.9038 7.31012 11.7212C7.12091 11.5386 7.0096 11.2899 6.99945 11.0271ZM7.33278 8.33243V4.33243C7.33278 4.15562 7.40302 3.98605 7.52805 3.86103C7.65307 3.736 7.82264 3.66577 7.99945 3.66577C8.17626 3.66577 8.34583 3.736 8.47086 3.86103C8.59588 3.98605 8.66612 4.15562 8.66612 4.33243V8.33243C8.66612 8.50924 8.59588 8.67881 8.47086 8.80384C8.34583 8.92886 8.17626 8.9991 7.99945 8.9991C7.82264 8.9991 7.65307 8.92886 7.52805 8.80384C7.40302 8.67881 7.33278 8.50924 7.33278 8.33243Z" />
      </svg>
      <span style="flex: 1">It looks like you are using a browser that we do not support. If you encounter any issues, try using a <a target="_blank" rel="noopener noreferrer" href="${SUPPORTED_BROWSER_DOCUMENTATION_URL}" style="color: #306167; font-weight: bold">supported browser</a>.</span>
      <style>
        .close-browser-warning:hover {
          background: #fff3e0;
        }
        .close-browser-warning:active {
          border-color: #c54700;
        }
        [data-whatinput='keyboard'] .close-browser-warning:focus::after {
          border-color: #4caec3;
        }
        @media print {
          .unsupported-browser-warning {
            display: none !important;
          }
        }
      </style>
    `

    warningBar.classList.add('unsupported-browser-warning')
    const button = document.createElement('button')
    button.className =
      'close-browser-warning kp-button-transparent kp-button-small kp-button-icon'
    button.innerHTML = `
      <svg fill="#666" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-label="Close unsupported browser warning">
        <path d="M9.5333 8.11993C9.51778 8.10445 9.50546 8.08606 9.49706 8.06581C9.48866 8.04556 9.48433 8.02386 9.48433 8.00193C9.48433 7.98001 9.48866 7.9583 9.49706 7.93805C9.50546 7.91781 9.51778 7.89941 9.5333 7.88393L15.7086 1.70927C15.8961 1.52149 16.0014 1.26692 16.0012 1.00156C16.001 0.736194 15.8954 0.481773 15.7076 0.294265C15.5199 0.106757 15.2653 0.0015217 14.9999 0.00170923C14.7346 0.00189677 14.4801 0.107492 14.2926 0.295265L8.11796 6.46727C8.10248 6.48279 8.08409 6.4951 8.06384 6.5035C8.04359 6.51191 8.02188 6.51623 7.99996 6.51623C7.97804 6.51623 7.95633 6.51191 7.93608 6.5035C7.91584 6.4951 7.89744 6.48279 7.88196 6.46727L1.7073 0.295265C1.61445 0.202377 1.50422 0.128685 1.3829 0.0783977C1.26157 0.0281102 1.13153 0.00221158 1.0002 0.00218062C0.734959 0.00211811 0.480559 0.107424 0.292963 0.294932C0.105366 0.48244 -5.95304e-05 0.736791 -0.000122042 1.00203C-0.000184555 1.26727 0.105121 1.52167 0.292629 1.70927L6.46663 7.88393C6.48215 7.89941 6.49446 7.91781 6.50287 7.93805C6.51127 7.9583 6.51559 7.98001 6.51559 8.00193C6.51559 8.02386 6.51127 8.04556 6.50287 8.06581C6.49446 8.08606 6.48215 8.10445 6.46663 8.11993L0.292629 14.2953C0.199785 14.3882 0.126145 14.4984 0.0759147 14.6198C0.0256843 14.7411 -0.000152995 14.8712 -0.000122042 15.0025C-5.95304e-05 15.2677 0.105366 15.5221 0.292963 15.7096C0.385851 15.8024 0.496117 15.8761 0.617465 15.9263C0.738812 15.9765 0.868865 16.0024 1.0002 16.0023C1.26544 16.0023 1.51979 15.8969 1.7073 15.7093L7.88196 9.53393C7.89744 9.51841 7.91584 9.5061 7.93608 9.49769C7.95633 9.48929 7.97804 9.48497 7.99996 9.48497C8.02188 9.48497 8.04359 9.48929 8.06384 9.49769C8.08409 9.5061 8.10248 9.51841 8.11796 9.53393L14.2926 15.7093C14.4801 15.8969 14.7345 16.0023 14.9997 16.0023C15.265 16.0024 15.5194 15.8971 15.707 15.7096C15.8946 15.5221 16 15.2677 16 15.0025C16.0001 14.7373 15.8948 14.4829 15.7073 14.2953L9.5333 8.11993Z" />
      </svg>
    `

    function handleClick () {
      warningBar.remove()
      setLastClosedInfo(lastClosedInfo.count + 1)
      button.removeEventListener('click', handleClick)
    }
    button.addEventListener('click', handleClick)
    warningBar.appendChild(button)

    document.body.prepend(warningBar)
  }
}
