/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'

import { validationGadget } from '../../../components/feature-flags'
import { AlertError as Icon } from '../../../icons'
import { RequiredConfig } from './config'

export default {
  layout: true,

  getAssembler: ({ Basic, GriddedText }, gridded) =>
    gridded ? GriddedText : Basic,

  View: () => <div />,

  Edit: props => (
    <div
      role='note'
      tabindex='0'
      aria-labelledby={
        props['aria-labelledby'] ?? props.details?.['aria-labelledBy']
      }
      className='sr-only'
    >
      {props.a11yDesc ?? props.details?.a11yDesc}
    </div>
  ),
  meta: {
    hidden: !validationGadget,
    initialTemplate: () => ({
      label: '',
      conditionalVisibility: { enabled: true }
    }),
    label: 'Validation',
    Icon,
    help: 'This gadget will display a custom error based on your settings. We recommend limiting its visibility so that it only shows when needed.',
    category: 'Smart'
  },
  RequiredConfig
}
