/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { clamp, find, reduce } from 'lodash'
import React from 'react'

import {
  CalcFunctionSelector,
  MATH_GADGETS
} from '../../../pages-builder/calculations/calculation-config'
import {
  childAutoUpdates,
  getRepeatableChildren
} from '../../engine/formbot/utils'

export const MAX_REPEATS = 100

export function RequiredConfig ({ Gadgets }) {
  return (
    <Gadgets.Custom
      configKey='defaultRepeatCount'
      label={`Start with this many repeats (max ${MAX_REPEATS})`}
    >
      {({ onChange, value }) => (
        <NumericInput
          id='defaultRepeatCount'
          placeholder='1'
          onChange={onChange}
          value={value}
        />
      )}
    </Gadgets.Custom>
  )
}

export function OptionalConfig ({ Gadgets, childrenTemplate, allGadgets }) {
  const calcFields = React.useMemo(() => {
    return getRepeatableChildren({ type: 'Repeater', childrenTemplate }).filter(
      (child, _i, children) => {
        const type =
          child.type === 'DataLink'
            ? child.details?.selectedOutputField?.type
            : child.type
        return (
          MATH_GADGETS.includes(type) &&
          !childAutoUpdates(child, children, allGadgets)
        )
      }
    )
  }, [childrenTemplate])
  return (
    <>
      <Gadgets.ConfigBox
        configKey='limitRepeats.enabled'
        label='Limit number of repeats'
      >
        <Gadgets.Custom
          configKey='limitRepeats.value'
          label={`Limit number of repeats (max ${MAX_REPEATS})`}
        >
          {({ onChange, value }) => (
            <NumericInput
              id='limitRepeats.value'
              placeholder='Max number of repeats'
              onChange={onChange}
              value={value}
            />
          )}
        </Gadgets.Custom>
      </Gadgets.ConfigBox>
      <Gadgets.ConfigBox
        configKey='calculationFooter.enabled'
        label='Enable calculation footer'
      >
        <Gadgets.Custom configKey='calculationFooter.fields' unPadded>
          {({ onChange, value }) => (
            <CalculationFields
              fields={calcFields}
              onChange={onChange}
              value={value}
            />
          )}
        </Gadgets.Custom>
      </Gadgets.ConfigBox>
    </>
  )
}

function NumericInput ({ onChange, ...rest }) {
  return (
    <input
      className='kp-input'
      type='number'
      min='1'
      max={MAX_REPEATS}
      step='1'
      onChange={e => {
        const newValue = e.target.value
        if (newValue === '') return onChange(null)
        const number = parseInt(newValue, 10)
        if (!isNaN(number)) {
          return onChange(clamp(number, 1, MAX_REPEATS))
        }
      }}
      {...rest}
    />
  )
}

function CalculationFields ({ fields, onChange, value }) {
  const handleChange = (changedField, val) => {
    onChange(
      reduce(
        fields,
        (acc, field) => {
          if (field.id === changedField.id) {
            if (val !== 'none') {
              acc.push({ id: field.id, calcFunction: val })
            }
          } else {
            const existing = find(value, { id: field.id })
            if (existing) {
              acc.push(existing)
            }
          }
          return acc
        },
        []
      )
    )
  }
  return fields.map(field => (
    <CalcFunctionSelector
      key={field.id}
      gadgetType='Repeater'
      onChange={val => handleChange(field, val)}
      value={find(value, { id: field.id })?.calcFunction ?? 'none'}
    >
      <h5 className='mr-2'>
        {(field.customName?.enabled && field.customName?.value) ||
          field.label ||
          field.formKey}
      </h5>
    </CalcFunctionSelector>
  ))
}
