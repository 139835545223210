/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import cx from 'clsx'
import { get, includes } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import Spinner from '../../../components/spinner'
import { useAppIds } from '../../../components/use-app-ids-context'
import { useAlerts } from '../../../ui/alerts'
import Button from '../../../ui/button'
import { useInputs, useValueHash } from '../../integration-utils'

export default function IntegrationFill ({
  value,
  onChange,
  details,
  context
}) {
  const id = details?.id
  const inputs = useInputs(details)
  const { error, loading, refetch } = useIntegrations(
    id,
    inputs,
    details,
    value,
    newValue => {
      if (!newValue) return onChange(null)
      if (newValue?.id !== value?.id) return onChange(newValue)
    }
  )
  React.useEffect(() => {
    if (!loading && !error && inputs !== null) return
    if ((error || inputs === null) && value != null) onChange(null)
  }, [loading, error, inputs, value])
  if (details?.headless && !context.configMode) return null
  return (
    <div className='flex min-h-[19px] flex-nowrap items-center justify-between'>
      {!!details.testIntegration && inputs !== null && (
        <RefreshButton
          onClick={() => {
            onChange(null)
            refetch()
          }}
        >
          Refetch
        </RefreshButton>
      )}
      {loading && (
        <div className='absolute right-4 top-4 pl-2'>
          <Spinner size={16} />
        </div>
      )}
      <span
        className={cx('flex-1 transition-all', {
          'text-medium-gray-300': loading
        })}
      >
        {value?.label}
      </span>
    </div>
  )
}

const useIntegrations = (id, inputs, details, existingValue, onChange) => {
  const inputHash = useValueHash(inputs)
  const alerts = useAlerts()
  const [refetching, setRefetching] = React.useState(false)
  const { appId, actionId } = useAppIds()
  const isTesting = !!details.testIntegration
  const q = isTesting
    ? getTestIntegrationQuery({ id, data: inputs, details, alerts, onChange })
    : getInvokeIntegrationQuery({
        appId,
        actionId,
        id,
        data: inputs,
        inputHash,
        storedInputHash: existingValue?.inputHash,
        onChange
      })

  const { error, loading, refetch } = useQuery(...q)

  return {
    error,
    loading: loading || refetching,
    refetch: () => {
      setRefetching(true)
      refetch()
        .then(resp => details.testReport(resp.data.testFetchIntegration))
        .catch(error => {
          alerts.type3(error.message, 'error')
          details.testReport({})
        })
        .finally(() => setRefetching(false))
    }
  }
}

const invokeIntegrationQuery = gql`
  query InvokeIntegrationQuery($appId: ID!, $id: ID!, $data: JSON) {
    app(id: $appId) {
      id
      sharedWithMe {
        integration(id: $id) {
          id
          invoke(data: $data)
        }
      }
    }
  }
`

const invokeIntegrationWithActionQuery = gql`
  query InvokeIntegrationWithActionQuery(
    $actionId: String!
    $id: ID!
    $data: JSON
  ) {
    action(actionId: $actionId) {
      id
      document {
        id
        app {
          id
          sharedWithMe {
            integration(id: $id) {
              id
              invoke(data: $data)
            }
          }
        }
      }
    }
  }
`

const getInvokeIntegrationQuery = ({
  appId,
  actionId,
  id,
  data,
  inputHash,
  storedInputHash,
  onChange
}) => [
  actionId ? invokeIntegrationWithActionQuery : invokeIntegrationQuery,
  {
    variables: { appId, actionId, id, data },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip:
      (!appId && !actionId) ||
      !id ||
      data === null ||
      inputHash === null ||
      inputHash === storedInputHash,
    onCompleted: data => {
      const app = actionId ? data?.action?.document?.app : data?.app
      const value = app?.sharedWithMe?.integration?.invoke
      value ? onChange({ ...value, inputHash }) : onChange(null)
    },
    onError: _error => {
      onChange(null)
    }
  }
]

const testIntegrationQuery = gql`
  query TestIntegration($id: ID, $integration: JSON!, $data: JSON) {
    testFetchIntegration(
      args: { id: $id, integration: $integration, data: $data }
    ) {
      status
      headers
      data
    }
  }
`

const getTestIntegrationQuery = ({ id, data, details, alerts, onChange }) => [
  testIntegrationQuery,
  {
    variables: { id, data, integration: details.testIntegration },
    skip: !details.testIntegration || data === null,
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      const value = formatTestData(
        data?.testFetchIntegration,
        details.testIntegration
      )
      onChange(value)
      details.testReport(data.testFetchIntegration)
    },
    onError: error => {
      onChange(null)
      alerts.type3(error.message, 'error')
      details.testReport({})
    }
  }
]

const str = value => (value == null ? value : String(value))
const formatTestData = ({ data, headers, status } = {}, integration) => {
  if (!data || typeof data !== 'object') return null
  if (!(status >= 200 && status < 300)) return null

  const contentTypes = get(headers, 'content-type')
  if (!includes(contentTypes, 'json')) {
    return null
  }
  const id = str(get(data, integration?.__idKey || 'id'))
  const label = str(get(data, integration?.__labelKey || 'label'))
  return { id, label, data }
}

const RefreshButton = styled(Button)`
  position: absolute;
  top: -44px;
  right: 14px;
`
