/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import * as Sentry from '@sentry/browser'
import { cloneDeep } from 'lodash'
import React from 'react'
import shortid from 'shortid'
import styled from 'styled-components'

import { useAlerts } from '../../../../../ui/alerts'
import Button from '../../../../../ui/button'
import Input from '../../../../../ui/input'
import { Flex } from '../../../../../ui/layout'
import Textarea from '../../../../../ui/textarea'
import { Body2 } from '../../../../../ui/typography'
import { useUpdateGroupMutation } from './mutation.update-group'

export default function RolePopup ({
  group,
  hide,
  initialTitle,
  initialDescription,
  id
}) {
  const alerts = useAlerts()
  const [title, setTitle] = React.useState(initialTitle ?? null)
  const [description, setDescription] = React.useState(initialDescription ?? '')
  const [updateGroup] = useUpdateGroupMutation(group)
  const roleSchemas = cloneDeep(group?.roleSchemas) ?? [
    ...(group?.category?.roleSchemas ?? [])
  ]

  const submit = e => {
    e.preventDefault()
    if (!title) return
    if (id) {
      const toEdit = roleSchemas.find(r => r.id === id)
      if (!toEdit) return
      toEdit.name = title
      toEdit.description = description
    } else {
      roleSchemas.push({
        __typename: 'RoleSchema',
        id: shortid.generate(),
        name: title ?? <Trans id='no.title' message='No Title' />,
        description
      })
    }
    hide()
    updateGroup({ roleSchemas })
      .then(() => {
        alerts.type3(
          `${id ? <Trans id='role.updated' message='Role Updated' /> : <Trans id='role.created' message='Role Created' />}`,
          'success'
        )
      })
      .catch(err => {
        Sentry.captureException(err)
        alerts.type3(
          i18n._({ id: 'error.creating.role', message: 'Error Creating Role' }),
          'error'
        )
      })
  }
  return (
    <div>
      <Wrapper data-testid='popover-new-role'>
        <Label className='text-medium-gray-500' htmlFor='title'>
          <Trans id='role.name' message='Role Name' />
        </Label>
        <Input
          className='mb-1 w-full'
          value={title ?? ''}
          id='title'
          onChange={setTitle}
          onEnter={submit}
        />
        <Label className='text-medium-gray-500' htmlFor='description'>
          <Trans id='description' message='Description' />
        </Label>
        <Textarea
          value={description}
          id='description'
          onChange={setDescription}
          onEnter={submit}
        />
        <Flex>
          <Button outline width='100%' mr={2} onClick={hide}>
            <Trans id='cancel' message='Cancel' />
          </Button>
          <Button width='100%' disabled={!title} onClick={submit}>
            <Trans id='save' message='Save' />
          </Button>
        </Flex>
      </Wrapper>
    </div>
  )
}

const Wrapper = styled.div`
  padding: 16px;
`

const Label = styled(Body2).attrs({ as: 'label' })``
