/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { ApolloProvider, gql, useQuery } from '@apollo/client'
import { cloneDeep } from 'lodash'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useImmer } from 'use-immer'

import Loading from '../components/loading'
import { useDocumentTitle } from '../components/use-document-title'
import NotFound from '../pages/not-found'
import apolloClient from './apollo-client'
import FinishedPage from './finished'
import FormPage from './form'

export default props => (
  <ApolloProvider client={apolloClient}>
    <Anonymous {...props} />
  </ApolloProvider>
)

function Anonymous () {
  const { appId, pageId } = useParams()
  const { query, ...rest } = getAnonymousPageQuery(appId, pageId)
  const { loading, error, data } = useQuery(query, rest)
  useDocumentTitle(data?.app?.name)
  const structure = React.useMemo(() => {
    if (!data?.app) return null
    return {
      template: cloneDeep(data.app.dataset.form.template),
      metaFields: data.app.dataset.form.metaFields,
      integrationFields: data.app.dataset.form.integrationFields,
      trashed: []
    }
  }, [data])
  const [page, setPage] = React.useState('form')
  const [formData, updateFormData] = useImmer({})
  const [documentId, setDocumentId] = React.useState(null)

  const props = {
    structure,
    formData,
    helpLink: data?.app?.helpLink,
    updateFormData,
    setPage,
    data,
    setDocumentId,
    documentId
  }
  return <Content loading={loading} error={error} page={page} {...props} />
}

const Content = ({ loading, error, page, ...props }) => {
  if (loading) return <Loading />
  if (error) return <NotFound />
  if (page === 'form') return <FormPage {...props} />
  if (page === 'finished') return <FinishedPage {...props} />
  return <div>???</div>
}

const getAnonymousPageQuery = (appId, pageId) => ({
  variables: { appId, pageId },
  query: gql`
    query GetApp($appId: String!, $pageId: ID) {
      tenant {
        timezone
      }
      app(args: { id: $appId }) {
        id
        name
        helpLink
        branding {
          id
          color
          logo
          maxHeight
          alt
        }
        dataset(id: $pageId) {
          labelSize
          paginated
          submissionsDisabled
          formSchedule {
            enabled
            startDate
            endDate
          }
          limitSubmissionsForDataset {
            enabled
            limit
          }
          submissionCount
          showNewFormButton
          autoStartNewForm
          form {
            id
            template
            metaFields {
              id
              formKey
              type
              label
              details
            }
            integrationFields {
              id
              formKey
              type
              label
              details
            }
          }
        }
      }
    }
  `
})
