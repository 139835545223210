/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import React from 'react'

import * as Lookups from '../../../components/lookup-controls'
import { useAppIds } from '../../../components/use-app-ids-context'
import useDebouncedValue from '../../../components/use-debounced-value'
import { useFormbotData } from '../../engine/formbot-react/hooks'
import { insertData } from '../../filter-config'

export default function Typeahead ({
  value,
  onChange,
  errors,
  details,
  inline,
  ...props
}) {
  const formData = useFormbotData()
  const [query, setQuery] = React.useState('')
  const debouncedQuery = useDebouncedValue(query, 300)
  const placeholder =
    details?.placeholder?.enabled && (details?.placeholder?.value ?? '')
  const dataFilledFilter = !details?.needsVersionFilter
    ? insertData(details.filter, formData)
    : undefined
  const versionConfig = details.needsVersionConfig
    ? 'LATEST_VERSION'
    : (details?.versionConfig ?? 'LATEST_VERSION')
  const dataFilledVersionFilter = details?.needsVersionFilter
    ? insertData(details.versionFilter, formData)
    : undefined
  const { options, loading } = useIntegrations(
    details,
    debouncedQuery,
    dataFilledFilter,
    dataFilledVersionFilter,
    versionConfig
  )

  return (
    <>
      <Lookups.Multiselect
        id={props.id}
        placeholder={placeholder}
        options={options}
        onChange={onChange}
        value={value}
        query={query}
        setQuery={setQuery}
        aria={{
          labelledby: props['aria-labelledby'],
          describedby: props['aria-describedby']
        }}
        gridded={props.gridded}
        loading={loading}
        hideResults={inline}
      />
      {props.gridded && errors}
    </>
  )
}

const useIntegrations = (
  { id, pageId },
  query,
  filter,
  versionFilter,
  versionConfig
) => {
  const { appId } = useAppIds()

  const sort = ['meta.title']
  const q = getSearchDocumentsQuery(
    appId,
    id,
    pageId,
    query,
    sort,
    filter,
    versionFilter,
    versionConfig
  )
  const { data, error, loading } = useQuery(...q)

  let options =
    data?.app?.sharedWithMe?.app?.dataset?.documentConnection?.edges ?? []
  options = options.map(({ node }) => node)
  return { options, error, loading }
}

const getSearchDocumentsQuery = (
  appId,
  id,
  pageId,
  query,
  sort,
  fields,
  secondaryFields,
  versionConfig
) => [
  gql`
    query SearchDocuments(
      $appId: ID!
      $id: ID!
      $pageId: ID
      $query: String
      $sort: [String!]
      $fields: Operator
      $secondaryFields: Operator
      $versionConfig: VersionConfig
    ) {
      app(id: $appId) {
        id
        sharedWithMe {
          app(id: $id) {
            id
            dataset(id: $pageId) {
              id
              documentConnection(
                args: {
                  query: $query
                  sort: $sort
                  fields: $fields
                  secondaryFields: $secondaryFields
                  versionConfig: $versionConfig
                }
                keyBy: ID
              ) {
                edges {
                  node {
                    id
                    documentSetId
                    label
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  {
    variables: {
      appId,
      id,
      pageId,
      query,
      sort,
      fields,
      secondaryFields,
      versionConfig
    },
    skip: !appId || !id
  }
]
