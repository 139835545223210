/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import * as Sentry from '@sentry/browser'
import React from 'react'
import { Link, useLocation, useOutletContext } from 'react-router-dom'
import { useImmer } from 'use-immer'

import AnimatedOutlet from '../../../components/animated-outlet'
import Pagination from '../../../components/data-table/pagination'
import Loading from '../../../components/loading'
import Spinner from '../../../components/spinner'
import { GraphQLError as Error } from '../../../components/system-error'
import { useQuery } from '../../../components/use-query'
import * as Icons from '../../../icons'
import { useAlerts } from '../../../ui/alerts'
import Button from '../../../ui/button'
import EmptyState from './empty-state'
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableWrapper
} from './layout'
import { QueryContextProvider } from './use-query-context'
import { formatFromNow, paramsToGql } from './util'

export default function DraftsOuter () {
  return (
    <>
      <AnimatedOutlet context={useOutletContext()} />
      <Drafts />
    </>
  )
}

function Drafts () {
  const location = useLocation()
  const alerts = useAlerts()
  React.useEffect(() => {
    if (location?.state?.fromSave) {
      alerts.type2(
        i18n._({
          id: 'pages.submitter.drafts.saved',
          message: 'Your draft has been saved.'
        }),
        'info'
      )
    }
  }, [alerts, location])
  const [params, updateParams] = useImmer(defaultParams([]))
  const query = myDraftsQuery(params)
  const { data, loading, error, refetch } = useQuery(query)
  const totalCount = data?.myDrafts?.totalCount
  const edges = data?.myDrafts?.edges
  if ((!loading && !edges) || error) return <Error error={error} />
  if ((!loading && edges?.length === 0) || totalCount < 1) {
    return (
      <EmptyState
        illustration='jennifer'
        message={i18n._({
          id: 'pages.submitter.drafts.none',
          message: 'You have no drafts.'
        })}
      />
    )
  }
  return (
    <QueryContextProvider query={query}>
      <TableWrapper className='relative min-h-[50vh]'>
        {!edges ? (
          <Loading />
        ) : (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>
                  <Trans id='document.type' />
                </TableHeaderCell>
                <TableHeaderCell>
                  <Trans id='title' />
                </TableHeaderCell>
                <TableHeaderCell>
                  <Trans id='created' />
                </TableHeaderCell>
                <TableHeaderCell>
                  <Trans id='time.elapsed' />
                </TableHeaderCell>
                <TableHeaderCell style={{ width: '1px' }}>
                  <Trans id='actions' />
                </TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {edges?.map(edge => (
                <DraftRow
                  refetch={refetch}
                  value={edge.node}
                  key={edge.node.id}
                />
              ))}
            </TableBody>
            {edges?.length > 0 && (
              <Pagination
                total={data?.myDrafts?.totalCount}
                skip={params.skip}
                limit={params.limit}
                onUpdate={({ skip, limit }) =>
                  updateParams(draft => {
                    draft.skip = skip
                    draft.limit = limit
                  })
                }
              />
            )}
          </Table>
        )}
      </TableWrapper>
    </QueryContextProvider>
  )
}

function DraftRow ({ refetch, value }) {
  const [loading, setLoading] = React.useState(false)
  const [discardDraft] = useMutation(discardDraftMutation)
  const alerts = useAlerts()
  const submitted = i18n.date(new Date(value.createdAt), {
    dateStyle: 'medium',
    timeStyle: 'short'
  })

  const elapsed = formatFromNow(new Date(value.createdAt))

  const handleDeleteClick = async e => {
    e.stopPropagation()
    const deleteButton = close => {
      return (
        <Button
          onClick={async () => {
            setLoading(true)
            close()
            await discardDraft({
              variables: { documentId: value.documentId }
            })
              .then(() => {
                refetch().then(() =>
                  alerts.type3(
                    i18n._({
                      id: 'pages.submitter.drafts.deleted',
                      message: 'Draft deleted.'
                    }),
                    'success'
                  )
                )
              })
              .catch(err => {
                setLoading(false)
                alerts.type3(
                  i18n._({
                    id: 'pages.submitter.drafts.failed',
                    message: 'Failed to delete draft'
                  }),
                  'error'
                )
                Sentry.captureException(err)
              })
          }}
          data-testid='confirm-delete'
        >
          <Trans id='delete' message='Delete' />
        </Button>
      )
    }
    alerts.type1(
      i18n._({
        id: 'pages.submitter.drafts.permanent',
        message: 'This is permanent'
      }),
      i18n._({
        id: 'pages.submitter.drafts.permanent.data',
        message:
          'Deleting this draft will permanently remove the document and all of its data. Are you sure you want to delete?'
      }),
      'confirm',
      deleteButton
    )
  }
  return (
    <TableRow style={{ cursor: 'pointer' }}>
      <LinkTableCell to={value.id} tabIndex={-1}>
        {value.label}
      </LinkTableCell>
      <LinkTableCell to={value.id}>{value?.title}</LinkTableCell>
      <LinkTableCell to={value.id} tabIndex={-1}>
        {submitted}
      </LinkTableCell>
      <LinkTableCell to={value.id} tabIndex={-1}>
        {elapsed}
      </LinkTableCell>
      <TableCell>
        <div className='flex items-center justify-center'>
          {loading ? (
            <Spinner size={16} />
          ) : (
            <Button transparent icon onClick={handleDeleteClick}>
              <span className='sr-only'>
                <Trans id='delete' message='Delete' />
              </span>
              <Icons.Delete />
            </Button>
          )}
        </div>
      </TableCell>
    </TableRow>
  )
}

function LinkTableCell ({ to, tabIndex, children, ...props }) {
  return (
    <TableCell {...props}>
      <Link
        to={to}
        tabIndex={tabIndex}
        className='block h-full w-full px-4 py-3 max-md:px-0.5'
      >
        {children}
      </Link>
    </TableCell>
  )
}

const defaultParams = options => ({
  skip: 0,
  limit: 10,
  q: undefined,
  filters: [],
  sorts: [{ ascending: true, field: 'meta.submittedAt', id: 'a1' }], // TODO this ascending is backwards because of the header component
  columns: defaultColumns(options)
})

const defaultColumns = options => [
  {
    type: 'Dropdown',
    formKey: 'formContainer',
    label: `${i18n._('document.type')}`,
    details: { options },
    visible: true
  },
  {
    type: 'DatePicker',
    formKey: 'meta.title',
    label: `${i18n._('title')}`,
    details: {},
    filterOut: ['draft'],
    visible: true
  },
  {
    type: 'DatePicker',
    formKey: 'meta.submittedAt',
    label: `${i18n._('date.submitted')}`,
    details: {},
    visible: true
  },
  {
    type: 'Duration',
    formKey: 'timeElapsed',
    label: `${i18n._('time.elapsed')}`,
    details: {},
    unsortable: true,
    visible: true
  },
  {
    type: 'WorkflowStatus',
    formKey: 'meta.workflowStatus',
    label: `${i18n._('status')}`,
    details: {},
    filterOut: ['draft'],
    visible: true
  }
]

const myDrafts = gql`
  query MyDrafts($skip: Int!, $limit: Int!) {
    myDrafts(args: { skip: $skip, limit: $limit }) {
      totalCount
      edges {
        node {
          id
          label
          title
          createdAt
          action
          documentId
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        skip
        limit
      }
    }
  }
`

const myDraftsQuery = params => ({
  variables: paramsToGql(params),
  fetchPolicy: 'cache-and-network',
  query: myDrafts
})

const discardDraftMutation = gql`
  mutation DiscardDocument($documentId: ID!) {
    discardDocumentV2(args: { id: $documentId }) {
      id
    }
  }
`
