/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { filter, find, isObject, map } from 'lodash'
import React from 'react'
import shortid from 'shortid'

import Radios from '../../../ui/radios'

export default function RadiosEdit (props) {
  const { details, id, onChange } = props
  const options = filter(details.options, 'key')
  const value = isObject(props.value) ? props.value.id : props.value || ''

  const defaultRadio = find(details.options, { defaultRadio: true })
  React.useEffect(() => {
    if (!value && defaultRadio) {
      onChange({ id: defaultRadio.key, label: defaultRadio.lbl })
    }
  }, [defaultRadio, onChange, value])

  return (
    <Radios
      aria-labelledby={props['aria-labelledby']}
      {...(props['aria-describedby'] && {
        'aria-describedby': props['aria-describedby']
      })}
      id={`wrapper-${id}${shortid.generate()}`}
      name={`name-${id}`}
      onChange={val => {
        const item = find(details.options, { key: val })
        onChange({ id: item.key, label: item.lbl })
      }}
      options={map(options, o => ({
        label: o.lbl,
        htmlId: shortid.generate(),
        id: o.key,
        ariaLabel: o.lbl
      }))}
      value={value}
    />
  )
}
