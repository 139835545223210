/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { produce } from 'immer'
import { cloneDeep, forEach, get, isEmpty, last } from 'lodash'
import React from 'react'
import shortid from 'shortid'

import { useIdFormkeyMap } from '../../engine/formbot-react/hooks'
import {
  getRepeatableChildren,
  traverseTemplate
} from '../../engine/formbot/utils'

export function getRepeatStructure (gadget, repeatId, repeatIndex, props) {
  return {
    // these don't need to be populated because `gadgetInstances` is already passed
    // through context
    metaFields: [],
    integrationFields: [],

    template: produce(gadget, draft => {
      traverseTemplate(draft, g => {
        g.id = `${props.id}.${repeatIndex}.${g.id}`
        if (g.formKey) {
          g.formKey = `${props.formKey}.data.${repeatIndex}.data.${g.formKey}`
        }
        g.details = {
          ...g.details,
          rowId: repeatId,
          rowIndex: repeatIndex,
          parentFormKey: props.formKey,
          parentValue: props.value
        }
        forEach(get(g, 'conditionalVisibility.value.parts'), part => {
          part.formKey = part.formKey.replace('.*.', `.${repeatIndex}.`)
        })
      })
    })
  }
}

export function useCreateRepeat (childrenTemplate, formbot) {
  const baseRepeat = React.useMemo(() => {
    const base = {}
    traverseTemplate({ children: childrenTemplate }, gadget => {
      const { formKey, type } = gadget
      if (!formKey) return
      base[formKey] = formbot.getGadget(type).defaultValue ?? null
    })
    return base
  }, [childrenTemplate, formbot])
  return () => ({ id: shortid.generate(), data: cloneDeep(baseRepeat) })
}

export function useFooterGadgets (childrenTemplate, footerFields) {
  const idMap = useIdFormkeyMap()
  return React.useMemo(() => {
    const gadgetsMap = {}
    if (!footerFields.length) return []
    traverseTemplate({ children: childrenTemplate }, child => {
      if (!footerFields.some(f => f.id === child.id)) return
      if (child.type === 'DataLink') {
        const selectedOutputField = child.details?.selectedOutputField
        const parentFullFormKey = idMap[child.details?.parentId]
        if (selectedOutputField && parentFullFormKey) {
          const parentFormKey = last(parentFullFormKey.split('.data.*.data.'))
          gadgetsMap[child.id] = {
            ...selectedOutputField,
            formKey: `${parentFormKey}.${selectedOutputField.path}`,
            label: child.label
          }
        }
      } else {
        gadgetsMap[child.id] = child
      }
    })
    return footerFields.map(field => ({ ...gadgetsMap[field.id], ...field }))
  }, [childrenTemplate, footerFields, idMap])
}

export function useSetDefault (
  createRepeat,
  defaultRepeatCount,
  onChange,
  value
) {
  React.useEffect(() => {
    if (value?.data?.length) return
    const length = defaultRepeatCount || 1
    onChange({ data: Array.from({ length }, () => createRepeat()) })
  }, [createRepeat, defaultRepeatCount, onChange, value])
}

// Returns a new gadget config with any footer fields removed
// that no longer have corresponding gadgets in the childrenTemplate.
// Used when a child gadget has been deleted.
export function filterFooter (gadget) {
  const childIds = getRepeatableChildren(gadget).map(child => child.id)
  return produce(gadget, draft => {
    const footer = draft.details?.calculationFooter
    const fields = footer?.fields
    if (isEmpty(fields)) return
    footer.fields = fields.filter(field => childIds.includes(field.id))
  })
}
