/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
// NOTE: imported separately for easier mocking in tests
import { get, isEqual, map } from 'lodash'
import debounce from 'lodash/debounce'
import React from 'react'
import styled from 'styled-components'

import Textarea from '../../../ui/textarea'
import { getAutocompleteValue } from '../text/autocomplete'

const calcRows = ref => {
  const oldRows = ref.rows
  ref.rows = 2
  const rows = Math.ceil(ref.scrollHeight / 24)
  if (oldRows === rows) {
    ref.rows = oldRows
  }

  return rows
}

export default class TextareaEdit extends React.Component {
  constructor (props) {
    super(props)

    this.state = { lastUpdate: props.value, text: props.value, rows: 3 }
    this.debouncedOnChange = debounce(val => {
      this.setState({ lastUpdate: val })
      this.props.onChange(val)
    }, 750)
  }

  componentDidMount () {
    this.setState({ rows: calcRows(this.textArea) })
  }

  UNSAFE_componentWillReceiveProps ({ value }) {
    if (this.state.lastUpdate === value) {
      // NOTE: lastUpdate is important because our onChange prop
      // will often set new state upstream in consumer applications,
      // which will then provide a new value prop back down through our
      // component tree. We ensure we don't re-render a stale value this
      // way when a user is typing quickly!
      return
    }

    if (this.state.text !== value) {
      this.setState({ text: value })
    }
  }

  handleChange = (text, e) => {
    const rows = calcRows(e.target)
    if (this.state.rows !== rows) {
      this.setState({ rows })
    }
    this.setState({ text })
    this.debouncedOnChange(text)
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (
      this.props.value === nextProps.value &&
      get(this, 'state.text') === get(nextState, 'text') &&
      get(this, 'state.rows') === get(nextState, 'rows') &&
      this.props.error === nextProps.error &&
      isEqual(this.props.context, nextProps.context) &&
      isEqual(this.props.details, nextProps.details)
    ) {
      return false
    }
    return true
  }

  render () {
    const {
      context,
      details,
      formKey,
      gridded,
      id,
      required,
      templateValidations
    } = this.props
    const placeholder =
      details?.placeholder?.enabled && (details?.placeholder?.value ?? '')
    const autoFocus = get(details, 'autoFocus')
    const onFocus = get(details, 'onFocus')
    const Component = gridded ? MyTextarea : Textarea
    const validations = get(context, ['validations', formKey], [])
    const validateMaxLength = templateValidations?.minMaxCharacters?.enabled
      ? templateValidations?.minMaxCharacters?.value?.max
      : undefined

    return (
      <>
        <Component
          aria-labelledby={this.props['aria-labelledby']}
          aria-describedby={this.props['aria-describedby']}
          aria-required={required}
          ref={me => {
            this.textArea = me
          }}
          id={id}
          maxLength={validateMaxLength || details?.limit}
          onChange={this.handleChange}
          placeholder={placeholder}
          rows={this.state.rows}
          value={this.state.text}
          autoFocus={autoFocus}
          onFocus={onFocus}
          autoComplete={getAutocompleteValue(details)}
        />
        {!!details?.limit && (
          <span className='text-right text-xs text-medium-gray-500'>
            {details.limit - (this.state.text?.length ?? 0)} characters
            remaining
          </span>
        )}
        {map(validations, message => (
          <Error className='text-red-400'>{message}</Error>
        ))}
      </>
    )
  }
}

const MyTextarea = styled(Textarea)`
  border: none;
  outline: none;
  padding: 0 16px 16px 16px;
  background: none;
  width: 100%;
  overflow: hidden;
  line-height: 24px;
  height: auto;
  html.dark & {
    // Outlier: dark:bg-white
    background: #444;
  }
`

const Error = styled.div`
  padding-left: 16px;
  padding-bottom: 8px;
`
