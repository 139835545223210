/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import { get, map } from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { formbot, gadgets } from '../../formbot'
import v from '../../formbot/engine/formbot/validate'
import { TableCell, TableRow as _TableRow } from '../../ui/table'

const TableRow = styled(_TableRow)`
  cursor: pointer;
`

const GadgetValue = ({ document, column, schema }) => {
  const { View, defaultValue } = gadgets[column.type] || {}
  if (!View) return null
  let value = get(document, column.formKey, defaultValue)
  const fbValidate = (type, value, gadget) => {
    const validator = formbot.getGadget(type).validateShape(v, gadget)
    const errors = validator(value)
    return !errors || errors.length === 0
  }
  if (column.transform) value = column.transform(value)
  const fbRenderView = (type, value, details) => {
    const Gadget = formbot.getGadget(type).View
    return (
      <Gadget
        fbRenderView={fbRenderView}
        fbValidate={fbValidate}
        value={value}
        details={details}
      />
    )
  }
  return (
    <View
      formKey={column.formKey}
      fbRenderView={fbRenderView}
      gridded
      id={column.id}
      value={value}
      details={get(column, 'details') || {}}
      childrenTemplate={get(column, 'childrenTemplate') || []}
      schema={schema}
      progDisc={() => true}
      condensed
    />
  )
}

export default function Row ({
  document,
  columns,
  onRowClick,
  linkTo,
  children,
  schema,
  rowHeader,
  rowHeaderValue,
  ...props
}) {
  const handleRowClick = () => onRowClick?.(document)
  return (
    <TableRow data-testid='row' onClick={handleRowClick} {...props}>
      {rowHeader && (
        <th className='sr-only' scope='row'>
          {rowHeaderValue}
        </th>
      )}
      {map(columns, (column, i) => (
        <TableCell
          as={column.header ? 'th' : 'td'}
          scope={column.header ? 'row' : undefined}
          key={column.formKey}
          data-test={column.formKey}
          className={cx('min-h-min !p-0', column.className)}
        >
          {linkTo ? (
            <Link
              data-testid='row-link'
              className='block h-full w-full px-4'
              to={linkTo}
              tabIndex={i === 0 ? undefined : -1}
              onKeyUp={e => {
                if (e.key === ' ') e.target.click()
              }}
            >
              <GadgetValue
                document={document}
                column={column}
                schema={schema}
              />
            </Link>
          ) : (
            <span className='block h-full w-full px-4'>
              <GadgetValue
                document={document}
                column={column}
                schema={schema}
              />
            </span>
          )}
        </TableCell>
      ))}
      {children}
    </TableRow>
  )
}
