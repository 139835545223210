import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { map, omitBy } from 'lodash'
import React from 'react'

export function ValidationErrors ({ label, validations, close }) {
  validations = omitBy(validations, val => val.meta)
  return (
    <div>
      <p>{label}</p>
      <details>
        <summary>
          <Trans id='details' />
        </summary>
        <ul>
          {map(validations, (val, key) => (
            <li key={key} className='text-red-500'>
              <strong>{getLabel(key, val)}</strong>:{' '}
              {localizeErrors(val.errors).join(', ')}
            </li>
          ))}
        </ul>
      </details>
      <button
        className='kp-button kp-button-transparent kp-button-sm'
        onClick={close}
      >
        <Trans id='dismiss' />
      </button>
    </div>
  )
}

function localizeErrors (errors) {
  return errors.map(error => {
    switch (error) {
      case 'email is invalid':
        return i18n._({ id: 'email.is.invalid', message: 'email is invalid' })
      case 'link must start with http:// or https://':
        return i18n._({
          id: 'link.must.start.with',
          message: 'link must start with http:// or https://'
        })
      default:
        return error
    }
  })
}

function getLabel (key, value) {
  let label = getGadgetLabel(value.gadget)
  if (value.index !== undefined) {
    let index = value.index
    // Tables currently have a "footer" row that offsets the index
    if (value.parent?.type === 'Repeater') index++
    label = `${index} > ${label}`
  }
  if (value.parent) {
    label = `${getGadgetLabel(value.parent)} > ${label}`
  }
  return label
}

function getGadgetLabel (gadget) {
  return gadget.details?.headless
    ? `[${gadget.formKey.replace(/^data\./, '')}]`
    : gadget.label
}
