/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { cloneDeep, forEach, get, set } from 'lodash'
import React from 'react'
import shortid from 'shortid'
import styled from 'styled-components'

import Checkbox from '../ui/checkbox'
import ConfigBox from '../ui/config-box'
import { formbot } from './'

const Padded = styled.div`
  padding-bottom: ${p => (p.unPadded ? 0 : 10)}px;
  display: flex;
  flex-direction: column;
`

const Label = styled.label`
  display: block;
  font-size: 14px;
  font-weight: 500;
`

function CheckboxGadget (props) {
  return (
    <Checkbox
      id={shortid.generate()}
      name={shortid.generate()}
      checked={props.value || false}
      onChange={props.onChange}
      label={props.details.checkLabel}
    />
  )
}

function DisabledCheckboxGadget (props) {
  return <CheckboxGadget {...props} disabled />
}

const ValueContext = React.createContext({})
const SaveContext = React.createContext(() => () => {})
const SaveObjectContext = React.createContext(() => {})
const ErrorsContext = React.createContext({})

function registerGadget (gadget, key) {
  return Object.fromEntries(
    ['Edit', 'View'].map(mode => {
      const Control = gadget[mode]
      function Gadget ({
        id,
        configKey,
        gridded,
        unPadded,
        label,
        error,
        testid,
        transformOut = a => a,
        className,
        'aria-describedby': ariaDescribedby,
        ...details
      }) {
        const value = React.useContext(ValueContext)
        const save = React.useContext(SaveContext)
        const errors = React.useContext(ErrorsContext)
        return (
          <Padded unPadded={unPadded} className={className}>
            <Label htmlFor={id || configKey} className='text-dark-gray-300'>
              {label}
            </Label>
            <Control
              a11yDesc={label}
              aria-labelledby=''
              aria-describedby={ariaDescribedby}
              context={{}}
              error={error}
              data-testid={testid}
              details={details}
              formKey={configKey}
              id={id || configKey}
              gridded={gridded}
              NOTFOUND_TYPE={key}
              onChange={value => save(configKey)(transformOut(value))}
              value={get(value, configKey)}
            />
            {errors?.[configKey] && (
              <div className='text-red-400'>{errors?.[configKey]}</div>
            )}
          </Padded>
        )
      }
      return [mode, Gadget]
    })
  )
}

function ConfigBoxGadget ({ configKey, ...rest }) {
  const value = React.useContext(ValueContext)
  const save = React.useContext(SaveContext)
  const enabled = get(value, configKey) ?? false
  return (
    <ConfigBox configKey={configKey} enabled={enabled} save={save} {...rest} />
  )
}

function CustomGadget ({
  configKey,
  defaultValue,
  children,
  group,
  unPadded,
  className,
  ...props
}) {
  const thisValue = React.useContext(ValueContext)
  const save = React.useContext(SaveContext)
  const saveObj = React.useContext(SaveObjectContext)
  const [onChange, value] = configKey
    ? [save(configKey), get(thisValue, configKey, defaultValue)]
    : [saveObj, thisValue]
  return (
    <Padded
      unPadded={unPadded}
      {...(group && { as: 'fieldset' })}
      className={className}
    >
      {props.label && (
        <Label
          {...(group ? { as: 'legend' } : { htmlFor: props.id || configKey })}
          className='text-dark-gray-300'
        >
          {props.label}
        </Label>
      )}
      {props.withGadgets ? (
        <StaticFormbot value={cloneDeep(value)} update={onChange}>
          {Gadgets => children({ onChange, value, Gadgets })}
        </StaticFormbot>
      ) : (
        children({ onChange, value })
      )}
    </Padded>
  )
}

export default function StaticFormbot ({
  value,
  update,
  errors,
  mode = 'Edit',
  children
}) {
  const save = key => val => {
    update(set(cloneDeep(value), key, val))
  }
  const gadgets = React.useMemo(() => {
    const gadgets = {}
    forEach(formbot.context.gadgets, (gadget, key) => {
      gadgets[key] = registerGadget(gadget, key)[mode]
    })
    gadgets.Checkbox = registerGadget(
      { Edit: CheckboxGadget, View: DisabledCheckboxGadget },
      'Checkbox'
    )[mode]
    gadgets.Padded = Padded
    gadgets.Label = Label
    gadgets.ConfigBox = ConfigBoxGadget
    gadgets.Custom = CustomGadget
    return gadgets
  }, [mode])
  return (
    <SaveContext.Provider value={save}>
      <SaveObjectContext.Provider value={update}>
        <ValueContext.Provider value={value}>
          <ErrorsContext.Provider value={errors}>
            {children(gadgets, value)}
          </ErrorsContext.Provider>
        </ValueContext.Provider>
      </SaveObjectContext.Provider>
    </SaveContext.Provider>
  )
}
