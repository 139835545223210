/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { i18n } from '@lingui/core'
import React from 'react'
import { unstable_usePrompt as usePrompt } from 'react-router-dom'

import { productBuilder } from '../../components/feature-flags'
import { LoadingPage } from '../../components/loading'
import { GLaDOS, PortalBlue } from '../../components/portals'
import { GraphQLError as Error } from '../../components/system-error'
import { useIds } from '../../components/use-ids'
import Toggle from '../../ui/toggle'
import FormDND from '../form/dnd'
import { useManageTemplate } from '../form/use-manage-template'
import FormRuntime from './runtime'

export default function Settings ({ isTable }) {
  const { appId, datasetId: pageId } = useIds()
  const variables = { appId, pageId }
  const { error, data } = useQuery(query, {
    variables,
    fetchPolicy: 'cache-and-network'
  })
  if (error) return <Error error={error} />
  if (!data?.app) return <LoadingPage />
  return <FormsInner isTable={isTable} resp={data} q={{ query, variables }} />
}

function FormsInner ({ isTable, resp, q }) {
  const [configure, setConfigure] = React.useState(false)
  const { data, template } = resp.app.settings
  const { canManage, canConfigureProduct } = resp.app.viewer
  const configMode = canManage && canConfigureProduct && productBuilder
  return (
    <GLaDOS>
      {configMode && (
        <div className='flex h-16 shrink-0 items-center justify-between border-b border-light-gray-300 print:hidden'>
          <div className='flex-1'>
            <PortalBlue />
          </div>
          <Toggle
            className='flex-1 !bg-blue-500 dark:!bg-blue-200'
            value={configure}
            onChange={() => setConfigure(a => !a)}
            off='Settings Page'
            on='Configure'
          />
          <div className='flex-1' />
        </div>
      )}
      {configure ? (
        <FormConfigure isTable={isTable} template={template} q={q} />
      ) : (
        <FormRuntime data={data} template={template} />
      )}
    </GLaDOS>
  )
}

function FormConfigure ({ isTable, template: original, q }) {
  const { appId, datasetId } = useIds()
  const [t, update] = useManageTemplate(q, appId, datasetId, original)
  usePrompt({
    when: t.loading || t.unsavedChanges,
    message: i18n._('pagesbuilder.form.sure')
  })
  return (
    <FormDND
      isProduct
      isTable={isTable}
      gadgetIndexTypes={{}}
      metaFields={[]}
      integrationFields={[]}
      user={{}}
      findReferences={() => []}
      template={t.template}
      update={update}
      preview={false}
    />
  )
}

const query = gql`
  query SettingsPageQuery($appId: ID!, $pageId: ID!) {
    app(id: $appId) {
      id
      viewer {
        canManage
        canConfigureProduct
      }
      settings(id: $pageId) {
        id
        template
        data(keyBy: ID)
      }
    }
  }
`
