/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import React from 'react'

export function OptionalConfig ({ id, Gadgets }) {
  return (
    <>
      <Gadgets.ConfigBox
        configKey='placeholder.enabled'
        label={i18n._('show.placeholder.text')}
        description={i18n._('show.placeholder.text.data')}
      >
        <Gadgets.Text configKey='placeholder.value' />
      </Gadgets.ConfigBox>
      <Gadgets.ConfigBox
        configKey='autocomplete.enabled'
        label={i18n._({
          id: 'configure.autocomplete',
          message: 'Configure autocomplete'
        })}
      >
        <Gadgets.Dropdown
          id={`${id}-autocomplete`}
          label={i18n._({
            id: 'autocomplete.choose.type',
            message: 'Choose Type'
          })}
          configKey='autocomplete.value'
          transformOut={o => o?.id || null}
          options={[
            {
              key: 'on',
              lbl: i18n._({ id: 'autocomplete.on', message: 'On' })
            },
            {
              key: 'off',
              lbl: i18n._({ id: 'autocomplete.off', message: 'Off' })
            }
          ]}
          noEmptyOption
        />
      </Gadgets.ConfigBox>
    </>
  )
}
