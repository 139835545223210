/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { useQuery } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import gql from 'graphql-tag'
import { find } from 'lodash'
import React from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'

import { TopTabs } from '../../components/app-layout'
import { ModalPage } from '../../components/modal-page'
import Sidebar from '../../components/sidebar'
import { PageContextProvider } from '../../components/use-page-context'
import * as Icons from '../../icons'
import { Empty } from './empty'

export const MaintenanceTableContext = React.createContext(false)

export default function MaintenanceTables () {
  const { appId, tableId } = useParams()
  const q = getMaintenanceTablesQuery(appId, tableId)
  const { data } = useQuery(q.query, q)

  return (
    <ModalPage title={i18n._('general.app.data')}>
      <MaintenanceTableContext.Provider value>
        <MaintenanceModal data={data}>
          <Outlet />
        </MaintenanceModal>
      </MaintenanceTableContext.Provider>
    </ModalPage>
  )
}

function MaintenanceModal ({ children, data }) {
  const location = useLocation()
  const navigate = useNavigate()
  const { tableId } = useParams()
  const [sidebarActive, setSidebarActive] = React.useState(false)
  const firstId = find(data?.app?.maintenanceTables, { type: 'dataset' })?.id
  const table = find(data?.app?.maintenanceTables, { id: tableId })
  React.useEffect(() => {
    if (firstId && !tableId) {
      navigate(`${location.pathname}/${firstId}/form`, { replace: true })
    }
  }, [firstId, tableId])
  if (!data?.app) {
    return (
      <div>
        <Trans id='pagesbuilder.maintenance.loading' />
      </div>
    )
  }
  const isExternal =
    location.pathname.endsWith('/external') ||
    location.pathname.endsWith('/dashboard') ||
    location.pathname.endsWith('/setting')
  return (
    <div className='relative flex pt-12 dark:bg-light-gray-300'>
      <button
        className='absolute left-4 top-4 z-[12] min-[501px]:hidden'
        onClick={e => {
          e.stopPropagation()
          setSidebarActive(!sidebarActive)
        }}
      >
        <Icons.MenuVertical />
      </button>
      <div
        className={cx(
          'fixed left-0 top-0 z-[11] h-screen w-screen bg-transparent',
          { block: sidebarActive, hidden: !sidebarActive }
        )}
        active={sidebarActive}
        onClick={() => setSidebarActive(false)}
      />
      <Sidebar
        configMode={data?.app?.viewer?.canConfigureProduct}
        isTable
        pages={data.app.maintenanceTables}
        sidebarActive={sidebarActive}
      />
      {!data.app.maintenanceTables.length ? (
        <Empty />
      ) : (
        <PageContextProvider page={table}>
          <div className='flex flex-1 flex-col'>
            {!isExternal && <TopTabs maint />}
            <div
              className={cx(
                'relative mx-6 mb-6 flex flex-col overflow-auto rounded-lg',
                isExternal
                  ? '-mt-9 h-[calc(100vh-148px)]'
                  : 'h-[calc(100vh-184px)] bg-white'
              )}
            >
              {children}
            </div>
          </div>
        </PageContextProvider>
      )}
    </div>
  )
}

const getMaintenanceTablesQuery = (appId, tableId) => ({
  variables: { appId, tableId },
  query: gql`
    query MaintenanceTablesQuery($appId: ID!) {
      app(id: $appId, isConfiguration: true) {
        id
        viewer {
          canConfigureProduct
        }
        maintenanceTables {
          id
          type
          details
          label {
            locked
            value
          }
          icon {
            locked
            value
          }
        }
      }
    }
  `
})
