/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import { useMatches, useOutlet, useResolvedPath } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

export default function AnimatedOutlet ({ context }) {
  const outlet = useOutlet(context)
  const key = useRouteKey()
  return (
    <TransitionGroup>
      <CSSTransition key={key} timeout={450}>
        <>{outlet}</>
      </CSSTransition>
    </TransitionGroup>
  )
}

function useRouteKey () {
  const matches = useMatches()
  const resolved = useResolvedPath('.')
  const p = resolved.pathname.replace(/\/$/, '')
  let good = false
  for (const match of matches) {
    const p2 = match.pathname.replace(/\/$/, '')
    if (p === p2) good = true
    if (good === true && p !== p2) return p2
  }
  return 'no-match'
}
