/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import versionInfo from './version.json'

export default {
  announceKit: {
    widget: 'https://announcekit.app/widgets/v2/2oMyE8'
  },
  apollo: {
    name: 'builder-ui',
    version: process.env.REACT_APP_SENTRY_RELEASE || 'app-builder-ui@dev',
    uri: '/app/api/v0/graphql'
  },
  sentry: {
    enabled: !!process.env.REACT_APP_SENTRY_RELEASE,
    dsn:
      process.env.REACT_APP_SENTRY_DSN ||
      'https://b0ba62ea999d408989eb8c146a3fe159@o418432.ingest.sentry.io/5321377',
    environment: process.env.REACT_APP_SENTRY_ENV || 'development',
    release: process.env.REACT_APP_SENTRY_RELEASE || 'app-builder-ui@dev',
    beforeBreadcrumb (breadcrumb, hint) {
      if (breadcrumb.category === 'ui.click') {
        const { target } = hint.event
        if (target.ariaLabel) {
          breadcrumb.message = target.ariaLabel
        } else if (target.id) {
          breadcrumb.message = target.id
        } else if (target['data-testid']) {
          breadcrumb.message = target['data-testid']
        } else if (target.innerText) {
          breadcrumb.message = target.innerText
        }
      }
      return breadcrumb
    },
    beforeSend (event, hint) {
      if (!event.extra) event.extra = {}
      event.extra.user = JSON.stringify(window.loggedInUser)
      return event
    }
  },
  version: versionInfo.latestVersion
}
