/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import { formatDistanceToNow } from 'date-fns'
import {
  cloneDeep,
  filter,
  findLast,
  get,
  lowerCase,
  map,
  reverse,
  set
} from 'lodash'
import React from 'react'

import Spinner from '../../../components/spinner'
import Tooltip, { TooltipTrigger } from '../../../components/tooltip'
import UserTypeahead from '../../../flowbot/components/user-typeahead'
import * as Icons from '../../../icons'
import { useAlerts } from '../../../ui/alerts'
import Button from '../../../ui/button'
import { Space } from '../../../ui/layout'
import { Popover2 } from '../../../ui/popover'
import WithCommentContent from '../with-comment-content'
import Comment from './comment'
import * as Status from './status'
import { translate } from './translate'
import { updateSimulationInCache } from './use-refresh-simulation'
import { useWorkflowOverview } from './utils'

export const DocumentQueryContext = React.createContext({ query: null })

export default function WorkflowTracker ({
  simulation,
  hideComments,
  ...props
}) {
  const [workflowOverview, updateWorkflowOverview] =
    useWorkflowOverview(simulation)
  return (
    <div
      className='workflow-tracker-box bg-white shadow-md'
      data-testid='workflow-tracker'
    >
      <h3 className='flex flex-wrap items-center justify-between bg-[#efefed] px-8 py-3 text-base font-bold text-[#636463] dark:bg-light-gray-300 dark:text-black'>
        <div>
          {workflowOverview
            ? i18n._({
                id: 'pagesrunner.workflow.status',
                message: 'Workflow Status'
              })
            : i18n._({
                id: 'pagesrunner.workflow.history.unavailable',
                message: 'Workflow History Unavailable'
              })}
        </div>
      </h3>
      {workflowOverview && (
        <div className='px-8 py-5'>
          {getWorkflowSteps(workflowOverview, {
            hideComments,
            updateWorkflowOverview,
            ...props
          })}
        </div>
      )}
    </div>
  )
}

export function getWorkflowSteps (
  workflowOverview,
  {
    appId,
    expandOrCollapseAll,
    showFutureSteps = true,
    updateWorkflowOverview,
    ...props
  }
) {
  const steps =
    workflowOverview.stepsWithAllVersions || workflowOverview.steps || []
  const filteredSteps = filter(
    reverse([...steps]),
    step => !shouldHideStep(step, showFutureSteps)
  )
  return (
    <ul className='my-4'>
      {map(filteredSteps, (step, i) => (
        <StepOverview
          allSteps={steps}
          applicationId={appId}
          expandOrCollapseAll={expandOrCollapseAll}
          key={step?.id || `${step?.definitionId}-${i}`}
          step={step}
          updateOverview={updateWorkflowOverview}
          {...props}
        />
      ))}
    </ul>
  )
}

function shouldHideStep (step, showFutureSteps) {
  return (
    step.users?.find(u => u.id === 'repair-util') ||
    (!showFutureSteps && step.simulated)
  )
}

function ErrorMsg ({ children }) {
  return <div className='my-3 flex items-center text-red-400'>{children}</div>
}

function ToolTipContent ({ children }) {
  return <div className='w-40'>{children}</div>
}

/* New UI */
const StepOverview = ({
  step,
  allSteps,
  startExpanded,
  expandOrCollapseAll,
  ...props
}) => {
  const isInProgress = !step.completedAt && !step.simulated
  const isComplete = step.completedAt
  const [showDetails, setShowDetails] = React.useState(
    !isComplete || startExpanded
  )
  React.useEffect(() => {
    if (expandOrCollapseAll === 'expand') {
      setShowDetails(true)
    } else if (expandOrCollapseAll === 'collapse') {
      setShowDetails(false)
    }
  }, [expandOrCollapseAll])
  const hasError = get(step, 'error', false)
  const details = StepDetails({ step, allSteps, hasError, ...props })
  const bottomBorder = isInProgress
    ? 'border-b-[6px] border-orange-300'
    : 'border-b border-light-gray-300'

  return (
    <li
      ref={element => {
        if (isInProgress) {
          setTimeout(() => {
            // scrollIntoView is undefined in our test environment
            element?.scrollIntoView?.({ behavior: 'smooth', block: 'center' })
          }, 100)
        }
      }}
      className={cx('p-4', bottomBorder, backgroundColor(step))}
      id={step?.id}
    >
      <div className='flex items-center justify-between'>
        <StepIcon step={step} />
        <div>
          <SuperTitle step={step} />
          <StepTitle step={step} />
          <SubTitle step={step} />
        </div>
        <div className='flex-grow' />
        {details && (
          <div className='print:hidden'>
            <button
              className='kp-button-transparent kp-button-icon kp-button-lg m-0 h-4 p-0 text-medium-gray-500 dark:text-medium-gray-300'
              onClick={() => setShowDetails(!showDetails)}
              aria-label={
                showDetails
                  ? i18n._({
                      id: 'workflow.less.details',
                      message: 'Show less details'
                    })
                  : i18n._({
                      id: 'workflow.more.details',
                      message: 'Show more details'
                    })
              }
            >
              {showDetails ? (
                <Icons.SelectDownArrow />
              ) : (
                <Icons.SelectUpArrow />
              )}
            </button>
          </div>
        )}
      </div>
      {showDetails && details}
      {isInProgress && (
        <div className='relative left-4 top-7 w-fit rounded-sm bg-orange-400 px-2 py-[2px] text-[9px] font-medium uppercase text-dark-gray-500'>
          <Trans
            id='pagesrunner.action.workflow.you.are.here'
            message='You are here'
          />
        </div>
      )}
    </li>
  )
}

const BACKGROUND_COLORS = {
  'Workflow Complete': 'bg-green-50',
  'Workflow Denied': 'bg-red-50',
  'Workflow Rejected': 'bg-red-50',
  'Workflow Withdrawn': 'bg-light-gray-100'
}

const backgroundColor = step => {
  return step?.type === 'workflowComplete'
    ? BACKGROUND_COLORS[step?.status]
    : ''
}
const STATUS_ICONS = {
  Cancelled: {
    icon: <Icons.WorkflowSkipped className='fill-medium-gray-500' />,
    bgColor: 'bg-light-gray-300'
  },
  Error: {
    icon: <Icons.AlertError className='fill-red-500' />,
    bgColor: 'bg-red-100'
  },
  Reassigned: {
    icon: <Icons.WorkflowReassigned className='fill-orange-500' />,
    bgColor: 'bg-orange-100'
  },
  Rejected: {
    icon: <Icons.WorkflowX className='fill-red-500' />,
    bgColor: 'bg-red-100'
  },
  Retried: {
    icon: <Icons.WorkflowRetry className='fill-orange-500' />,
    bgColor: 'bg-orange-100'
  },
  'Sent Back': {
    icon: <Icons.WorkflowBack className='fill-orange-500' />,
    bgColor: 'bg-orange-100'
  },
  Skipped: {
    icon: <Icons.WorkflowSkipped className='fill-orange-500' />,
    bgColor: 'bg-orange-100'
  },
  'Workflow Complete': {
    icon: <Icons.WorkflowComplete className='fill-green-500' />,
    bgColor: 'bg-green-100'
  },
  'Workflow Denied': {
    icon: <Icons.WorkflowComplete className='fill-red-500' />,
    bgColor: 'bg-red-100'
  },
  'Workflow Rejected': {
    icon: <Icons.WorkflowComplete className='fill-red-500' />,
    bgColor: 'bg-red-100'
  },
  'Workflow Withdrawn': {
    icon: <Icons.WorkflowComplete className='fill-medium-gray-500' />,
    bgColor: 'bg-light-gray-200'
  }
}

const StepIcon = ({ step }) => {
  const defaultFill = step.completedAt
    ? 'fill-green-500'
    : 'fill-medium-gray-500'
  const statusIcon = STATUS_ICONS[step?.status]
  let icon, bgColor
  if (statusIcon) {
    icon = statusIcon.icon
    bgColor = statusIcon.bgColor
  } else {
    if (step.completedAt) {
      bgColor = 'bg-green-100'
    } else {
      bgColor = 'bg-light-gray-300'
    }
    switch (step?.type) {
      case 'formfill':
        icon = <Icons.Clipboard className={defaultFill} />
        break
      case 'acknowledge':
        icon = <Icons.Like className={defaultFill} />
        break
      case 'approval':
        icon = <Icons.WorkflowCheck className={defaultFill} />
        break
      case 'task':
        icon = <Icons.Point className={defaultFill} />
        break
      case 'notification':
        icon = <Icons.Email className={defaultFill} />
        break
      case 'integration':
        icon = <Icons.Actions className={defaultFill} />
        break
      default:
        icon = null
    }
  }
  return (
    <span
      className={cx(
        'mr-4 flex h-8 w-8 items-center justify-center rounded-full',
        bgColor
      )}
    >
      {icon}
    </span>
  )
}
const SuperTitle = ({ step }) => {
  const dateString = step.completedAt ? (
    formatDate(step.completedAt)
  ) : step.simulated ? (
    <Trans id='future' message='Future' />
  ) : (
    <Trans id='in.progress' message='In Progress' />
  )
  return (
    <div className='text-sm text-medium-gray-500'>
      {dateString}
      {step.versionNumber ? ` - V${step.versionNumber}` : ''}
    </div>
  )
}
const StepTitle = ({ step }) => {
  let color
  let title = step?.msg
  switch (step?.status) {
    case 'Error':
      color = 'text-red-400'
      break
    case 'Retried':
      color = 'text-medium-gray-500'
      title = 'Retried'
      break
    default:
      color = 'text-medium-gray-500'
  }
  return (
    <div className={cx('text-base font-medium', color)}>
      {translateTitle(title, step?.type)}
    </div>
  )
}
const translateTitle = (title, stepType) => {
  if (title === 'Completed' && stepType === 'integration') {
    return translate('Integration Completed')
  }

  const translatedTitle = translate(title)
  if (translatedTitle) {
    return translatedTitle
  }

  const tokens = title.split(' ')
  if (tokens.length === 2) {
    if (tokens[1] === 'Cancelled') {
      return i18n._(
        {
          id: 'pagesrunner.workflow.step.cancelled',
          message: '{type} Cancelled'
        },
        {
          type: tokens[0]
        }
      )
    } else if (tokens[1] === 'Error') {
      return i18n._(
        { id: 'pagesrunner.workflow.step.error', message: '{type} Error' },
        {
          type: tokens[0]
        }
      )
    }
  } else if (tokens.length > 2) {
    if (tokens[0] === 'Waiting' && tokens[1] === 'for') {
      return i18n._(
        {
          id: 'pagesrunner.workflow.waiting.for.action',
          message: 'Pending {action}'
        },
        {
          action: tokens.slice(2).join(' ')
        }
      )
    }
  }
  return title
}
const SubTitle = ({ step }) => {
  const subtitle = step?.name || step?.stepName
  return !subtitle || step?.type === 'formfill' ? null : (
    <div className='text-xs text-medium-gray-500'>({subtitle})</div>
  )
}

const StepDetails = ({ step, allSteps, ...props }) => {
  const {
    applicationId,
    documentId,
    hasError,
    hasWFTroubleshootPerms,
    hideComments,
    updateOverview,
    workflowResends
  } = props
  if (step.type === 'workflowComplete') return
  const users = get(step, 'group.users', step?.users) ?? []
  const actions = UserActions({
    futureStep: step.simulated,
    group: step?.group,
    hasError,
    hideComments,
    stepType: step?.type,
    users
  })
  const signatures = get(step, 'signatures', [])
  const signatureInfo = SignatureInfo({ signatures })
  const duplicateActions = get(step, 'duplicateActions', [])
  const showTroubleshootButtons =
    hasWFTroubleshootPerms && step.type !== 'formfill'
  const troubleshootButtons = showTroubleshootButtons && (
    <TroubleshootButtons
      applicationId={applicationId}
      documentId={documentId}
      step={step}
      updateOverview={updateOverview}
      workflowResends={workflowResends}
      {...props}
    />
  )
  const errorInfo = hasError ? (
    <div className='mb-4 text-sm font-normal text-medium-gray-500'>
      {step.error}
    </div>
  ) : null

  const duplicateInfo = duplicateActions.length ? (
    <DuplicateInfo duplicateActions={duplicateActions} />
  ) : null

  return errorInfo ||
    signatureInfo ||
    duplicateInfo ||
    actions ||
    troubleshootButtons ? (
    <div className='ml-12 mt-4'>
      {errorInfo}
      {signatureInfo}
      {duplicateInfo}
      {actions}
      {troubleshootButtons}
    </div>
  ) : null
}

const UserActions = ({ futureStep, group, hideComments, stepType, users }) => {
  if (!users.length) {
    return null
  }
  return (
    <>
      {group?.name && stepType !== 'formfill' && (
        <div className='mb-2 mt-2 text-xs font-bold'>
          {group.name} - {group.role}
        </div>
      )}
      <ul
        aria-label={i18n._({
          id: 'pagesrunner.action.workflow.tracker.user.actions',
          message: 'User actions'
        })}
        className='mb-4'
      >
        {users.map((user, i) => {
          const comment = get(user, 'workflowAction.comment')
          const date = formatDate(get(user, 'workflowAction.date'))
          const action = get(user, 'workflowAction.action')
          return (
            <li className='my-4' key={`${get(user, 'id')}-${i}`}>
              <>
                <div className='flex items-center font-medium text-medium-gray-500'>
                  <Status.Icon status={action} />
                  <div>
                    {!futureStep && (
                      <div className='text-xs'>
                        <ActionDetails
                          action={action}
                          date={date}
                          stepType={stepType}
                        />
                      </div>
                    )}
                    <div className='text-sm'>
                      {user?.displayName || user?.email}
                    </div>
                  </div>
                </div>
                {comment && !hideComments && (
                  <Comment className='mb-2 text-sm'>{comment}</Comment>
                )}
              </>
            </li>
          )
        })}
      </ul>
    </>
  )
}
const SignatureInfo = ({ signatures }) => {
  if (signatures.length) {
    return (
      <ul className='mb-2'>
        {signatures.map((signature, i) => {
          const date = formatDate(signature.date)
          return (
            <li
              className='flex items-center font-medium text-medium-gray-500'
              key={`signature-${i}`}
            >
              <Icons.PenDraw className='mr-[7px] mt-1 fill-blue-500' />
              <div>
                <div className='text-xs'>
                  <Trans
                    id='pagesrunner.action.workflow.actions.signed'
                    message='Signed'
                  />
                  {' - '}
                  {date}
                </div>
                <div className='text-sm'>{signature.displayName}</div>
              </div>
            </li>
          )
        })}
      </ul>
    )
  } else {
    return null
  }
}

const ActionDetails = ({ action, date, stepType }) => {
  if (!action) return null
  switch (action) {
    case 'pending':
      return translate(stepType) || translate('Pending')
    case 'cancelled':
      return null
    default:
      return `${getActionWord(action)} - ${date}`
  }
}

const getActionWord = (action, to) => {
  if (lowerCase(action) === 'reassigned') {
    return i18n._(
      {
        id: 'pagesrunner.action.workflow.actions.reassigned',
        message: 'Reassigned to {to}'
      },
      { to }
    )
  } else {
    return translate(action) || action
  }
}

function formatDate (rawDate) {
  if (!rawDate) return null
  const date = new Intl.DateTimeFormat(window.navigator.language, {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  }).format(+new Date(rawDate))
  const time = new Intl.DateTimeFormat(window.navigator.language, {
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: 'h12',
    timeZoneName: 'short'
  })
    .format(+new Date(rawDate))
    .replace(' AM', 'am')
    .replace(' PM', 'pm')
  return `${date} - ${time}`
}

function updateResendsInCache (cache, query, resends) {
  if (!query || !resends) return
  const rawData = cache.readQuery(query)
  const documentResults = cloneDeep(rawData)
  const cacheKey = documentResults?.action
    ? 'action.document.meta.workflowResends'
    : 'document.meta.workflowResends'
  set(documentResults, cacheKey, resends)
  cache.writeQuery({ ...query, data: documentResults })
}

const retryMutation = gql`
  mutation RetryStep($args: RetryWorkflowStepsInput!) {
    retryWorkflowStep(args: $args)
  }
`

const useRetryStep = () => {
  const { query } = React.useContext(DocumentQueryContext)
  const [retryStep, { error }] = useMutation(retryMutation, {
    update (cache, { data }) {
      updateSimulationInCache(cache, query, data.retryWorkflowStep)
    }
  })
  const retry = async ({ appId, documentId, id }, updateOverview) => {
    const variables = { args: { appId, documentId, stepId: id } }
    const resp = await retryStep({ variables })
    updateOverview({ simulation: resp.data.retryWorkflowStep })
  }
  return [retry, error]
}

const skipMutation = gql`
  mutation SkipStep($args: SkipWorkflowStepsInput!) {
    skipWorkflowStep(args: $args)
  }
`

const useSkipStep = () => {
  const { query } = React.useContext(DocumentQueryContext)
  const [skipStep, { error }] = useMutation(skipMutation, {
    update (cache, { data }) {
      updateSimulationInCache(cache, query, data.skipWorkflowStep)
    },
    refetchQueries: query?.query?.definitions[0]?.name?.value
      ? [query?.query?.definitions[0]?.name?.value]
      : []
  })

  const skip = async (
    { appId, documentId, id: stepId, firstActionId, actionId },
    comment,
    updateOverview
  ) => {
    const variables = {
      args: { appId, documentId, stepId, variables: { comment } }
    }
    const resp = await skipStep({ variables })
    updateOverview({ simulation: resp.data.skipWorkflowStep })
  }

  return [skip, error]
}

const reassignMutation = gql`
  mutation ReassignStep($args: ReassignWorkflowStepsInput!) {
    reassignWorkflowStep(args: $args)
  }
`

const useReassignStep = () => {
  const { query } = React.useContext(DocumentQueryContext)
  const [reassignStep, { error }] = useMutation(reassignMutation, {
    update (cache, { data }) {
      updateSimulationInCache(cache, query, data.reassignWorkflowStep)
    }
  })

  const reassign = async (
    { appId, documentId, id: stepId },
    comment,
    user,
    updateOverview
  ) => {
    const variables = {
      args: { appId, documentId, stepId, variables: { comment, user } }
    }
    const resp = await reassignStep({ variables })
    updateOverview({ simulation: resp.data.reassignWorkflowStep })
  }

  return [reassign, error]
}

const resendMutation = gql`
  mutation ResendApprovalNotification(
    $args: ResendApprovalNotificationsInput!
  ) {
    resendApprovalNotifications(args: $args)
  }
`
const useResendApprovalNotification = () => {
  const { query } = React.useContext(DocumentQueryContext)
  const [resend, { error }] = useMutation(resendMutation, {
    update (cache, { data }) {
      updateResendsInCache(cache, query, data.resendApprovalNotifications)
    }
  })
  const resendApproval = async (
    { appId, documentId, id: stepId },
    updateOverview
  ) => {
    await resend({
      variables: { args: { appId, documentId, stepId } }
    })
  }
  return [resendApproval, error]
}

const minutesSinceExecution = executionTimeString => {
  const diff = new Date() - new Date(executionTimeString)
  return diff / 1000 / 60 // Milliseconds to Seconds
}

const TroubleshootButtons = ({
  applicationId,
  documentId,
  hasError = false,
  step,
  updateOverview,
  workflowResends,
  dataSetId,
  isTable,
  firstPageId,
  firstActionId,
  actionId,
  ...props
}) => {
  const alerts = useAlerts()
  const [processing, setProcessing] = React.useState(false)
  const [retryStep, retryStepError] = useRetryStep()
  const [skipStep, skipStepError] = useSkipStep()
  const [reassignStep, reassignStepError] = useReassignStep()
  const [resendApproval, resendApprovalError] = useResendApprovalNotification()
  const inProgress =
    !step.simulated &&
    !step.completedAt &&
    (step.type !== 'acknowledge' || hasError)
  const isIntegration = step.type === 'integration'
  const canRetryNotification =
    step.type === 'notification' &&
    (step.status === 'Generating PDF' ||
      minutesSinceExecution(step.startedAt) > 9)
  const canResendApprovalEmailOrReassign =
    // form-approval is old workflow engine
    (step.type === 'approval' ||
      step.type === 'form-approval' ||
      step.type === 'task') &&
    inProgress
  const timeSinceLastResend = calculateLastResentTime(step, workflowResends)
  const tableUrl = `/app/${applicationId}/page/${firstPageId}/workflow/general-data/${dataSetId}/workflow`
  const productUrl = `/app/${applicationId}/page/${dataSetId}/workflow`
  const appUrl = `/workflow/${applicationId}`
  const trueUrl = isTable ? tableUrl : dataSetId ? productUrl : appUrl
  return (
    inProgress && (
      <div className='troubleshoot-buttons mb-2 mt-2 flex flex-wrap align-middle'>
        {(hasError || isIntegration || canRetryNotification) && (
          <TooltipTrigger
            as={Button}
            small
            mb={2}
            mr={2}
            data-testid='step-retry'
            tooltipId='retry-tooltip'
            disabled={processing}
            onClick={async () => {
              if (!processing) {
                setProcessing('retry')
                try {
                  await retryStep(
                    { ...step, appId: applicationId, documentId },
                    updateOverview
                  )
                } catch (error) {
                  alerts.type3(error, 'error')
                } finally {
                  setProcessing(false)
                }
              }
            }}
          >
            {processing === 'retry' ? (
              <Spinner size={20} />
            ) : (
              i18n._({ id: 'retry', message: 'Retry' })
            )}
          </TooltipTrigger>
        )}
        <Tooltip id='retry-tooltip' place='bottom'>
          <ToolTipContent>
            <Trans
              id='pagesrunner.workflow.restart.step'
              message='Restart this step and attempt to execute it again'
            />
          </ToolTipContent>
        </Tooltip>
        <Popover2
          role='dialog'
          trigger={
            <TooltipTrigger
              as={Button}
              small
              mb={2}
              mr={2}
              data-testid='step-skip'
              tooltipId='skip-tooltip'
              disabled={processing}
            >
              <Trans id='pagesrunner.workflow.skip' message='Skip' />
            </TooltipTrigger>
          }
        >
          {hide => (
            <WithCommentContent
              required
              label={i18n._({
                id: 'pagesrunner.workflow.skip',
                message: 'Skip'
              })}
              submitting={processing}
              onCancel={hide}
              onClick={async ({ comment }) => {
                if (!processing) {
                  setProcessing('skip')
                  try {
                    await skipStep(
                      {
                        ...step,
                        appId: applicationId,
                        documentId,
                        firstActionId,
                        actionId
                      },
                      comment,
                      updateOverview
                    )
                  } catch (error) {
                    alerts.type3(error.message, 'error')
                  } finally {
                    setProcessing(false)
                  }
                }
              }}
            />
          )}
        </Popover2>
        <Tooltip id='skip-tooltip' place='bottom'>
          <ToolTipContent>
            <Trans
              id='pagesrunner.workflow.skip.step'
              message='Skip this step and move to the next step'
            />
          </ToolTipContent>
        </Tooltip>
        {canResendApprovalEmailOrReassign && (
          <Popover2
            role='dialog'
            trigger={
              <TooltipTrigger
                as={Button}
                small
                mr={2}
                data-testid='step-reassign'
                tooltipId='reassign-tooltip'
                disabled={processing}
              >
                <Trans id='pagesrunner.workflow.reassign' message='Reassign' />
              </TooltipTrigger>
            }
          >
            {hide => (
              <WithCommentContent
                required={['user', 'comment']}
                label={i18n._({
                  id: 'pagesrunner.workflow.reassign',
                  message: 'Reassign'
                })}
                submitting={processing}
                onCancel={hide}
                onClick={async ({ comment, user }) => {
                  if (!processing) {
                    setProcessing('reassign')
                    try {
                      await reassignStep(
                        {
                          ...step,
                          appId: applicationId,
                          documentId
                        },
                        comment,
                        user,
                        updateOverview
                      )
                    } catch (error) {
                      alerts.type3(error, 'error')
                    } finally {
                      setProcessing(false)
                    }
                  }
                }}
              >
                {(data, setData) => (
                  <>
                    <label
                      className='block text-sm text-medium-gray-500 after:ml-1 after:text-red-400 after:content-["*"]'
                      id='user-label'
                      htmlFor='user-picker'
                      required
                    >
                      <Trans
                        id='pagesrunner.workflow.reassign.step'
                        message='Reassign this step to which person?'
                      />
                    </label>
                    <UserTypeahead
                      id='user-picker'
                      aria-labelledby='user-label'
                      value={data.user}
                      onChange={newUser =>
                        setData({ ...data, user: newUser?.user })
                      }
                    />
                    <Space h='16px' />
                  </>
                )}
              </WithCommentContent>
            )}
          </Popover2>
        )}
        <Tooltip id='reassign-tooltip' place='bottom'>
          <ToolTipContent>
            {' '}
            <Trans
              id='pagesrunner.workflow.reassign.another'
              message='Reassign this step to another person'
            />
          </ToolTipContent>
        </Tooltip>
        {canResendApprovalEmailOrReassign && (
          <TooltipTrigger
            as={Button}
            small
            mr={2}
            tooltipId='resend-tooltip'
            disabled={processing}
            onClick={async () => {
              if (!processing) {
                setProcessing('resend')
                try {
                  await new Promise(resolve => setTimeout(resolve, 1000))
                  await resendApproval(
                    { ...step, appId: applicationId, documentId },
                    updateOverview
                  )
                  alerts.type3(
                    i18n._({
                      id: 'pagesrunner.action.message.sent',
                      message: 'Message Sent'
                    }),
                    'success'
                  )
                } catch (error) {
                  alerts.type3(error.message, 'error')
                } finally {
                  setProcessing(false)
                }
              }
            }}
          >
            {processing === 'resend' ? (
              <Spinner size={20} />
            ) : (
              `${i18n._({ id: 'pagesrunner.action.resend', message: 'Resend Notification' })}`
            )}
          </TooltipTrigger>
        )}
        <Tooltip id='resend-tooltip' place='bottom'>
          <ToolTipContent>
            {timeSinceLastResend !== null ? (
              <Trans
                id='pagesrunner.action.last.resent'
                message='This notification was last resent {time} ago'
                values={{ time: timeSinceLastResend }}
              />
            ) : (
              `${i18n._({ id: 'pagesrunner.action.send.additional', message: 'Send an additional notification to people who have not yet responded at this step' })}`
            )}
          </ToolTipContent>
        </Tooltip>
        <TooltipTrigger
          as={Button}
          disabled={processing}
          small
          tooltipId='view-tooltip'
          forwardedProps={{ as: 'a' }}
          href={`${window.location.origin}${trueUrl}?showStep=${step.stepDefinitionId}`}
          rel='noopener noreferrer'
          target='_blank'
          mr={2}
        >
          <Trans id='pagesrunner.workflow.view.step' message='View Step' />
        </TooltipTrigger>
        <Tooltip id='view-tooltip' place='bottom'>
          <ToolTipContent>
            <Trans
              id='pagesrunner.workflow.view.step.configuration'
              message='View step configuration in the workflow builder'
            />
          </ToolTipContent>
        </Tooltip>
        {retryStepError && <ErrorMsg>{retryStepError.message}</ErrorMsg>}
        {skipStepError && <ErrorMsg>{skipStepError.message}</ErrorMsg>}
        {reassignStepError && <ErrorMsg>{reassignStepError.message}</ErrorMsg>}
        {resendApprovalError && (
          <ErrorMsg>{resendApprovalError.message}</ErrorMsg>
        )}
      </div>
    )
  )
}

const calculateLastResentTime = (step, workflowResends = []) => {
  const lastResend = findLast(workflowResends, wr => wr.stepId === step.id)
  if (lastResend) {
    return formatDistanceToNow(new Date(lastResend.date))
  }
  return null
}

function DuplicateInfo ({ duplicateActions }) {
  if (duplicateActions.length) {
    return (
      <div className='pb-4 pt-4'>
        <div className='text-sm'>
          <span className='font-bold'>
            <Trans id='pagesrunner.workflow.note' message='NOTE: ' />
          </span>
          <span>
            <Trans
              id='pagesrunner.workflow.attempted.actions'
              message='The following actions were attempted but ignored because they were taken after the step had been completed:'
            />
          </span>
        </div>
        <ul className='list-inside list-disc'>
          {duplicateActions.map((da, index) => {
            const date = da.date
              ? new Intl.DateTimeFormat(window.navigator.language, {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric'
                }).format(+new Date(da.date))
              : ''
            let description
            if (da.callbackId === 'integration') {
              description = `${i18n._({
                id: 'pagesrunner.workflow.response.integration',
                message: 'Processing a response from this integration'
              })}`
            } else if (da.callbackId === 'pdf') {
              description = `${i18n._({
                id: 'pagesrunner.workflow.generation.service',
                message: 'Processing a response from the PDF generation service'
              })}`
            } else {
              description = `${i18n._(
                {
                  id: 'pagesrunner.workflow.handling.attempt',
                  message: 'Handling a {action} attempt from {user}'
                },
                {
                  action: da.action,
                  user: da.user
                }
              )}`
            }
            return (
              <li key={index} className='text-sm'>
                {description}{' '}
                <Trans
                  id='pagesrunner.workflow.on'
                  message='on'
                  comment='this is for the pretranslated description on a date. format is: description on date'
                />{' '}
                {date}.
              </li>
            )
          })}
        </ul>
      </div>
    )
  } else {
    return null
  }
}
