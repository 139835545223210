/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import React from 'react'

import ConditionalVisibility from '../../../pages-builder/form/config-conditional-visibility'

export function RequiredConfig ({ Gadgets, conditionals }) {
  return (
    <Gadgets.Custom
      configKey='conditionalVisibility.value'
      defaultValue={{}}
      label={i18n._({
        message: 'Rules for making this visible',
        id: 'formbot.gadgets.validation.config.conditionalVisibility'
      })}
    >
      {({ onChange, value }) => (
        <div className='pt-4'>
          <ConditionalVisibility
            gadgets={conditionals}
            onChange={onChange}
            value={value}
            context={{}}
          />
        </div>
      )}
    </Gadgets.Custom>
  )
}
