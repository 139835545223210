/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import React from 'react'

import { autoUpdatingFilters } from '../../../components/feature-flags'
import Tooltip, { TooltipTrigger } from '../../../components/tooltip'
import * as Icons from '../../../icons'

const BASE_OPTIONS = [
  {
    id: 'disabled',
    label: i18n._({ id: 'never', message: 'Never' }),
    value: { enabled: false }
  },
  {
    id: 'enabled',
    label: i18n._({
      id: 'document.updating.saved',
      message: 'When the linked document is saved'
    }),
    helpText: i18n._({
      id: 'document.updating.saved.help',
      message:
        'This includes when a document is edited then saved on the specific version that is linked (whether as a draft, submitted to workflow, or completed). This does not update upon creation or saving of a new version of the linked document.'
    }),
    value: { enabled: true }
  }
]

const VERSION_OPTIONS = [
  {
    id: 'submitted',
    label: i18n._({
      id: 'document.updating.submitted',
      message: 'When a new version of the linked document is submitted'
    }),
    value: { enabled: true, updateOn: 'submitted' }
  },
  {
    id: 'completed',
    label: i18n._({
      id: 'document.updating.completed',
      message: 'When a new version of the linked document is completed'
    }),
    value: { enabled: true, updateOn: 'completed' }
  }
]

const FILTER_OPTION = {
  id: 'filter',
  label: i18n._({
    id: 'document.updating.filter',
    message:
      'When a new version of the linked document meets filtering criteria'
  }),
  value: { enabled: true, updateOn: 'filter' }
}

function getCheckedId (value, hasVersions) {
  if (!value?.enabled) return 'disabled'
  if (!hasVersions) return 'enabled'
  if (['submitted', 'completed', 'filter'].includes(value.updateOn)) {
    return value.updateOn
  }
  return 'enabled'
}

export default function AutoUpdateConfig ({
  id,
  Gadgets,
  className,
  hasVersions
}) {
  const configKey = 'autoUpdate'
  const options = hasVersions
    ? [
        ...BASE_OPTIONS,
        ...VERSION_OPTIONS,
        autoUpdatingFilters && FILTER_OPTION
      ]
    : BASE_OPTIONS

  return (
    <Gadgets.Custom
      configKey={configKey}
      group
      className={cx('space-y-1', className)}
    >
      {({ onChange, value }) => {
        const checkedId = getCheckedId(value, hasVersions)
        return (
          <>
            <legend>
              <Trans
                id='document.updating.when'
                message='When should the primary document update?'
              />
            </legend>
            {options.filter(Boolean).map(option => {
              const optionId = `${id}-${configKey}-${option.id}`
              return (
                <div key={option.id} className='flex items-center gap-2'>
                  <label>
                    <input
                      id={optionId}
                      type='radio'
                      className='kp-radio'
                      name={configKey}
                      value={option.id}
                      checked={option.id === checkedId}
                      onChange={() => onChange(option.value)}
                      aria-describedby={
                        option.helpText ? `${optionId}-help` : undefined
                      }
                    />
                    <span>{option.label}</span>
                  </label>
                  {option.helpText && (
                    <>
                      <Tooltip
                        id={`${optionId}-help`}
                        place='right'
                        className='w-52'
                      >
                        {option.helpText}
                      </Tooltip>
                      <TooltipTrigger
                        as={Icons.AlertHelp}
                        tooltipId={`${optionId}-help`}
                        label={option.label}
                      />
                    </>
                  )}
                </div>
              )
            })}
          </>
        )
      }}
    </Gadgets.Custom>
  )
}
