/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import { filter, find, map, partition } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import Button from '../../../ui/button'
import { Flex, Space } from '../../../ui/layout'
import McPicker from './mc-picker'

export function RequiredConfig ({ Gadgets, ...rest }) {
  return (
    <div>
      <Gadgets.Label>
        <Trans id='select.gadget.colon' />
      </Gadgets.Label>
      <Chooser {...rest} />
    </div>
  )
}

export const sources = [
  { id: 'data.', label: 'Data on the form' },
  { id: 'meta.', label: 'System metadata' },
  { id: 'integration.', label: 'Data from workflow integrations' }
]

function Chooser ({ allGadgets, value, onChange, ...rest }) {
  const [showChooser, setShowChooser] = React.useState(false)
  let gadgets = filter(
    allGadgets,
    g =>
      // TODO: Add support for some of these gadgets
      ![
        'Credits',
        'DataLink',
        'FileUpload',
        'ImageUpload',
        'ReadOnlyData',
        'Repeater',
        'Table',
        'Signature',
        'Spacer',
        'Validation'
      ].includes(g.type)
  )
  gadgets = map(gadgets, g => ({ ...g, icon: getIcon(rest.gadgets, g.type) }))
  const [normal, expanded] = partition(gadgets, gadget => !gadget.expanded)
  const selected = value.selectedGadget
    ? find(gadgets, { id: value.selectedGadget.id }) || value.selectedGadget
    : null
  return (
    <>
      <Flex justifyContent='space-between'>
        {selected ? (
          <>
            <Flex>
              {getIcon(rest.gadgets, selected.type)}
              <Space w='8px' />
              <span>{selected.label}</span>
              <Space w='16px' />
            </Flex>
            <Space expand />
            <Button outline onClick={() => setShowChooser(a => !a)}>
              <Trans id='change' />
            </Button>
          </>
        ) : (
          <Button onClick={() => setShowChooser(a => !a)}>
            <Trans id='choose' />
          </Button>
        )}
      </Flex>
      {showChooser && (
        <McPicker
          hide={() => setShowChooser(false)}
          title='Gadgets'
          sources={[
            () => sources,
            id => filter(normal, g => g.formKey.startsWith(id)),
            id => {
              const gadget = find(normal, { id })
              if (!gadget) return []
              return filter(expanded, g => g.formKey.startsWith(gadget.formKey))
            }
          ]}
          onChange={id => {
            setShowChooser(false)
            const { type, label, formKey, details } = find(gadgets, { id })
            onChange({ selectedGadget: { id, type, label, formKey, details } })
          }}
          isValid={ids => ids.length >= 2}
        />
      )}
    </>
  )
}

function getIcon (gadgets, type) {
  const Icon = gadgets?.[type]?.meta?.Icon
  if (!Icon) return null
  return <MyIcon as={Icon} />
}

const MyIcon = styled.i`
  flex-shrink: 0;
  fill: #666;
  width: 16px;
  height: 16px;
`
