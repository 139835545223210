/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import cx from 'clsx'
import { find, get, isEqual, isNil, last, omit, pick, split } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import ErrorBoundary from '../../../components/error-boundary'
import { Tooltip, TooltipTrigger } from '../../../components/new-tooltip'
import sanitize from '../../../components/sanitize'
import { AlertHelp } from '../../../icons'
import * as assemblers from './assemblers'
import v from './validate'

export default function renderGadget (Formbot, mode, template, options) {
  const {
    progDisc,
    context,
    children,
    shouldShow,
    onChange,
    value,
    noGrid,
    highlight,
    readOnly,
    hidden,
    warning
  } = options
  if (hidden) return null
  const _mode = template?.details?.calculation?.enabled
    ? 'Edit'
    : readOnly
      ? 'View'
      : mode
  const { id, type, formKey } = template
  const gadgetDef = Formbot.getGadget(type)
  if (!shouldShow || (!formKey && !gadgetDef.layout)) return null
  const label = template.label && (
    <LabelInner
      className={cx('text-blue-500', {
        'invalid-field': template.type === 'Validation'
      })}
      labelSize={getLabelSize(context?.labelSize, template.type)}
      labelWeight={getLabelWeight(template.type)}
    >
      {template.label}
    </LabelInner>
  )
  const previousValue = get(context, ['previousDocument', 'data', formKey])
  const desc =
    template.description?.enabled &&
    (template.description.displayAsPopover ? (
      <TooltipTrigger
        id={`gadget-desc-${id}`}
        className='relative inline-block'
      >
        <AlertHelp
          className='fill-blue-500 dark:fill-medium-gray-300'
          label={i18n._('field.information')}
          ml={1}
          role='img'
          alt={i18n._('field.information')}
        />
        <Tooltip
          id={`gadget-desc-${id}`}
          position='bottom'
          className='min-w-max'
        >
          <DescTooltip
            helpSize={getHelpSize(context?.labelSize)}
            className='ql-editor ql-snow'
            dangerouslySetInnerHTML={{
              __html: sanitize(template.description.value || '')
            }}
          />
        </Tooltip>
      </TooltipTrigger>
    ) : (
      <Desc
        id={`${id}-desc`}
        helpSize={getHelpSize(context?.labelSize)}
        className='ql-editor ql-snow'
        dangerouslySetInnerHTML={{
          __html: sanitize(template.description.value || '')
        }}
      />
    ))
  const props = {
    a11yDesc: template.label,
    required: template.required || false, // this belongs in the validation decorator
    ...(label && { 'aria-labelledby': `${id}-label` }),
    ...(desc && { 'aria-describedby': `${id}-desc` }),
    children,
    childrenTemplate: template.childrenTemplate,
    progDisc,
    context,
    details: template.details || {},
    templateValidations: template.validations || {},
    formKey,
    id,
    fbValidate: (type, value, gadget) => {
      const validator = Formbot.getGadget(type).validateShape(v, gadget)
      const errors = validator(value)
      return !errors || errors.length === 0
    },
    fbRenderView: (type, value, details, childrenTemplate, formKey, ctx2) => {
      const Gadget = Formbot.getGadget(type).View
      const otherProps = pick(props, ['fbValidate', 'fbRenderView', 'progDisc'])
      const ctx = { ...omit(context, ['prev', 'next']) }
      return (
        <Gadget
          {...otherProps}
          formKey={formKey}
          value={value}
          details={details}
          childrenTemplate={childrenTemplate}
          context={{ ...ctx, ...(ctx2 || {}) }}
        />
      )
    },
    fbRenderEdit: (
      renderEditType,
      value,
      details,
      onChange,
      renderEditFormKey,
      formKey
    ) => {
      const defaultValue = Formbot.getGadget(renderEditType).defaultValue
      const warningObj = find(context?.warnings, w =>
        w.shouldShow(
          { type: renderEditType, formKey: renderEditFormKey },
          value
        )
      )
      const warning = warningObj ? warningObj.message : null
      return renderGadget(
        Formbot,
        'Edit',
        {
          type: renderEditType,
          formKey,
          details,
          id: `${id}.${renderEditFormKey}`,
          validations: details?.validations ?? {}
        },
        {
          context,
          warning,
          shouldShow: true,
          // anything that uses Lookup
          noRelative: [
            'IntegrationTypeahead',
            'IntegrationMultiselect',
            'UserTypeahead',
            'UserMultiselect',
            'GroupTypeahead',
            'GroupMultiselect',
            'FormTypeahead',
            'FormMultiselect',
            'CountryDropdown',
            'LanguagesDropdown',
            'StateDropdown',
            'Terms'
          ].includes(renderEditType),
          highlight,
          gridded: true,
          value: isNil(value) ? defaultValue : value,
          onChange,
          StaticFormbot: options.StaticFormbot
        }
      )
    },
    fbRenderConfig: (type, value, onChange, Gadgets, formKey) => {
      const Gadget = Formbot.getGadget(type).RequiredConfig
      if (!Gadget) return null
      return (
        <Gadget
          context={{}}
          id={formKey}
          value={value}
          onChange={onChange}
          Gadgets={Gadgets}
        />
      )
    },
    NOTFOUND_TYPE: type,
    StaticFormbot: options.StaticFormbot,
    onChange,
    value,
    compareVersions: context?.compareVersions
  }
  const Gadget = gadgetDef[_mode]
  // If progressive disclosure takes away children of a column or row, the
  // column/row would still render their flex div, leaving empty spaces in the
  // form. In regards to a "better way"™ there probably needs to be something
  // in the gadget manifest telling it to behave this way, or do something with
  // progressive disclosure and change the template before we pass it into
  // formbot
  if ((type === 'Column' || type === 'Row') && children.length === 0) {
    return null
  }
  const gridded = !noGrid
  const gadget = (
    <ErrorBoundary>
      <Gadget {...props} gridded={gridded} />
    </ErrorBoundary>
  )
  const previousGadget = context?.compareVersions &&
    !isEqual(value, previousValue) && (
      <Gadget {...props} gridded={gridded} value={previousValue} />
    )
  const decorators = Formbot.getDecorators({ type, mode: _mode })
  const templateWithGadgetId = withGadgetId(template)
  const decorate = parts =>
    Formbot.decorate(
      decorators,
      parts,
      { ...props, template: templateWithGadgetId },
      gadgetDef,
      (value, tmpl, context, embedded) => {
        value = isNil(value) ? gadgetDef.defaultValue : value
        return (
          <div
            className={cx('h-full w-full flex-1', {
              'border-l border-t border-light-gray-300 bg-white': !embedded
            })}
          >
            {renderGadget(Formbot, mode, tmpl, { ...options, value, context })}
          </div>
        )
      }
    )

  const parts = decorate({ label, desc, gadget })
  const getAssembler = gadgetDef.getAssembler || (({ Basic }) => Basic)
  const Assembler =
    gadgetDef.Assembler ||
    getAssembler(assemblers, gridded, props.details, context)
  const component = (
    <Assembler
      headless={props.details?.headless}
      noRelative={options.noRelative}
      isCanonicalGadget={
        !!context?.canonicalGadgetsMap?.[templateWithGadgetId.id]
      }
      key={template.id}
      label={parts.label}
      desc={parts.desc}
      config={Formbot.options.config}
      displayDescInline={template.description?.displayAsPopover}
      compareVersions={context?.compareVersions}
      previousGadget={previousGadget}
      gadget={parts.gadget}
      template={template}
      gridded={gridded}
      highlight={_mode === 'Edit' ? highlight : null}
      readOnly={readOnly}
      warning={warning}
    />
  )
  return decorate({ component }).component
}

// Repeaters render their children with ids in the form of
// [repeaterId].[i].[gadgetId] to ensure that labels match up correctly.
// But in order to make moving gadgets work, we need to provide just the gadget
// id itself to all the decorators so that they can match properly against
// the overall form template. But we add the `fullId` so that there is a unique
// id for creating the Voronoi diagram.
function withGadgetId (gadget) {
  const parts = split(gadget.id, '.')
  return parts.length >= 3
    ? { ...gadget, id: last(parts), fullId: gadget.id }
    : gadget
}

function getLabelSize (labelSize, type) {
  const size = labelSize ?? 'medium'

  if (type === 'Section') {
    if (size === 'huge') return 28
    if (size === 'extra-large') return 24
    if (size === 'large') return 22
    return 20
  } else {
    if (size === 'huge') return 24
    if (size === 'extra-large') return 20
    if (size === 'large') return 18
    return 16
  }
}

const getLabelWeight = type => (type === 'Section' ? 'bold' : 'normal')

function getHelpSize (labelSize) {
  const size = labelSize ?? 'medium'

  if (size === 'huge') return 20
  if (size === 'extra-large') return 18
  if (size === 'large') return 16
  return 14
}

const LabelInner = styled.span`
  font-weight: ${p => p.labelWeight};
  font-size: ${p => p.labelSize}px;
  word-break: break-word;
`

const Desc = styled.p`
  color: #666;
  html.dark & {
    // Outlier: dark:text-dark-gray-100
    color: #bbb;
  }
  font-size: ${p => p.helpSize}px;
  margin: 0;
  margin-top: 8px;
  padding: 0 !important;
  height: initial !important;
  min-height: initial !important;
  border: 0;
  width: 100%;
  & p {
    line-height: 1.42;
    color: #666;
    html.dark & {
      // Outlier: dark:text-dark-gray-100
      color: #bbb;
    }
    font-size: ${p => p.helpSize}px;
  }
`

const DescTooltip = styled.p`
  color: white;
  font-size: ${p => p.helpSize}px;
  margin-bottom: 0;
  max-width: 300px;
  white-space: normal;
  padding: 0 !important;
  height: initial;
  min-height: initial !important;
  border: 0;
  & > p {
    color: white;
  }
  & p {
    line-height: 1.42;
    color: #fff;
    font-size: ${p => p.helpSize}px;
  }
  & a {
    color: #9bcceb;
  }
`
