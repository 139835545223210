import { i18n } from '@lingui/core'
import { detect, fromNavigator, fromStorage } from '@lingui/detect-locale'
import { I18nProvider } from '@lingui/react'
import { enCA, enGB, enIE, enUS, enZA, es, fr, frCA } from 'date-fns/locale'
import React, { useEffect } from 'react'
import { registerLocale, setDefaultLocale } from 'react-datepicker'

import { multipleLanguages } from './components/feature-flags'
import { messages as enMessages } from './locales/en/messages'
import { messages as esMessages } from './locales/es/messages'
import { messages as frMessages } from './locales/fr/messages'

const defaultLocale = () => 'en'

const localeMessages = {
  en: enMessages,
  es: esMessages,
  fr: frMessages
}

/** Loads the correct locale for date-fns/locale
 *
 *  date-fns/locale does not support es-CL, Spanish (Chile)
 *  react-datepicker, which uses date-fns/locale, throws a stream
 *  of console messages when it encounters an unsupported locale.
 *  Here's an example of the error message:
 *  "A locale object was not found for the provided string ['es-CL']"
 *
 *  To avoid the console messages for Chile, we take the standard Spanish
 *  locale from date-fns/locale and modify it to have Spanish (Chile) code: "es-CL"
 *  so we have the line below: return { ...es, code: 'es-CL' }
 *  This way we avoid the console messages and we have a locale that
 *  react-datepicker can use.
 */
export const getFormatLocale = locale => {
  switch (locale) {
    case 'en-CA': // English (Canada)
      return enCA
    case 'en-GB': // English (United Kingdom)
      return enGB
    case 'en-IE': // English (Ireland)
      return enIE
    case 'en-ZA': // English (South Africa)
      return enZA
    case 'fr-CA': // French (Canada)
      return frCA
    case 'es-CL': // Spanish (Chile)
      return { ...es, code: 'es-CL' }
    default:
      if (locale.startsWith('es')) return es // Spanish
      if (locale.startsWith('fr')) return fr // French
      return enUS // English (United States)
  }
}

export const locales = {
  en: 'English',
  es: 'Espańol',
  fr: 'Français'
}

export async function saveLocale (locale) {
  window.localStorage.setItem('lang', locale)
}

// /**
//  * We do a dynamic import of just the catalog that we need
//  */
export async function loadLocale (locale) {
  const fallbackLocale = getFallbackLocale(locale)
  // dynamic import not working with esbuild yet
  // const { messages } = await import(`./locales/${fallbackLocale}/messages`)
  const messages = localeMessages[fallbackLocale]
  i18n.load(locale, messages)
  i18n.activate(locale)

  // Load and initialize the locale for date-fns/locale and react-datepicker
  const formatLocale = getFormatLocale(i18n.locale)
  registerLocale(formatLocale.code, formatLocale)
  setDefaultLocale(formatLocale.code)
}

export const getFallbackLocale = locale => {
  const fallback = locale.slice(0, 2)
  if (Object.keys(locales).includes(fallback)) {
    return fallback
  } else {
    return defaultLocale()
  }
}

const I18nWrapper = props => {
  useEffect(() => {
    if (multipleLanguages) {
      const locale = detect(fromStorage('lang'), fromNavigator(), defaultLocale)
      loadLocale(locale)
    } else {
      loadLocale(defaultLocale())
    }
  }, [])

  return <I18nProvider i18n={i18n}>{props.children}</I18nProvider>
}

export default I18nWrapper
