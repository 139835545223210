/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'

export function AutocompleteConfig ({ id, Gadgets, value }) {
  return (
    <Gadgets.ConfigBox
      configKey='autocomplete.enabled'
      label={i18n._({
        id: 'configure.autocomplete',
        message: 'Configure autocomplete'
      })}
    >
      <Gadgets.Dropdown
        id={`${id}-autocomplete`}
        label={i18n._({
          id: 'autocomplete.choose.type',
          message: 'Choose Type'
        })}
        configKey='autocomplete.value'
        transformOut={o => o?.id || null}
        options={[
          {
            key: 'on',
            lbl: i18n._({
              id: 'autocomplete.on.browser',
              message: 'On (Browser Default)'
            })
          },
          {
            key: 'off',
            lbl: i18n._({ id: 'autocomplete.off', message: 'Off' })
          },
          { key: 'name', lbl: i18n._({ id: 'name', message: 'Name' }) },
          {
            key: 'tel',
            lbl: i18n._({ id: 'phone.number', message: 'Phone Number' })
          },
          {
            key: 'street-address',
            lbl: i18n._({ id: 'street.address', message: 'Street Address' })
          },
          {
            key: 'postal-code',
            lbl: i18n._({ id: 'postal.code', message: 'Postal Code' })
          },
          { key: 'custom', lbl: i18n._({ id: 'custom', message: 'Custom' }) }
        ]}
        noEmptyOption
      />
      {value.autocomplete?.value === 'custom' && (
        <div className='pb-2'>
          <Gadgets.Text
            id={`${id}-autocomplete-custom`}
            configKey='autocomplete.customValue'
            label={i18n._({
              id: 'custom.autocomplete.value',
              message: 'Custom Autocomplete'
            })}
            aria-describedby={`${id}-autocomplete-custom-help`}
            className='!pb-0'
          />
          <small id={`${id}-autocomplete-custom-help`}>
            <Trans
              id='autocomplete.custom.help'
              message='Refer to <link>online docs</link> for more examples.'
              components={{
                link: (
                  <a
                    href='https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete'
                    rel='noreferrer'
                    className='text-text-link underline'
                  />
                )
              }}
            />
          </small>
        </div>
      )}
    </Gadgets.ConfigBox>
  )
}

export function getAutocompleteValue (details) {
  const config = details?.autocomplete ?? {}
  if (!config.enabled) return undefined
  if (!config.value || config.value === 'on') return 'on'
  if (config.value === 'custom') return config.customValue
  return config.value
}
