/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import React from 'react'

export default React.forwardRef(
  ({ onChange, onEnter, stopPropagation, className, ...props }, ref) => (
    <input
      className={cx(
        'kp-input',
        { 'border-2 border-red-400': props.error },
        className
      )}
      ref={ref}
      onKeyDown={onEnter ? e => e.keyCode === 13 && onEnter(e) : undefined}
      onChange={
        onChange
          ? e => {
              if (stopPropagation) e.stopPropagation()
              onChange(e.target.value)
            }
          : undefined
      }
      {...props}
    />
  )
)
