/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { Trans } from '@lingui/react'
import { isNil } from 'lodash'
import React from 'react'
import { useOutletContext } from 'react-router-dom'
import styled from 'styled-components'

import Loading from '../../components/loading'
import Spinner from '../../components/spinner'
import { GraphQLError } from '../../components/system-error'
import { VisuallyHidden } from '../../ui/a11y'
import Button from '../../ui/button'
import Input from '../../ui/input'
import { Space } from '../../ui/layout'
import Toggle from '../../ui/toggle'
import EmailPreview from './components/email-preview'
import FormPreview from './components/form-preview'
import { useRemoveBranding } from './components/mutation.remove-branding'
import { useUpdateBranding } from './components/mutation.update-branding'
import { useUploadMutation } from './components/mutation.upload-svg'

const defaultMaxHeight = 40

export function BrandingInner () {
  const { space } = useOutletContext()
  const id = space?.id
  const parentId = space?.parentId
  const ref = React.useRef()
  const update = useUpdateBranding(id)
  const remove = useRemoveBranding()
  const uploadFile = useUploadMutation()
  const [configuringEmail, setConfiguringEmail] = React.useState(false)
  const [saving, setSaving] = React.useState(false)
  const { data, error } = useQuery(brandingQuery, { variables: { id } })
  if (!data) return <Loading />
  if (error) return <GraphQLError error={error} />
  const isSuiteBranding = isNil(parentId)
  const { branding } = data
  return branding.exists || isSuiteBranding ? (
    <>
      <Wrapper className='branding text-sm'>
        <div>
          <Toggle
            off='Form'
            on='Email'
            value={configuringEmail}
            onChange={setConfiguringEmail}
          />
          <div className='border-b border-light-gray-400' />
          <Inputs>
            <div>
              <div className='flex'>
                <Label>
                  <Trans id='color' />
                </Label>
                {!isValidHexColor(branding.color) && (
                  <div
                    id='color-error'
                    className='mb-1 px-1 leading-[16px] text-red-500'
                  >
                    - <Trans id='invalid.color' />
                  </div>
                )}
              </div>
              <TextInput
                ariaInvalid={!isValidHexColor(branding.color)}
                ariaDescribedBy='color-error'
                value={branding.color}
                onChange={color => update({ color })}
              />
            </div>
            {!configuringEmail && (
              <div>
                <Label>
                  <Trans id='logo' />
                </Label>
                {branding.logo ? (
                  <Button outline onClick={() => update({ logo: '' })}>
                    <Trans id='remove.logo' />
                  </Button>
                ) : (
                  <input
                    type='file'
                    accept='image/png, image/jpeg'
                    onChange={async e => {
                      const file = e.target.files[0]
                      const { location } = await uploadFile(file)
                      let size = {}
                      if (!branding.emailLogo) {
                        size = await getSize(ref, location)
                      }
                      update({
                        logo: location,
                        maxHeight: defaultMaxHeight,
                        ...size
                      })
                    }}
                  />
                )}
              </div>
            )}
            {configuringEmail && (
              <div>
                <Label>
                  <Trans id='logo' />
                </Label>
                {branding.emailLogo ? (
                  <Button
                    outline
                    onClick={async () => {
                      const size = await getSize(ref, branding.logo)
                      update({ emailLogo: '', ...size })
                    }}
                  >
                    <Trans id='remove.logo' />
                  </Button>
                ) : (
                  <input
                    type='file'
                    accept='image/png, image/jpeg'
                    onChange={async e => {
                      const file = e.target.files[0]
                      const { location } = await uploadFile(file)
                      const size = await getSize(ref, location)
                      update({ emailLogo: location, ...size })
                    }}
                  />
                )}
              </div>
            )}
            <div>
              <Label>
                <Trans id='alt.text' />
              </Label>
              <TextInput
                value={branding.alt}
                onChange={alt => update({ alt })}
              />
            </div>
            {!configuringEmail && (
              <div>
                <Label>
                  <Trans id='max.height' />
                </Label>
                <NumberInput
                  value={branding.maxHeight}
                  max={80}
                  onChange={maxHeight => update({ maxHeight })}
                />
              </div>
            )}
            {configuringEmail && (
              <div>
                <Label>
                  <Trans id='width' />
                </Label>
                <NumberInput
                  value={branding.width}
                  onChange={w => update({ width: w, height: resizeH(ref, w) })}
                />
              </div>
            )}
            {configuringEmail && (
              <div>
                <Label>
                  <Trans id='height' />
                </Label>
                <NumberInput
                  value={branding.height}
                  onChange={h => update({ height: h, width: resizeW(ref, h) })}
                />
              </div>
            )}
          </Inputs>
          {!isSuiteBranding && (
            <>
              <Space expand />
              <Button
                mt='20px'
                outline
                disabled={saving}
                onClick={() => {
                  setSaving(true)
                  remove(id).then(() => setSaving(false))
                }}
              >
                {saving ? (
                  <Spinner size={20} />
                ) : (
                  <Trans id='restore.default.branding' />
                )}
              </Button>
            </>
          )}
        </div>
        {configuringEmail ? (
          <EmailPreview branding={branding} />
        ) : (
          <FormPreview branding={branding} />
        )}
      </Wrapper>
      <VisuallyHidden>
        <img ref={ref} src={branding.emailLogo || branding.logo} />
      </VisuallyHidden>
    </>
  ) : (
    <div className='flex flex-col items-center pt-8'>
      <div className='flex w-80 flex-col items-center'>
        <div>
          <Trans id='forms.used.by.apps.in.space.data' />
        </div>
        <Button
          mt={2}
          disabled={saving}
          onClick={() => {
            setSaving(true)
            update({}).then(() => setSaving(false))
          }}
        >
          {saving ? <Spinner size={20} /> : <Trans id='customize.branding' />}
        </Button>
      </div>
    </div>
  )
}

const brandingQuery = gql`
  query FetchBranding($id: ID) {
    branding(spaceId: $id) {
      id
      color
      logo
      emailLogo
      maxHeight
      width
      height
      alt
      exists
    }
  }
`

function NumberInput ({ value, onChange, max }) {
  const [num, setNum] = React.useState(value)
  React.useEffect(() => {
    setNum(value)
  }, [value])
  const update = num => {
    setNum(num)
    onChange(num)
  }
  return (
    <Input
      type='number'
      min={0}
      max={max}
      value={num}
      onChange={val => {
        if (val === '') return update(null)
        const number = parseInt(val, 10)
        if (!isNaN(number)) return update(number)
      }}
    />
  )
}

function TextInput ({ ariaDescribedBy, ariaInvalid, value, onChange }) {
  const [val, setVal] = React.useState(value)
  const update = val => {
    setVal(val)
    onChange(val)
  }
  return (
    <Input
      type='text'
      value={val}
      onChange={update}
      aria-describedby={ariaDescribedBy}
      aria-invalid={ariaInvalid}
    />
  )
}

function getSize (ref, src) {
  return new Promise((resolve, reject) => {
    if (!src) return resolve({})
    const img = ref.current
    function clampDimensions (width, height) {
      if (height <= defaultMaxHeight) return { width, height }
      return {
        height: defaultMaxHeight,
        width: Math.round((defaultMaxHeight / height) * width)
      }
    }
    img.onload = () => {
      const { width, height } = clampDimensions(
        img.naturalWidth,
        img.naturalHeight
      )
      resolve({ width, height })
    }
    img.onerror = reject
    img.src = src
  })
}

function resizeW (ref, height) {
  const { naturalHeight, naturalWidth } = ref.current
  return Math.round((naturalWidth / naturalHeight) * height) || 0
}

function resizeH (ref, width) {
  const { naturalHeight, naturalWidth } = ref.current
  return Math.round((naturalHeight / naturalWidth) * width) || 0
}

function isValidHexColor (hexColor) {
  const regex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/
  return regex.test(hexColor)
}

const Wrapper = styled.div`
  display: flex;
  height: 100%;

  > :first-child {
    height: 100%;
    width: 250px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;

    > :first-child {
      justify-content: center;
      padding: 24px 0;
    }
  }

  > :last-child {
    flex: 1;
  }
`

const Inputs = styled.div`
  overflow: auto;
  flex: 1;
  padding: 0 16px;
  & > div {
    margin-top: 24px;
  }
  & input {
    width: 100%;
  }
`
function Label ({ children }) {
  return (
    <label className='block whitespace-pre text-medium-gray-500'>
      {children}
    </label>
  )
}
