/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import * as Sentry from '@sentry/browser'
import React from 'react'
import { Link, useOutletContext } from 'react-router-dom'
import styled, { css } from 'styled-components'

import AnimatedOutlet from '../../../../components/animated-outlet'
import PopoverButton from '../../../../components/data-table/popover-button'
import * as Icons from '../../../../icons'
import { useAlerts } from '../../../../ui/alerts'
import Button from '../../../../ui/button'
import { Body2, H4 } from '../../../../ui/typography'
import hashColor from '../../utils/get-kuali-color-by-hash'
import { useUpdateGroupMutation } from './components/mutation.update-group'
import RolePopup from './components/role-details-popup'

export default function GroupRoles () {
  const { group } = useOutletContext()
  return (
    <>
      <AnimatedOutlet context={{ group }} />
      <GroupRolesInner group={group} />
    </>
  )
}

function GroupRolesInner ({ group }) {
  const roleSchema = group?.category?.roleSchemas ?? []
  const groupSchema =
    group?.roleSchemas?.filter(
      role => !roleSchema.find(r => r.id === role.id)
    ) ?? []
  const canAdminister = group?.access?.isAdmin

  const adminRoles = [
    {
      name: i18n._({ id: 'administrators', message: 'Administrators' }),
      id: 'admins',
      description: (
        <Trans
          id='administrators.data'
          message='Users authorized to administer for this group'
        />
      )
    },
    {
      name: i18n._({ id: 'members', message: 'Members' }),
      id: 'members',
      description: (
        <Trans id='members.data' message='All users belonging to the group' />
      )
    }
  ]

  return (
    <Wrapper>
      <h1>
        <Trans id='system.roles' message='System Roles' />
      </h1>

      <StyledRoleList>
        {adminRoles.map(role => (
          <Card
            key={role.id}
            role={role}
            group={group}
            canAdminister={canAdminister}
          />
        ))}
      </StyledRoleList>

      {group?.category?.id && (
        <>
          <h1>
            <Trans id='blueprint.roles' message='Blueprint Roles' />
          </h1>
          <StyledRoleList>
            {roleSchema?.map(role => (
              <Card
                key={role.id}
                role={role}
                group={group}
                canAdminister={canAdminister}
              />
            ))}
          </StyledRoleList>
        </>
      )}
      <TitleSpan>
        <h1>
          <Trans id='group.roles' message='Group Roles' />
        </h1>
        {canAdminister && (
          <PopoverButton
            hideArrow
            buttonProps={{
              'aria-label': (
                <Trans id='add.group.role' message='Add Group Role' />
              ),
              transparent: false
            }}
            label={
              <>
                <Icons.Add fill='var(--white)' mr={2} />
                <Trans id='add.role' message='Add Role' />
              </>
            }
            bottom={40}
          >
            {hide => (
              <div style={{ width: '100%' }}>
                <RolePopup group={group} hide={hide} />
              </div>
            )}
          </PopoverButton>
        )}
      </TitleSpan>

      <StyledRoleList>
        {groupSchema?.map(role => (
          <Card
            key={role.id}
            role={role}
            group={group}
            groupRoles
            canAdminister={canAdminister}
          />
        ))}
      </StyledRoleList>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 48px 8px;
  max-width: 814px;
  margin: 0 auto;
  h1 {
    margin: 10px 0;
    font-size: 22px;
    font-weight: 600;
  }

  @media (max-width: 540px) {
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const TitleSpan = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  margin-top: 32px;
`

const StyledRoleList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  @media (max-width: 540px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

function Card ({ role, group, groupRoles, canAdminister }) {
  const alerts = useAlerts()
  const closeAlertsRef = React.useRef()
  const [updateGroup, { loading: updateGroupLoading }] =
    useUpdateGroupMutation(group)

  const thisRole = group?.roles?.find(r => r.roleId === role.id)
  const users = thisRole?.membersConnection?.edges?.map(e => e.node) ?? []
  const totalCount = thisRole?.membersConnection?.totalCount ?? 0

  return (
    <CardWrapper key={role.id} className='bg-white dark:bg-light-gray-300'>
      <CardInner>
        <div>
          <Body2 as='label' htmlFor='role-name'>
            <Trans id='role.name.colon' message='Role Name:' />
          </Body2>
          <H4 as='div' mb={2} id='role-name'>
            {role?.name}
          </H4>
          <Body2>{role?.description ?? ''}</Body2>
        </div>
        <div>
          <Body2>
            <Trans
              id='people.count'
              message='People ({count}):'
              values={{ count: totalCount ?? 0 }}
            />
          </Body2>
          <Circles totalCount={totalCount} people={users} />
        </div>
      </CardInner>
      <CardBottom
        spread={groupRoles && canAdminister}
        className='border-t border-light-gray-400'
      >
        <Button
          aria-label={
            role?.name
              ? i18n._({
                  id: 'view.role.name',
                  message: 'View {name}',
                  values: { name: role?.name }
                })
              : i18n._({ id: 'view.role', message: 'View Role' })
          }
          transparent
          small
          as={Link}
          to={`${encodeURIComponent(role.id)}/role`}
        >
          <Trans id='view.all.people' message='View All People' />
        </Button>
        {groupRoles && canAdminister && (
          <ButtonsWrap>
            <PopoverButton
              hideArrow
              buttonProps={{
                transparent: true,
                icon: true,
                disabled: updateGroupLoading
              }}
              label={<Icons.Edit />}
              bottom={40}
            >
              {hide => (
                <div style={{ width: '100%' }}>
                  <RolePopup
                    group={group}
                    hide={hide}
                    id={role.id}
                    initialTitle={role.name}
                    initialDescription={role.description}
                  />
                </div>
              )}
            </PopoverButton>
            <Button
              disabled={updateGroupLoading}
              icon
              transparent
              onClick={() => {
                closeAlertsRef.current?.()
                closeAlertsRef.current = alerts.type2(
                  i18n._(
                    {
                      id: 'role.permanently.deleted',
                      message: 'Role ({roleName}) will be permanently deleted.'
                    },
                    { roleName: role?.name }
                  ),
                  'error',
                  close => (
                    <Button
                      small
                      ml={2}
                      onClick={async () => {
                        close()
                        const roles =
                          group?.roles
                            ?.map(r => ({
                              __typename: 'Role',
                              id: r.roleId,
                              value: r.memberIds
                            }))
                            ?.filter(r => r.id !== role.id) ?? []
                        const roleSchemas = group?.roleSchemas?.filter(
                          r => r.id !== role.id
                        )
                        updateGroup({ roleSchemas, roles })
                          .then(() =>
                            alerts.type3(
                              i18n._({
                                id: 'role.deleted',
                                message: 'Role deleted'
                              }),
                              'success'
                            )
                          )
                          .catch(err => {
                            alerts.type3(
                              i18n._({
                                id: 'failed.delete.role',
                                message: 'Failed to delete role'
                              }),
                              'error'
                            )
                            Sentry.captureException(err)
                          })
                      }}
                    >
                      <Trans id='continue' message='Continue' />
                    </Button>
                  )
                )
              }}
            >
              <Icons.Delete />
            </Button>
          </ButtonsWrap>
        )}
      </CardBottom>
    </CardWrapper>
  )
}

const CardWrapper = styled.div`
  width: 250px;
  height: 250px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  border-radius: 4px;
  box-shadow: 0 0 8px 1px rgb(0 0 0 / 20%);
`

const CardInner = styled.div`
  padding: 16px 16px 0;
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  ${Body2} {
    color: var(--medium-gray-500);
    margin-bottom: 4px;
  }
`

const CardBottom = styled.div`
  width: 100%;
  height: 56px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: ${p => (p.spread ? 'space-between' : 'center')};
`

const ButtonsWrap = styled.div`
  display: flex;
  align-items: center;
`

const MAX_PEOPLE = 12
function Circles ({ totalCount, people }) {
  const overMax = totalCount > MAX_PEOPLE
  const filteredPeople = people.slice(0, overMax ? MAX_PEOPLE - 1 : MAX_PEOPLE)

  return (
    <CircleBox>
      {filteredPeople.map((p, i) => (
        <Circle
          className='border-2 border-white dark:border-light-gray-300'
          key={i}
          num={i}
          color={hashColor(p.label)}
          active={p.active}
        >
          {(p.firstName?.toUpperCase()[0] || '') +
            (p.lastName?.toUpperCase()[0] || '') || p.label?.toUpperCase()[0]}
        </Circle>
      ))}
      {overMax && (
        <Circle
          className='border-2 border-white dark:border-light-gray-300'
          num={MAX_PEOPLE - 1}
          color='#DDD'
        >
          +{totalCount - MAX_PEOPLE + 1}
        </Circle>
      )}
    </CircleBox>
  )
}

const Circle = styled.div`
  border-radius: 100%;
  width: 36px;
  height: 36px;
  background-color: ${p => (!p.active && '#ababab') || (p.color ?? '#E43935')};
  position: absolute;
  left: ${p => p.num * 16}px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  ${p =>
    !p.active &&
    css`
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background-color: #fff;
        opacity: 0.5;
      }
    `}
`

const CircleBox = styled.div`
  height: 36px;
  width: 100%;
  position: relative;
  margin-bottom: 8px;
`
