/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { cloneDeep, isEmpty, isEqual } from 'lodash'
import React from 'react'
import { useBlocker } from 'react-router-dom'
import { useImmer } from 'use-immer'

import FormbotContainer from '../../components/formbot-container/formbot-container'
import { useIds } from '../../components/use-ids'
import { useSingleAlert } from '../../components/use-single-alert'
import Formbot, { validate } from '../../formbot'
import { useAlerts } from '../../ui/alerts'

export default function RuntimeForm ({ data, template }) {
  const { appId, datasetId: pageId } = useIds()
  const alerts = useAlerts()
  const singleAlert = useSingleAlert('type2')
  const [saving, setSaving] = React.useState(false)
  const [formData, updateFormData] = useImmer(null)
  const [formChanged, setFormChanged] = React.useState(false)
  const [imageCache, setImageCache] = useImmer({})
  const [updateSettings] = useMutation(mutation)

  const updateImageCache = (key, val) => {
    setImageCache(draft => {
      draft[key] = val
    })
  }
  const structure = React.useMemo(
    () => ({
      template: cloneDeep(template),
      metaFields: [],
      integrationFields: [],
      trashed: []
    }),
    [template]
  )
  const document = { data: formData, meta: {}, integration: {} }

  React.useEffect(() => {
    const actualData = data
    if (actualData) updateFormData(() => actualData)
  }, [data, updateFormData])

  const handleChange = (key, val) => {
    setFormChanged(true)
    return updateFormData(draft => {
      draft[key] = val
    })
  }

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      formChanged && currentLocation.pathname !== nextLocation.pathname
  )

  React.useEffect(() => {
    const dataMatch = isEqual(formData, data)
    if (dataMatch) return setFormChanged(false)
    if (blocker.state === 'blocked' && formChanged) {
      singleAlert(
        i18n._('pagesrunner.edit.unsaved.edits'),
        'error',
        close => (
          <button
            className='kp-button kp-button-solid'
            onClick={() => {
              saveDocument()
              blocker.reset()
              close()
            }}
          >
            <Trans id='save' />
          </button>
        ),
        undefined,
        close => (
          <button
            className='kp-button'
            onClick={() => {
              blocker.proceed()
              close()
            }}
          >
            <Trans id='pagesrunner.edit.close' />
          </button>
        )
      )
    }
  }, [blocker, data, formData, formChanged])

  const saveDocument = () => {
    setSaving(true)
    setTimeout(async () => {
      try {
        setFormChanged(false)
        await updateSettings({ variables: { appId, pageId, data: formData } })
        setSaving(false)
        alerts.type3(
          i18n._({ id: 'settings.saved', message: 'Settings saved' }),
          'success'
        )
      } catch (error) {
        const message =
          error?.graphQLErrors?.[0]?.message ??
          i18n._('pagesrunner.edit.document.error')
        alerts.type3(message, 'error')
        setSaving(false)
      }
    }, 2000)
  }

  const validations = formData ? validate(document, structure) : {}

  return (
    <>
      <FormbotContainer
        className='bg-light-gray-200'
        actionButtons={
          <button
            className='kp-button-solid max-lg:w-full'
            data-test='submit-approval'
            disabled={!isEmpty(validations) || saving}
            onClick={saveDocument}
          >
            {saving ? `${i18n._('saving')}` : `${i18n._('save')}`}
          </button>
        }
        formbot={
          formData && (
            <Formbot.Edit
              className='formbot'
              document={document}
              structure={structure}
              onChange={handleChange}
              context={{ validations, imageCache, updateImageCache }}
            />
          )
        }
      />
    </>
  )
}

const mutation = gql`
  mutation UpdateSettings($appId: ID!, $pageId: ID!, $data: JSON!) {
    updateSettings(appId: $appId, pageId: $pageId, data: $data) {
      id
      data
    }
  }
`
