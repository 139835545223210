/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { useMutation } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import * as Sentry from '@sentry/browser'
import { debounce, filter, keyBy } from 'lodash'
import React from 'react'
import { Link, useOutletContext } from 'react-router-dom'
import styled from 'styled-components'

import AnimatedOutlet from '../../../components/animated-outlet'
import { NoMatching, NoResults } from '../../../components/data-table/empty'
import PopoverButton from '../../../components/data-table/popover-button'
import { SmallSearch } from '../../../components/data-table/search-bar'
import Loading from '../../../components/loading'
import * as Icons from '../../../icons'
import { useAlerts } from '../../../ui/alerts'
import CREATE_CATEGORY_MUTATION from '../gql/mutation.create-category'
import NewBlueprintPopup from './components/new-blueprint-popup'

export default function Blueprints () {
  const { blueprints, refetchBlueprints, loading, canManageIdentity } =
    useOutletContext()
  const alerts = useAlerts()

  const [searchTerm, setSearchTerm] = React.useState('')

  const debouncedSearch = React.useCallback(
    debounce(newSearchTerm => setSearchTerm(newSearchTerm), 400),
    []
  )

  const [createCategory, { loading: mutationLoading }] = useMutation(
    CREATE_CATEGORY_MUTATION
  )

  const filteredBlueprints = filter(blueprints, ({ name }) => {
    return name.toLowerCase().includes(searchTerm.toLowerCase().trim())
  })

  const showHierarchy = !searchTerm && blueprints?.length
  const showNoMatching =
    !loading && searchTerm && filteredBlueprints?.length === 0
  const showNoResults = !loading && blueprints?.length === 0

  if (loading) return <Loading />
  return (
    <StyledContainer className='text-sm'>
      <AnimatedOutlet
        context={{ blueprints, canManageIdentity, prefix: '../..' }}
      />
      <StyledBlueprintsWrapper>
        <TopSection>
          <ButtonGrouping>
            <SmallSearch
              value={searchTerm}
              onChange={newSearchTerm => {
                setSearchTerm(newSearchTerm)
                debouncedSearch(newSearchTerm)
              }}
              reset={() => {
                debouncedSearch('')
                setSearchTerm('')
              }}
            />
            {canManageIdentity && (
              <PopoverButton
                hideArrow
                buttonProps={{ transparent: false, disabled: mutationLoading }}
                label={
                  <>
                    <Icons.Add fill='var(--white)' mr={2} />
                    <Trans id='add.blueprint' />
                  </>
                }
                right={1}
              >
                {hide => (
                  <div style={{ width: '100%' }}>
                    <NewBlueprintPopup
                      blueprints={blueprints}
                      submitting={mutationLoading}
                      onSubmit={({ name, parentId }) =>
                        createCategory({ variables: { name, parentId } })
                          .then(() => {
                            alerts.type3(
                              i18n._('blueprint.created.successfully'),
                              'success'
                            )
                            hide()
                            refetchBlueprints()
                          })
                          .catch(err => {
                            hide()
                            alerts.type3(
                              i18n._('could.not.create.blueprint'),
                              'error'
                            )
                            Sentry.captureException(err)
                          })
                      }
                      onCancel={hide}
                    />
                  </div>
                )}
              </PopoverButton>
            )}
          </ButtonGrouping>
        </TopSection>
        {showNoResults ? (
          <NoResults
            text={i18n._('no.results')}
            subtext={i18n._('no.blueprints.yet')}
          />
        ) : showNoMatching ? (
          <NoMatching
            reset={() => {
              debouncedSearch('')
              setSearchTerm('')
            }}
            text={i18n._('no.matching.search.results')}
            subtext={i18n._('please.try.again.general.search.terms')}
          />
        ) : showHierarchy ? (
          <HierarchicalGroup
            root
            blueprint={{ name: 'everyone' }}
            blueprints={blueprints}
          />
        ) : (
          filteredBlueprints.map(blueprint => (
            <SearchItem
              key={blueprint.id}
              blueprint={blueprint}
              blueprints={blueprints}
            />
          ))
        )}
      </StyledBlueprintsWrapper>
    </StyledContainer>
  )
}

function HierarchicalGroup ({ root, blueprint, blueprints }) {
  let children
  if (root) {
    const categoryMap = keyBy(blueprints, 'id')
    children = blueprints.filter(
      cat => !cat.parentId || !categoryMap[cat.parentId]
    )
  } else {
    children = blueprints.filter(cat => cat.parentId === blueprint.id)
  }
  return (
    <CategoryItem root={root} category={blueprint}>
      <StyledList>
        {children.map(child => (
          <HierarchicalGroup
            key={child.id}
            blueprint={child}
            blueprints={blueprints}
          />
        ))}
      </StyledList>
    </CategoryItem>
  )
}

function CategoryItem ({ category, root, children }) {
  return root ? (
    <>
      <StyledCategoryItem className='bg-white dark:bg-light-gray-300' root>
        <span>
          <Trans id='everyone' />
        </span>
      </StyledCategoryItem>
      {children}
    </>
  ) : (
    <>
      <StyledCategoryItem className='bg-white dark:bg-light-gray-300' as='li'>
        <StyledItemContainerLink
          to={`${category.id}/details/info`}
          className='border-white hover:border hover:text-text-link dark:border-medium-gray-300'
        >
          <StyledCategoryItemName>{category.name}</StyledCategoryItemName>
        </StyledItemContainerLink>
        <StyledInfoBoxContainer
          to={`${category.id}/group-list`}
          className='border-white hover:border dark:border-medium-gray-300'
        >
          <InfoBox title={i18n._('groups')} number={category?.groupCount} />
          <InfoBox
            title={i18n._('roles')}
            number={category?.roleSchemas?.length}
          />
        </StyledInfoBoxContainer>
      </StyledCategoryItem>
      {children}
    </>
  )
}

const InfoBox = ({ title, number }) => {
  return (
    <StyledGroupsRolesInfoBox>
      <StyledInfoBoxTitle className='text-medium-gray-200 dark:text-medium-gray-400'>
        {title}
      </StyledInfoBoxTitle>
      <StyledInfoBoxSpan className='text-medium-gray-500 dark:text-dark-gray-200'>
        {number ?? 0}
      </StyledInfoBoxSpan>
    </StyledGroupsRolesInfoBox>
  )
}

const SearchItem = ({ blueprint, blueprints }) => {
  const ancestors = getAncestors(blueprints, blueprint.id).map(b => b.name)
  ancestors.pop()
  ancestors.unshift('Home')
  return (
    <StyledSearchItem
      to={`${blueprint.id}/details/info`}
      className='border-white bg-white hover:border dark:border-medium-gray-300 dark:bg-light-gray-300'
    >
      <StyledSearchItemName className='text-dark-gray-500'>
        {blueprint.name}
      </StyledSearchItemName>
      <StyledSearchItemPath className='text-medium-gray-500'>
        {ancestors.join(' > ')}
      </StyledSearchItemPath>
    </StyledSearchItem>
  )
}

const getAncestors = (blueprints, id, visited = new Set()) => {
  const blueprint = blueprints.find(blueprint => blueprint.id === id)
  if (!blueprint || visited.has(blueprint)) return []
  visited.add(blueprint)
  return [...getAncestors(blueprints, blueprint.parentId, visited), blueprint]
}

const StyledItemContainerLink = styled(Link)`
  width: calc(100% - 120px);
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: inherit;
  padding-left: 16px;
  border-radius: 4px 0 0 4px;
  z-index: 1;

  @media (max-width: 500px) {
    width: 100%;
  }
`

const StyledInfoBoxContainer = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  padding: 0 24px;
  gap: 16px;

  &:hover {
    * {
      color: var(--text-link);
    }
  }
`

const StyledCategoryItemName = styled.span`
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 16px;
`

const StyledSearchItemName = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.0025em;
`

const StyledSearchItem = styled(StyledItemContainerLink)`
  margin-bottom: 10px;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;
  border-radius: 4px;
  width: 100%;
  height: 48px;

  &:hover {
    ${StyledSearchItemName} {
      color: var(--text-link);
    }
  }
`
const StyledSearchItemPath = styled.span`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: 0.004em;
`

const StyledInfoBoxTitle = styled.span`
  width: 28px;
  height: 12px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.004em;
  text-transform: uppercase;
`

const StyledInfoBoxSpan = styled.span`
  width: 8px;
  height: 20px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.0025em;
`

const StyledGroupsRolesInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  align-items: center;
  font-weight: 500;
  color: #8a8a8a;
`

const PADDING = 16
const WIDTH = 1200
const FIRST_MEDIA_WIDTH = WIDTH + PADDING * 2

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: calc((100vw - ${WIDTH}px) / 2) ${WIDTH}px calc(
      100% - (${WIDTH}px + 100vw) / 2
    );
  height: 100%;

  @media (max-width: 1232px) {
    grid-template-columns:
      calc(${PADDING}px)
      calc(100vw - (${PADDING}px * 2))
      calc(${PADDING}px - (100vw - 100%));
  }
`

const StyledBlueprintsWrapper = styled.div`
  grid-column-start: 2;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: ${WIDTH}px;
  margin: 0;
  @media (max-width: ${FIRST_MEDIA_WIDTH}px) {
    width: 100%;
  }
`

const StyledList = styled.ul`
  list-style: none;
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  padding-left: 24px;

  &:before {
    content: '';
    position: absolute;
    bottom: 33px;
    left: -11px;
    border-left: 1px solid #bbbbbb;
    height: 100%;
  }
  &:last-child {
    &:before {
      border-left: none;
    }
  }

  @media (max-width: 500px) {
    padding-left: 12px;
    &:before {
      left: -6px;
    }
  }
`

const StyledCategoryItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  position: relative;
  border-radius: 4px;
  position: relative;
  padding-left: ${p => p.root && '16px'};
  min-height: 48px;

  &:before {
    border-left: ${p => (p.root ? 'none' : '1px solid #bbbbbb')};
    height: calc(100% + 10px);
    position: absolute;
    bottom: 24px;
    left: -11px;
    content: '';
  }

  &:last-child {
    &:before {
      border-left: none;
    }
  }

  &:first-child {
    &:before {
      height: calc(50% + 10px);
    }
  }

  &:after {
    content: '';
    border-bottom: ${p => (p.root ? 'none' : '1px solid #bbbbbb')};
    width: 11px;
    position: absolute;
    top: 50%;
    left: -11px;
  }

  @media (max-width: 500px) {
    ${StyledInfoBoxContainer} {
      display: none;
    }

    &:before {
      left: -6px;
    }

    &:after {
      width: 6px;
      left: -6px;
    }
  }
`

const TopSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding: 16px 0 32px;
  h2 {
    margin: 0;
  }

  @media (max-width: 1220px) {
    padding-bottom: 16px;
  }
`

const ButtonGrouping = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
