/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import { find, forEach, get, includes, map, reduce, set } from 'lodash'
import React from 'react'

import {
  childAutoUpdates,
  getRepeatableChildren
} from '../../formbot/engine/formbot/utils'
import * as Icons from '../../icons'
import { CALC_FUNCTIONS } from './calculation-constants'
import * as calcFunctions from './calculation-functions'
import gadgetFunctions from './calculation-gadget-config'

export const MATH_GADGETS = ['Number', 'Currency', 'LinearScale']
const gadgetCanCalculate = gadget => {
  return MATH_GADGETS.includes(
    gadget.details?.selectedOutputField?.type || gadget.type
  )
}

export default props => {
  const [calcValue, setCalcValue] = React.useState(
    props.value || {
      parts: [{ formKey: '' }, { formKey: '' }],
      calcFunctionForAll: CALC_FUNCTIONS.SUM
    }
  )

  const handleChange = (prefix, value, formKey) => {
    const newValue = prefix ? set(calcValue, prefix, value) : value
    setCalcValue(newValue)
    props.onChange(newValue)
  }

  return (
    <CalculationsConfig
      {...props}
      handleChange={handleChange}
      value={calcValue}
    />
  )
}

const CalculationsConfig = ({
  value,
  handleChange,
  allGadgets,
  gadgetType,
  id,
  formKey
}) => {
  const gadgets = reduce(
    allGadgets,
    (acc, gadget) => {
      if (gadgetCanCalculate(gadget)) {
        return [gadget, ...acc]
      }
      if (includes(['Repeater', 'Table'], gadget.type)) {
        const children = getRepeatableChildren(gadget)
        const isInSameRepeatable = find(children, { id })
        const repeatableValues = reduce(
          children,
          (acc, child) => {
            // Check that the child gadget is not the gadget being configured
            if (child.id === id) return acc

            // Check if the child gadget type allows calculations
            if (!gadgetCanCalculate(child)) return acc

            const autoUpdate = childAutoUpdates(child, children, allGadgets)

            // Check if the child is in the same table as the gadget being configured
            if (isInSameRepeatable) {
              return [
                ...acc,
                {
                  ...child,
                  autoUpdate,
                  parentFormKey: gadget.formKey
                }
              ]
            }

            // Check if footer is enabled for the child gadget and that a calc function is selected
            const footer = gadget.details?.calculationFooter ?? {}
            if (!footer.enabled) {
              return acc
            }

            const footerCalcFunction =
              gadget.type === 'Repeater'
                ? find(footer.fields, { id: child.id })?.calcFunction
                : footer.columns?.[child.id]

            if (!footerCalcFunction) {
              return acc
            }

            return [
              ...acc,
              {
                ...child,
                autoUpdate,
                parentFormKey: gadget.formKey,
                footerCalcFunction
              }
            ]
          },
          []
        )
        return [...repeatableValues, ...acc]
      }
      return acc
    },
    []
  )

  const parts = get(value, 'parts')

  const handleRemoveField = i => {
    parts.splice(i, 1)
    let newVal = { ...value, parts }
    if (!parts.length) newVal = null
    handleChange('', newVal)
  }

  const handleAddField = () => {
    const newVal = {
      ...value,
      parts: [...parts, { formKey: '' }]
    }
    handleChange('', newVal)
  }

  const handleSetAll = val => {
    const newVal = {
      ...value,
      calcFunctionForAll: val
    }
    handleChange('', newVal)
  }

  React.useEffect(() => {
    // in case they reenabled calculations on another gadget that depends
    // on this one, we want to clear out any circular dependencies
    // when we render the config
    forEach(parts, (part, i) => {
      if (!part?.id) return
      const gadget = find(gadgets, g => g.id === part.id)
      if (gadget && checkForCircularDependency(gadget, formKey)) {
        handleChange(`parts.${i}`, undefined)
      }
    })
  }, [parts, gadgets, handleChange])

  const gadgetOptGroups = reduce(
    gadgets,
    (acc, gadget) => {
      if (checkForCircularDependency(gadget, formKey)) {
        acc.circular.push(
          <Option gadget={gadget} disabled style={{ color: 'darkred' }} />
        )

        return acc
      }
      if (gadget.autoUpdate) {
        acc.autoUpdate.push(
          <Option gadget={gadget} disabled style={{ color: 'darkred' }} />
        )

        return acc
      }
      acc.available.push(<Option gadget={gadget} />)
      return acc
    },
    { available: [], circular: [], autoUpdate: [] }
  )

  return (
    <div className='flex flex-col items-center'>
      <CalcFunctionSelector
        value={value?.calcFunctionForAll}
        gadgetType={gadgetType}
        onChange={val => handleSetAll(val)}
      >
        <div className='mr-2 flex items-center gap-2'>
          <Icons.Calculate />
          <h6>
            <Trans id='pagesbuilder.calc.type' />
          </h6>
        </div>
      </CalcFunctionSelector>
      {map(parts, (part, i) => {
        const prefix = `parts.${i}`
        return (
          <div className='mt-4 w-full rounded' role='row' key={i}>
            <div className='flex items-center'>
              <h5 className='mr-2'>
                <Trans id='pagesbuilder.calc.field' /> {i + 1}
              </h5>
              <select
                className='kp-select w-[45%]'
                aria-label={i18n._('pagesbuilder.calc.calc.field')}
                onChange={e =>
                  handleChange(
                    `${prefix}`,
                    find(gadgets, { id: e.target.value }),
                    formKey
                  )
                }
                value={get(value, `${prefix}.id`, '')}
              >
                <option value=''>- - -</option>
                {gadgetOptGroups.available.length > 0 && (
                  <optgroup
                    label={i18n._('pagesbuilder.calc.available.fields')}
                  >
                    {gadgetOptGroups.available}
                  </optgroup>
                )}
                {gadgetOptGroups.circular.length > 0 && (
                  <optgroup label={i18n._('pagesbuilder.calc.unavailable')}>
                    {gadgetOptGroups.circular}
                  </optgroup>
                )}
                {gadgetOptGroups.autoUpdate.length > 0 && (
                  <optgroup
                    label={i18n._({
                      id: 'pagesbuilder.calc.unavailable.autoupdate',
                      message: 'Unavailable (Auto-Updating)'
                    })}
                  >
                    {gadgetOptGroups.autoUpdate}
                  </optgroup>
                )}
              </select>
              {i > 1 && (
                <button
                  className='kp-button-transparent kp-button-icon text-medium-gray-500'
                  aria-label={i18n._('pagesbuilder.calc.remove.row')}
                  onClick={() => handleRemoveField(i)}
                >
                  <Icons.Delete />
                </button>
              )}
            </div>
          </div>
        )
      })}
      <button className='kp-button-transparent mt-2' onClick={handleAddField}>
        <Icons.Add className='mr-2 h-3 w-3 fill-blue-500' />
        <Trans id='pagesbuilder.calc.field.add' />
      </button>
    </div>
  )
}

const Option = ({ gadget, ...props }) => (
  <option
    title={
      gadget.parentFormKey
        ? `
      ${gadget.parentFormKey.replace('data.', '')} > ${gadget.formKey}`
        : `
      ${gadget.formKey.replace('data.', '')}`
    }
    key={gadget.id}
    value={gadget.id}
    {...props}
  >
    {gadget?.customName?.value ||
      gadget.label ||
      `${gadget.type} ${gadget.formKey}`}
    {gadget.footerCalcFunction && ' (' + gadget.footerCalcFunction + ')'}
  </option>
)

export const CalcFunctionSelector = ({
  onChange,
  value,
  disabled,
  children,
  gadgetType,
  ...rest
}) => {
  const calcFunctionOptions = gadgetFunctions?.[gadgetType]
  return (
    <div
      className={cx('flex w-full items-center justify-between', {
        'border-t border-light-gray-300 py-2 dark:border-medium-gray-200':
          includes(['Repeater', 'Table'], gadgetType)
      })}
    >
      {children}
      <select
        className='kp-select w-[45%]'
        value={value || ''}
        onChange={e => onChange(e.target.value)}
        disabled={disabled}
      >
        {calcFunctionOptions?.map(option => {
          const { name } = calcFunctions?.[option]
          return (
            <option key={name} value={name}>
              {name}
            </option>
          )
        })}
      </select>
    </div>
  )
}

const checkForCircularDependency = (gadget, formKey) => {
  if (!gadget?.details?.calculation?.enabled) return false
  let circular = false
  forEach(gadget?.details?.calculation?.value?.parts, (part, i) => {
    if ([formKey, 'data.' + formKey].includes(part?.formKey)) {
      circular = true
      return false
    }
    circular = checkForCircularDependency(part, formKey)
  })
  return circular
}
