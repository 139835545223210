/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import * as Sentry from '@sentry/browser'
import { find, map } from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Typeahead } from '../../../../components/lookup-controls'
import Spinner from '../../../../components/spinner'
import Tooltip, { TooltipTrigger } from '../../../../components/tooltip'
import useDebouncedValue from '../../../../components/use-debounced-value'
import * as Icons from '../../../../icons'
import { useAlerts } from '../../../../ui/alerts'
import Button from '../../../../ui/button'
import Input from '../../../../ui/input'
import { Flex } from '../../../../ui/layout'
import { Option, Select } from '../../../../ui/select'
import { Body1 } from '../../../../ui/typography'
import {
  createGroupMutation,
  createGroupWithCategoryIdMutation
} from '../../gql/mutation.create-group'
import { updateParentQueryCache } from './hierarchy-view'

const createGroupMutationParams = {
  update (cache, result) {
    const group = result.data?.createGroup?.group
    if (!group || group.id === 'temporary-id') return
    updateParentQueryCache(cache, group)
  }
}

export default function NewGroupPopup ({
  label,
  onCancel,
  blueprints,
  blueprint,
  passedParent,
  refetch,
  path
}) {
  const [name, setName] = React.useState('')
  const [parent, setParent] = React.useState(passedParent ?? null)
  const [thisBlueprint, setThisBlueprint] = React.useState(blueprint)
  const [createGroup, { loading: createGroupLoading }] = useMutation(
    createGroupMutation,
    createGroupMutationParams
  )
  const [
    createGroupWithCategoryId,
    { loading: createGroupWithCategoryIdLoading }
  ] = useMutation(createGroupWithCategoryIdMutation, createGroupMutationParams)
  const loading = createGroupLoading || createGroupWithCategoryIdLoading
  const alerts = useAlerts()

  const invalid = !name

  const handleChange = id => {
    setThisBlueprint(find(blueprints, blueprint => blueprint.id === id))
  }

  const create = e => {
    const categoryId = thisBlueprint?.id || null
    const createMethod = categoryId ? createGroupWithCategoryId : createGroup
    e.preventDefault()
    if (invalid) return

    createMethod({
      variables: { categoryId, name, parentId: parent?.id },
      optimisticResponse: {
        createGroup: {
          group: {
            name,
            __typename: 'Group',
            id: 'temporary-id',
            parentId: parent?.id ?? null,
            categoryId: thisBlueprint?.id ?? null,
            hasChildren: false
          },
          query: {
            __typename: 'Query',
            category: {
              __typename: 'Category',
              id: thisBlueprint?.id,
              groupCount: thisBlueprint?.groupCount + 1,
              historyConnection: {
                id: 'temp',
                __typename: 'GroupHistoryConnection',
                edges: [
                  {
                    node: {
                      id: 'node - temp'
                    }
                  }
                ]
              }
            }
          }
        }
      }
    })
      .then(res => {
        if (refetch) refetch()
        alerts.type3(
          close => (
            <>
              <Trans
                id='successfully.created.group.link'
                components={{
                  link: (
                    <Link
                      to={`${path}/${res?.data?.createGroup?.group?.id}/view`}
                      relative='path'
                      style={{ color: '#3369a3' }}
                      onClick={close}
                    />
                  )
                }}
              />
            </>
          ),
          'success',
          6000
        )
        onCancel()
      })
      .catch(err => {
        alerts.type3(i18n._('could.not.create.group'), 'error')
        Sentry.captureException(err)
      })
  }

  const showParentGroupSelect =
    !passedParent &&
    thisBlueprint?.parentId &&
    !(thisBlueprint?.parentId === 'global')
  return (
    <Flex justifyContent='center'>
      <Wrapper data-testid={`popover-${label}`}>
        <StyledLabel htmlFor='new-group-name'>
          <Trans id='new.group.name' />
        </StyledLabel>
        <Input
          disabled={loading}
          id='new-group-name'
          className='mb-4 w-full'
          value={name}
          onChange={setName}
          onEnter={create}
        />
        {!blueprint && (
          <>
            <StyledLabel htmlFor='choose-blueprint'>
              <Trans id='choose.blueprint' />
            </StyledLabel>
            <Select
              disabled={loading}
              id='choose-blueprint'
              mb={3}
              onChange={handleChange}
            >
              {blueprints?.map(blueprint => {
                return (
                  <Option key={blueprint.id} value={blueprint.id}>
                    {blueprint.name}
                  </Option>
                )
              })}
            </Select>
          </>
        )}
        {showParentGroupSelect && (
          <>
            <StyledLabel htmlFor='parent-group-select'>
              <span>
                <Trans id='parent.group' />
              </span>
              <TooltipTrigger
                ml={1}
                as={Icons.AlertHelp}
                tooltipId='parent-group-select'
              />
            </StyledLabel>
            <ParentGroups
              id='parent-group-select'
              thisBlueprint={thisBlueprint}
              setParent={setParent}
              parent={parent}
            />
            <Tooltip id='parent-group-select' place='right'>
              <div style={{ width: 150 }}>
                <Trans id='create.hierachy.between' />
              </div>
            </Tooltip>
          </>
        )}

        <Flex mt={3}>
          <Button
            disabled={loading}
            type='button'
            outline
            width='100%'
            mr={2}
            onClick={onCancel}
          >
            <Trans id='cancel' />
          </Button>
          <Button
            type='button'
            width='100%'
            disabled={invalid || loading}
            title={invalid ? i18n._('title.is.required') : undefined}
            onClick={create}
          >
            {loading ? <StyledSpinner2 size={16} /> : <Trans id='continue' />}
          </Button>
        </Flex>
      </Wrapper>
    </Flex>
  )
}

export const ParentGroups = ({ id, thisBlueprint, setParent, parent }) => {
  const [query, setQuery] = React.useState('')
  const debouncedQuery = useDebouncedValue(query, 300)
  const [fetch, { data, loading: parentsLoading }] = useLazyQuery(parentQuery, {
    variables: { categoryId: thisBlueprint?.parentId },
    fetchPolicy: 'no-cache'
  })
  React.useEffect(() => {
    fetch({
      variables: { categoryId: thisBlueprint?.parentId, query: debouncedQuery }
    })
  }, [debouncedQuery, fetch, thisBlueprint?.parentId])

  const options = map(data?.groupsConnection?.edges, ({ node: group }) => ({
    id: group.newId,
    label: group.name
  }))

  const noParentGroups =
    !parentsLoading && !data?.groupsConnection?.edges?.length

  return (
    <>
      <GroupTypeahead
        id={id}
        disabled={noParentGroups}
        options={options}
        onChange={setParent}
        value={parent}
        query={query}
        setQuery={setQuery}
        loading={parentsLoading}
      />
      {noParentGroups && (
        <div className='text-red-400'>
          <Trans id='no.parent.groups.exist' />
        </div>
      )}
    </>
  )
}

const StyledLabel = styled(Body1).attrs({ as: 'label', mb: 1 })`
  display: flex;
  align-items: center;
`

const StyledSpinner2 = styled(Spinner)`
  margin-left: 8px;
  border-color: #eee;
  border-top-color: #00b586;
`

const Wrapper = styled.div`
  padding: 16px;
  width: 276px;
`

const GroupTypeahead = styled(Typeahead)`
  li {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    line-height: 32px;
  }
  ul {
    max-height: none;
  }
`

const parentQuery = gql`
  query potentialParentGroups($categoryId: ID!, $query: String) {
    groupsConnection(
      args: {
        limit: 50
        categoryId: $categoryId
        query: $query
        administratable: true
      }
    ) {
      edges {
        node {
          id
          newId
          name
        }
      }
    }
  }
`
