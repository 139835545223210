/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { get, isEmpty } from 'lodash'
import React from 'react'
import { Link, Navigate, Outlet, useOutletContext } from 'react-router-dom'

import Loading from '../../components/loading'
import { TenantFeaturesContextProvider } from '../../components/tenant-features-context'
import * as Icons from '../../icons'
import NotFoundPage from '../../pages/not-found'
import { VisuallyHidden } from '../../ui/a11y'
import Button from '../../ui/button'
import { Tab, TabInner, Tabs } from '../../ui/tabs'
import { H4 } from '../../ui/typography'

export function RedirectToFirstSpace () {
  const { firstSpaceId } = useOutletContext()
  return <Navigate to={firstSpaceId} replace />
}

export function SettingsRedirect () {
  const { tenantHasSpaces } = useOutletContext()
  return <Navigate to={tenantHasSpaces ? 'spaces' : 'integrations'} replace />
}

export default function Settings () {
  const { data, loading } = useQuery(...getSettingsQuery())
  const rootSpaces = data?.viewer?.rootSpaces || []
  const canManageSettings = data?.viewer?.user?.canManageSettings
  const tenantHasSpaces = data?.tenant?.features?.spaces
  const tenantHasPortals = data?.tenant?.features?.portals
  const tenantAllowsAppCreation = !data?.tenant?.features?.appCreationDisabled
  const firstSpaceId = get(rootSpaces, '[0].id')

  if (loading) {
    return <Loading />
  }

  if (isEmpty(rootSpaces) && !canManageSettings) {
    return <NotFoundPage />
  }

  return (
    <div className='flex min-h-screen flex-col'>
      <header className='flex h-20 items-center border-b border-b-light-gray-300 bg-white p-4 shadow-[0_-10px_10px_2px_rgba(0,0,0,0.1)] dark:bg-light-gray-300'>
        <div className='absolute flex items-center gap-4'>
          <Button transparent icon as={Link} to='/' width={32}>
            <Icons.Back className='fill-blue-500' />
            <VisuallyHidden>
              <Trans id='pages.settings.leave' />
            </VisuallyHidden>
          </Button>
          <H4 as='h1'>
            {tenantHasSpaces
              ? `${i18n._('pages.settings.spaces.settings')}`
              : `${i18n._('pages.settings.system.settings')}`}
          </H4>
        </div>
        <nav className='m-auto'>
          <Tabs>
            {tenantHasSpaces && canManageSettings ? (
              <>
                <Tab to='spaces'>
                  <TabInner>
                    <Trans id='pages.settings.spaces' />
                  </TabInner>
                </Tab>
                <Tab to='permissions'>
                  <TabInner>
                    <Trans id='permissions' />
                  </TabInner>
                </Tab>
              </>
            ) : !tenantHasSpaces ? (
              <>
                <Tab to='integrations'>
                  <TabInner>
                    <Trans id='pages.settings.integrations' />
                  </TabInner>
                </Tab>
                <Tab to='branding'>
                  <TabInner>
                    <Trans id='pages.settings.branding' />
                  </TabInner>
                </Tab>
                <Tab to='permissions'>
                  <TabInner>
                    <Trans id='permissions' />
                  </TabInner>
                </Tab>
              </>
            ) : null}
          </Tabs>
        </nav>
      </header>
      <TenantFeaturesContextProvider
        suites={data?.suites}
        tenantFeatures={data?.tenant?.features}
      >
        <Outlet
          context={{
            space: {},
            tenantHasSpaces,
            tenantHasPortals,
            tenantAllowsAppCreation,
            firstSpaceId,
            rootSpaces
          }}
        />
      </TenantFeaturesContextProvider>
    </div>
  )
}

const getSettingsQuery = () => [
  gql`
    query SettingsQuery {
      tenant {
        id
        features {
          spaces
          portals
          appCreationDisabled
        }
      }
      suites {
        id
        name
      }
      viewer {
        id
        rootSpaces: spaces(type: ADMIN_ROOT) {
          id
          name
          suite {
            id
            name
            icon
          }
        }
        user {
          id
          canManageSettings
        }
      }
    }
  `,
  {
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network'
  }
]
