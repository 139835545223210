/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { Trans } from '@lingui/react'
import { map } from 'lodash'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import { AppIcon, AppSquare } from '../../components/app-icon'
import Pagination from '../../components/data-table/pagination'
import SearchBar from '../../components/data-table/search-bar'
import * as routes from '../../components/routes'
import Spinner from '../../components/spinner'
import { GraphQLError, NotFound } from '../../components/system-error'
import Tooltip, { TooltipTrigger } from '../../components/tooltip'
import * as Icons from '../../icons'
import { Flex } from '../../ui/layout'
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow
} from '../../ui/table'

/*
Potential fields to add later:
- How many versions of an App there are (How often an App is published)
- Date the last workflow finished for a specific app
- How many documents are actively in workflow for a specific app
- # of documents that have completed workflow
- Split up documents into:
    1) Documents submitted
    2) Drafts saved
    3) Documents finished

Other Considerations
- Last Published was removed because if you used app.updatedAt, you *may* get the right date, but it could give a completely wrong thing and we currently don't store last published.
- Can't sort by Latest Document or Total Submitted because they're calculated fields.
*/

export default function Apps () {
  const [sort, setSort] = React.useState('-createdAt')
  const [queryInput, setQueryInput] = React.useState('')
  const [params, updateParams] = useImmer({
    skip: 0,
    limit: 25,
    query: queryInput,
    sort: ['-createdAt']
  })

  const q = getAppsQuery(params)
  const { query, ...qParams } = q
  const { data, loading, error } = useQuery(query, qParams)
  const [apps, setApps] = React.useState()
  const [totalCount, setTotalCount] = React.useState()

  React.useEffect(() => {
    if (!data || !data.appsConnection) return
    setApps(data.appsConnection.edges.map(({ node }) => node))
    setTotalCount(data.appsConnection.totalCount)
  }, [data, setApps, setTotalCount])

  // This is needed because you can't query documentConnection on products,
  // So at some point we're gonna need to add that functionality in
  const err = error?.message
    ?.split('Not supported for products')
    ?.join('')
    ?.trim()

  const dateFormatter = new Intl.DateTimeFormat(undefined, {
    dateStyle: 'medium'
  })

  if (!apps || error) {
    if (loading) {
      return (
        <div className='mt-8 flex flex-col items-center'>
          <Spinner size={200} />
          <div className='max-w-md pt-10 text-center text-dark-gray-200'>
            <Trans id='pages.usage.hang.tight' />
          </div>
        </div>
      )
    }
    if (err !== '') return <GraphQLError fillHeight />
    return <NotFound fillHeight />
  }

  function changeSort (field) {
    const newSort = sort === field ? `-${field}` : field
    setSort(newSort)
    updateParams(draft => {
      draft.sort = [newSort]
      draft.skip = 0
    })
  }

  return (
    <div className='mx-16 my-9 flex h-[90%] flex-col'>
      <div className='mb-2 flex items-center md:block'>
        <SearchBar
          collapse={700}
          value={queryInput}
          onChange={setQueryInput}
          reset={() => {
            setQueryInput('')
            updateParams(draft => {
              draft.skip = 0
              draft.query = null
            })
          }}
          onBlur={() => {
            updateParams(draft => {
              draft.skip = 0
              draft.query = queryInput
            })
          }}
          onEnter={() => {
            updateParams(draft => {
              draft.skip = 0
              draft.query = queryInput
            })
          }}
        />
        {loading && <Spinner size={30} />}
      </div>
      <TableHolder className='border-b border-t border-light-gray-200 dark:border-light-gray-300'>
        <AppTable data-testid='app-table'>
          <TableHeader>
            <MyTableRow>
              <TableHeaderCell
                onClick={() => changeSort('name')}
                className='w-1/2'
              >
                <Flex>
                  <Trans id='name' />
                  <Arrow field='name' sort={sort} />
                </Flex>
              </TableHeaderCell>
              <TableHeaderCell onClick={() => changeSort('createdAt')}>
                <Flex>
                  <Trans id='date.created' />
                  <Arrow field='createdAt' sort={sort} />
                </Flex>
              </TableHeaderCell>
              <TableHeaderCell>
                <Trans id='pages.usage.total.submitted' />
              </TableHeaderCell>
              <TableHeaderCell>
                <Flex>
                  <Trans id='pages.usage.latest.document' />
                  <TooltipTrigger
                    tooltipId='last-created-document'
                    ml={1}
                    as={Icons.AlertHelp}
                  />
                  <Tooltip id='last-created-document' className='max-w-xs'>
                    <Trans id='pages.usage.draft.or' />
                  </Tooltip>
                </Flex>
              </TableHeaderCell>
              <TableHeaderCell>
                <Trans id='creator' />
              </TableHeaderCell>
            </MyTableRow>
          </TableHeader>
          <TableBody>
            {map(apps, app => (
              <MyTableRow key={app.id}>
                <TableCell>
                  <div className='flex items-center gap-4'>
                    <AppSquare
                      backgroundColor={app.tileOptions?.backgroundColor}
                      className='w-8'
                    >
                      <AppIcon
                        iconName={app.tileOptions?.iconName}
                        isProduct={app.type === 'product'}
                      />
                    </AppSquare>
                    <GetLink app={app} />
                  </div>
                </TableCell>
                <TableCell>
                  {formatDate(app.createdAt, dateFormatter)}
                </TableCell>
                <TableCell>
                  <span>{app.documentCount.toLocaleString()}</span>
                </TableCell>
                <TableCell>
                  <span>
                    {app.latestDocument &&
                      formatDate(
                        new Date(app.latestDocument).getTime(),
                        dateFormatter
                      )}
                  </span>
                </TableCell>
                <TableCell>
                  <span>{app.createdBy?.email}</span>
                </TableCell>
              </MyTableRow>
            ))}
          </TableBody>
          {totalCount !== 0 && (
            <Pagination
              onUpdate={({ skip, limit }) =>
                updateParams(draft => {
                  draft.skip = skip
                  draft.limit = limit
                })
              }
              total={totalCount}
              skip={params.skip}
              limit={params.limit}
            />
          )}
        </AppTable>
      </TableHolder>
    </div>
  )
}

function formatDate (createdAt, formatter) {
  const date = new Date(+createdAt)
  if (Number.isNaN(date)) return null
  try {
    return formatter.format(date)
  } catch (error) {
    return null
  }
}

function Arrow ({ field, sort }) {
  return (
    <>
      {sort === field ? (
        <KeyboardArrowUp ml={2} />
      ) : sort === `-${field}` ? (
        <Icons.KeyboardArrowDown ml={2} />
      ) : null}
    </>
  )
}

const TableHolder = styled.div`
  overflow: auto;

  & thead {
    position: sticky;
    top: -1px;
    z-index: 1;
  }

  & tfoot {
    position: sticky;
    bottom: -1px;
    z-index: 1;
    background: #eee;
    html.dark & {
      // Outlier: dark:bg-light-gray-300
      background: #333;
    }
  }
`

const AppTable = styled(Table)`
  & th,
  & td {
    border: 1px solid var(--light-gray-300);
    padding: 8px;
  }
`

const MyTableRow = styled(TableRow)`
  > th {
    color: #666;
  }
`

const KeyboardArrowUp = styled(Icons.KeyboardArrowDown)`
  transform: rotate(180deg);
`

function GetLink ({ app: { id, viewer = {}, firstPageId, name } }) {
  if (viewer.canViewDocuments || viewer.canManage) {
    return (
      <NavLink
        className='text-text-link underline'
        to={routes.documentList(id, firstPageId)}
      >
        {name}
      </NavLink>
    )
  }
  if (viewer.canSubmitDocuments) {
    return (
      <NavLink
        className='text-text-link underline'
        to={routes.run(id, firstPageId)}
      >
        {name}
      </NavLink>
    )
  }
  return <span>{name}</span>
}

const getAppsQuery = ({ skip, limit, sort, query }) => ({
  variables: {
    args: {
      limit,
      skip,
      sort,
      query
    }
  },
  fetchPolicy: 'cache-and-network',
  errorPolicy: 'all',
  query: gql`
    query UsageApps($args: AppsConnectionInput!) {
      appsConnection(args: $args) {
        edges {
          node {
            id
            name
            type
            createdAt
            firstPageId
            createdBy {
              id
              email
            }
            tileOptions {
              backgroundColor
              iconName
            }
            viewer {
              canManage
              canSubmitDocuments
              canViewDocuments
            }
            latestDocument
            documentConnection(args: { limit: 1, sort: ["-createdAt"] }) {
              edges {
                node {
                  id
                  createdAt
                }
              }
            }
            documentCount
          }
        }
        totalCount
      }
    }
  `
})
