/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { filter, find, isEmpty, isString, map } from 'lodash'
import qs from 'querystring'
import React from 'react'
import {
  Link,
  Navigate,
  useLocation,
  useParams,
  useSearchParams
} from 'react-router-dom'

import FormbotContainer from '../../components/formbot-container/formbot-container'
import { useScrollToTop } from '../../components/use-scroll-to-top'
import Formbot, { formbot, getPages, validate } from '../../formbot'
import { utils as fb } from '../../formbot/engine/formbot'
import { useAlertTracker, useAlerts } from '../../ui/alerts'
import Button from '../../ui/button'
import { Flex } from '../../ui/layout'
import { useSubmitDocumentMutation } from './components/mutation.submit'

export default function Form ({
  structure,
  helpLink,
  data,
  formData,
  updateFormData,
  setPage,
  setDocumentId
}) {
  const alerts = useAlerts()
  const [searchParams] = useSearchParams()
  const [trackAlert, closeTrackedAlerts] = useAlertTracker()
  usePopulateQuerystringData(structure, updateFormData)
  const { appId, pageId } = useParams()
  const rawPageNum = searchParams.get('page')
  const pageNum = parseInt(rawPageNum, 10) || 1
  const scrollRef = useScrollToTop([pageNum])
  const [shouldValidate, setShouldValidate] = React.useState(false)
  const [submitting, setSubmitting] = React.useState(false)
  const [warnedSubmission, setWarnedSubmission] = React.useState(false)
  const [realCanSubmit, setRealCanSubmit] = React.useState(false)
  const validations = shouldValidate
    ? validate({ data: formData }, structure)
    : {}
  const handleChange = (key, val) => {
    updateFormData(draft => {
      draft[key] = val
    })
  }
  const submitDoc = useSubmitDocumentMutation()
  const finishForm = (sendDocument, finalize) => async () => {
    setShouldValidate(true)
    const validations = validate({ data: formData }, structure)
    if (!isEmpty(validations)) {
      trackAlert(alerts.type2(i18n._('pagesanon.form.failed.submit'), 'error'))
      setSubmitting(false)
      return
    }
    setSubmitting(true)
    try {
      const resp = await sendDocument(
        appId,
        pageId,
        formData,
        Intl.DateTimeFormat().resolvedOptions().timeZone
      )

      if (resp.data.submitDocument === 'submissionsDisabled') {
        alerts.type1(
          i18n._('pagesanon.form.form.cannot'),
          i18n._('pagesanon.form.form.cannot.data'),
          'confirm'
        )
        setSubmitting(false)
        setRealCanSubmit(true)
        return
      }

      await finalize(resp)
    } catch (err) {
      trackAlert(alerts.type2(i18n._('pagesanon.form.server.error'), 'error'))
      setSubmitting(false)
    }
  }

  const getButtons = (validations, { paginate, pages }) => {
    const formSchedule = data.app.dataset?.formSchedule
    const limitedSubmissionsEnabled =
      data.app.dataset?.limitSubmissionsForDataset?.enabled
    const submissionLimit = data.app.dataset?.limitSubmissionsForDataset?.limit
    const submissionCount = data.app.dataset?.submissionCount
    const canSubmit = !checkCanSubmit({
      startDate: formSchedule?.startDate,
      endDate: formSchedule?.endDate,
      enabled: formSchedule?.enabled,
      submissionsDisabled: data.app.dataset?.submissionsDisabled,
      limitedSubmissionsEnabled,
      submissionLimit,
      submissionCount
    })
    if (canSubmit && !warnedSubmission) {
      alerts.type1(i18n._('pagesanon.form.admin.disabled'), '', 'confirm')
      setWarnedSubmission(true)
    }
    const disableButtons = !isEmpty(validations) || submitting
    const submittable = canSubmit || realCanSubmit ? true : disableButtons
    const prevPage =
      pages &&
      pages
        .slice(0, pageNum - 1)
        .reverse()
        .find(page => !page.hidden)
    const nextPage = pages && pages.slice(pageNum).find(page => !page.hidden)
    return (
      <div>
        {paginate && (
          <Flex mb={2}>
            <Button
              brandColor={data.app.branding.color}
              large
              width='100%'
              mr={2}
              disabled={!prevPage}
              as={prevPage ? Link : 'button'}
              to={prevPage ? prevPage.href : null}
            >
              <Trans id='pagesanon.form.back' />
            </Button>
            <Button
              brandColor={data.app.branding.color}
              large
              width='100%'
              disabled={!nextPage}
              as={nextPage ? Link : 'button'}
              to={nextPage ? nextPage.href : null}
            >
              <Trans id='pagesanon.form.next' />
            </Button>
          </Flex>
        )}
        <Button
          brandColor={data.app.branding.color}
          large
          width='100%'
          disabled={submittable}
          onClick={() => {
            setSubmitting(true)
            setTimeout(
              finishForm(submitDoc, () => {
                closeTrackedAlerts()
                if (data?.app?.dataset?.autoStartNewForm) {
                  alerts.type3(i18n._('pagesanon.form.submitted'), 'info')
                  setTimeout(() => {
                    window.location.reload()
                  }, 2000)
                }
                setPage('finished')
              }),
              1000
            )
          }}
        >
          {submitting
            ? `${i18n._('pagesanon.form.submitting')}`
            : `${i18n._('pagesanon.form.submit')}`}
        </Button>
      </div>
    )
  }

  const document = {
    data: formData,
    meta: {}
  }

  const paginate = data?.app?.dataset?.paginated
  let pages = null
  if (!paginate && rawPageNum) return <Navigate to='.' replace />
  if (paginate) {
    if ((rawPageNum && pageNum === 1) || pageNum < 1) {
      return <Navigate to='.' replace />
    }
    const maxPage = structure.template?.children?.length ?? 1000
    if (pageNum > maxPage) {
      return <Navigate to={`?page=${maxPage}`} replace />
    }
    pages = map(getPages(document, structure, validations), (page, i) => ({
      index: i,
      errorMsg: page.errorMsg,
      name: page.name,
      hidden: page.hidden,
      format:
        i + 1 < pageNum
          ? 'checked'
          : i + 1 === pageNum
            ? 'selected'
            : 'default',
      href: i === 0 ? '.' : `?page=${i + 1}`
    }))
  }
  const pagination = { paginate, pages }

  return (
    <FormbotContainer
      actionButtons={getButtons(validations, pagination)}
      branding={data?.app?.branding}
      helpLink={helpLink}
      pages={pages}
      formbot={
        <div ref={scrollRef}>
          <Formbot.Edit
            className='formbot'
            document={document}
            onChange={handleChange}
            multipageNum={paginate && pageNum}
            structure={structure}
            branding={data.app.branding}
            context={{
              validations,
              labelSize: data.app.dataset.labelSize,
              documentMeta: {},
              actionId: 'anonymous'
            }}
          />
        </div>
      }
    />
  )
}

function checkStartAndEndDate (startDate, endDate) {
  const curr = new Date()
  if (startDate != null && curr < startDate) return false
  if (endDate != null && curr > endDate) return false
  return true
}

function checkCanSubmit ({
  startDate,
  endDate,
  enabled,
  submissionsDisabled,
  limitedSubmissionsEnabled,
  submissionLimit,
  submissionCount
}) {
  if (submissionsDisabled) return false
  if (enabled) {
    const sd = startDate ? new Date(Number(startDate)) : null
    const ed = endDate ? new Date(Number(endDate)) : null
    if (!checkStartAndEndDate(sd, ed)) return false
  }
  if (limitedSubmissionsEnabled) {
    if (submissionLimit <= submissionCount) return false
  }
  return true
}

const validList = ['Text', 'Textarea', 'Email']
function usePopulateQuerystringData (structure, updateFormData) {
  const location = useLocation()
  React.useEffect(() => {
    if (!location.search) return
    let schema = fb.structureToSchema(structure, formbot)
    schema = filter(schema, ({ formKey, type }) => {
      return formKey.startsWith('data.') && validList.includes(type)
    })
    const query = qs.parse(location.search.substr(1))
    updateFormData(draft => {
      for (const key in query) {
        if (!isString(query[key])) continue
        const gadget = find(schema, ({ customFormKey }) => {
          return customFormKey?.enabled && customFormKey.value === key
        })
        if (gadget) draft[gadget.formKey.replace('data.', '')] = query[key]
      }
    })
  }, [location.search, structure, updateFormData])
}
