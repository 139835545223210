/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { RequiredConfig } from './config'
import Edit from './edit'
import * as filters from './filters'
import { ReactComponent as Icon } from './icon.svg.jsx'
import * as progressiveDisclosure from './progressive-disclosure'
import { validateShape } from './validations'
import View from './view'

const subFields = ({ id, formKey, label }) => {
  return [
    {
      id: `${id}.label`,
      type: 'Text',
      formKey: `${formKey}.**.label`,
      label: `${label} - Label`,
      subFieldLabel: 'Label',
      details: {}
    },
    {
      id: `${id}.id`,
      type: 'Text',
      formKey: `${formKey}.**.id`,
      label: `${label} - ID`,
      subFieldLabel: 'ID',
      details: {}
    }
  ]
}

export default {
  getAssembler: ({ BasicWithoutLabel }) => BasicWithoutLabel,
  RequiredConfig,
  defaultValue: [],
  Edit,
  meta: {
    category: 'Basic',
    Icon,
    label: 'Checkbox'
  },
  sortSuffix: '.label',
  filters,
  progressiveDisclosure,
  sampleValue: [],
  subFields,
  validateShape,
  View
}
