/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import FocusTrap from 'focus-trap-react'
import {
  cloneDeep,
  filter,
  find,
  forEach,
  forEachRight,
  get,
  includes,
  set
} from 'lodash'
import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { useImmer } from 'use-immer'

import { ModalPage } from '../../components/modal-page'
import { validate } from '../../formbot'
import { traverseTemplate } from '../../formbot/engine/formbot/utils'
import WorkflowTracker from '../../pages-runner/action/workflow-tracker'
import { InfoPage, InfoTitle } from '../components/simulator-parts'
import SimulationContext from '../components/simulator-parts/context'
import SimulateCompleted from '../components/simulator-parts/simulate-completed'
import { ReactComponent as SimulateIconBase } from '../components/simulator-parts/simulate-icon-base.svg.jsx'
import { ReactComponent as SimulateIcon } from '../components/simulator-parts/simulate-icon.svg.jsx'
import { ReactComponent as SimulateStopped } from '../components/simulator-parts/simulate-stopped.svg.jsx'
import steps from '../steps'

export default ({ ...props }) => (
  <div
    className={cx(
      'fixed right-6 z-[2] w-1/2 text-sm max-[768px]:top-[130px] max-[768px]:w-full',
      props.isTable ? 'bottom-6 top-[222px]' : 'bottom-10 top-[144px]'
    )}
  >
    <FocusTrap
      focusTrapOptions={{
        clickOutsideDeactivates: true,
        initialFocus: '#default-btn'
      }}
    >
      <div
        className='kp-transition-slide-left relative h-full w-full rounded-br-lg border-l border-light-gray-300 transition-[left] duration-300'
        onClick={e => e.stopPropagation()}
      >
        {props.value ? (
          props.value.finalState ? (
            <End status={props.value.status} {...props} />
          ) : (
            <Simulation key={props.selectedId} {...props} />
          )
        ) : (
          <Intro {...props} />
        )}
      </div>
    </FocusTrap>
  </div>
)

const Intro = props => {
  const { simulateAction } = props
  return (
    <InfoPage justifyContent='center' flexDirection='column'>
      <SimulateIcon className='h-[57px] w-[72px]' />
      <SimulateIconBase className='mt-[3px] h-[10.5px] w-[27.24px]' />
      <InfoTitle>
        <Trans id='workflow.simulator' />
      </InfoTitle>
      <div className='mb-4 text-base font-medium'>
        <div className='w-72 text-center'>
          <Trans id='allows.see.workflow.runs' />
        </div>
        <ul className='my-4 list-disc pl-10 text-red-300'>
          <li>
            <Trans id='data.will.not.be.changed' />
          </li>
          <li>
            <Trans id='people.not.emailed' />
          </li>
        </ul>
      </div>
      <button
        className='kp-button-solid'
        id='default-btn'
        onClick={() => simulateAction('start')}
      >
        <Trans id='run.test' />
      </button>
    </InfoPage>
  )
}

const End = props => {
  const [showOverview, setShowOverview] = React.useState(false)
  const { simulateAction, simulationState, status } = props
  const state = status === 'complete' ? 'Completed' : 'Stopped'
  return (
    <InfoPage justifyContent='center' flexDirection='column'>
      {state === 'Stopped' && <SimulateStopped />}
      {state === 'Completed' && <SimulateCompleted />}
      <div className='mt-5 text-3xl text-black'>
        <Trans id='workflow.state.object' values={{ state }} />
      </div>
      <div className='pt-4'>
        <button
          className='kp-button-transparent'
          id='overview-btn'
          onClick={() => setShowOverview(true)}
        >
          <Trans id='view.status.log' />
        </button>
      </div>
      <div className='pt-12'>
        <button
          className='kp-button-outline'
          id='default-btn'
          onClick={() => simulateAction('back', simulationState)}
        >
          <Trans id='back' />
        </button>
        <button
          className='kp-button-solid ml-6'
          id='reset-btn'
          onClick={() => simulateAction('start over')}
        >
          <Trans id='start.over' />
        </button>
      </div>
      <TransitionGroup>
        <CSSTransition key={showOverview} timeout={450}>
          {showOverview ? (
            <ModalPage
              title={i18n._('simulation.status.log')}
              onClose={() => setShowOverview(false)}
            >
              <div className='px-20 py-8'>
                <WorkflowTracker
                  simulation={simulationState.simulation}
                  startExpanded
                />
              </div>
            </ModalPage>
          ) : (
            <span />
          )}
        </CSSTransition>
      </TransitionGroup>
    </InfoPage>
  )
}

export const Simulation = ({
  appName,
  currentUser,
  value,
  form,
  branding,
  headless,
  imageCache,
  setImageCache,
  simulateAction,
  simulationState,
  workflow,
  rootWorkflow,
  workflowSettings
}) => {
  const [doc, updateDoc] = useImmer(get(simulationState, 'meta.document'))
  const usedFields = cloneDeep(simulationState.meta.usedFields)
  const templateCopy = cloneDeep(form?.template)
  const schema = Object.values(rootWorkflow?.schema ?? {})
  if (value.type === 'formfill') {
    let foundExtraUsedFields = false
    do {
      foundExtraUsedFields = false
      // eslint-disable-next-line no-loop-func
      traverseTemplate(templateCopy, (gadget, root, parent, i) => {
        if (gadget.type === 'Section' && gadget.details?.officeUseOnly) {
          gadget.hidden = true
        }
        if (gadget.conditionalVisibility?.enabled) {
          if (includes(usedFields, `data.${gadget.formKey}`)) {
            const parts = gadget.conditionalVisibility?.value?.parts || []
            parts.forEach(part => {
              if (part.formKey && !includes(usedFields, part.formKey)) {
                usedFields.push(part.formKey)
                foundExtraUsedFields = true
              }
            })
          }
        }
        if (gadget.type === 'IntegrationFill') {
          if (includes(usedFields, `data.${gadget.formKey}`)) {
            const inputs = gadget.details?.inputFields || []
            forEach(inputs, input => {
              if (input.value?.id) {
                const id = input.value.id.split('.')[1]
                const dep = schema.find(v => v.id === id)
                if (dep && !includes(usedFields, dep.formKey)) {
                  usedFields.push(dep.formKey)
                  foundExtraUsedFields = true
                }
              }
            })
          }
        }
      })
    } while (foundExtraUsedFields)
  } else {
    const stepDefinition = find(workflow.steps, { _id: value.stepDefinitionId })
    let sectionSettings = {}
    if (stepDefinition?.sectionSettings?.enabled === true) {
      sectionSettings = stepDefinition?.sectionSettings?.value || {}
    }

    const enforceSectionSettings = (template, parent, index) => {
      if (!template) return
      if (
        template.type === 'Section' &&
        sectionSettings[template.id] === 'hide'
      ) {
        template.hidden = true
      } else {
        template.readOnly =
          template.type === 'Section'
            ? sectionSettings[template.id]
              ? sectionSettings[template.id] === 'view'
              : workflowSettings.defaultFormToViewOnly
            : parent.readOnly
        forEachRight(template.children, (child, i) => {
          enforceSectionSettings(child, template, i)
        })
      }
    }
    enforceSectionSettings(templateCopy, { readOnly: true })
  }
  const metaFields = filter(schema, gadget =>
    gadget.formKey.startsWith('meta.')
  )
  const integrationFields = filter(schema, gadget =>
    gadget.formKey.startsWith('integration.')
  )
  const validations = validate(doc, {
    template: templateCopy,
    metaFields,
    integrationFields,
    fieldsToValidate: usedFields
  })
  const handleChange = (key, val) => {
    set(value, `documentUpdates.${key}`, val)
    updateDoc(draft => {
      draft.data[key] = val
    })
  }
  const updateIntegrationData = (key, val) => {
    set(value, `integrationData.${key}`, val)
    updateDoc(draft => {
      set(draft, `integrationData.${key}`, val)
    })
  }
  const updateImageCache = (key, val) => {
    setImageCache(draft => {
      draft[key] = val
    })
  }
  const manifest = steps[value.type]
  return (
    <>
      {manifest.Simulate && (
        <SimulationContext.Provider
          value={{
            doc,
            handleChange,
            metaFields,
            template: templateCopy,
            updateDoc,
            updateIntegrationData,
            usedFields,
            validations
          }}
        >
          <manifest.Simulate
            appName={appName}
            currentUser={currentUser}
            value={value}
            form={form}
            simulationState={simulationState}
            branding={branding}
            headless={headless}
            simulateAction={simulateAction}
            imageCache={imageCache}
            updateImageCache={updateImageCache}
            workflow={workflow}
            rootWorkflow={rootWorkflow}
            workflowSettings={workflowSettings}
          />
        </SimulationContext.Provider>
      )}
    </>
  )
}
